import { TimeIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  GridItem,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
  useTheme
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Button } from '../../../components/Button'
import { LocationOpeningHoursModal } from '../Modals/LocationOpeningHoursModal'
import { Location } from '../../../types'

interface Props {
  location: Location
  onUpdate: () => void
}

export const LocationOpeningHours: React.FC<Props> = ({
  location,
  onUpdate,
  ...rest
}) => {
  const theme = useTheme()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const openingHours = location.opening_hours

  const openingHoursComponent = (start?: string, end?: string) => {
    return (
      <Text fontSize="lg">
        {start && end ? parseHours(start) + ' to ' + parseHours(end) : '--:--'}
      </Text>
    )
  }

  const parseHours = (hours: string) => {
    // hours is in format hh:mm:ss
    try {
      let hoursArray = hours.split(':')
      let d = new Date()
      d.setHours(parseInt(hoursArray[0]))
      d.setMinutes(parseInt(hoursArray[1]))
      return format(d, 'h:mm aaa')
    } catch (error) {
      console.error(error)
      return 'Invalid Format'
    }
  }

  return (
    <Box
      {...rest}
      w={['100%', '70%']}
      position="relative"
      margin="5px"
      cursor="pointer"
      mb={3}
    >
      <Flex py={3} textAlign="center" w={['100%', '70%']}>
        <Heading as="h4" size="md">
          <TimeIcon color={theme.colors.brandPrimary} fontSize="xl" mr={2} />
          Opening hours settings
        </Heading>
        <Spacer />
        <Button variant="secondary" onClick={onOpen}>
          Update
        </Button>
      </Flex>
      <Box
        bg="white"
        {...rest}
        w={['100%', '70%']}
        position="relative"
        background="#fff"
        boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
        borderRadius="4px"
        padding="15px"
        margin="5px"
        cursor="pointer"
        mb={3}
      >
        <SimpleGrid columns={2} spacing={5}>
          <GridItem justifyContent={'right'}>
            <Text color="gray.500" fontSize="lg">
              Daily opening hours
            </Text>
          </GridItem>
          <GridItem>
            {openingHoursComponent(
              openingHours?.full_day_from,
              openingHours?.full_day_to
            )}
          </GridItem>
          <GridItem justifyContent={'right'}>
            <Text fontSize="lg" color="gray.500">
              Half a day set to{' '}
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize="lg">
              {openingHours
                ? openingHours.hours_half_day +
                  ' ' +
                  (openingHours.hours_half_day === 1 ? 'hour' : 'hours')
                : 'Value is not set'}
            </Text>
          </GridItem>
        </SimpleGrid>
        <LocationOpeningHoursModal
          onClose={(status?: boolean) => {
            onClose()
            if (status) onUpdate()
          }}
          isOpen={isOpen}
          location={location}
        />
      </Box>
    </Box>
  )
}
