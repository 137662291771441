import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import React from 'react'
import { updateOffice } from '../../../api'
import { Button } from '../../../components/Button'
import XeroAccountDropdown from '../../../components/inputs/XeroAccountDropdown'
import { KeyValueText } from '../../../components/KeyValueText'
import { useCustomToast } from '../../../context/toast-context'
import { IOfficeListDetail } from '../../../types'
import { SuperAdminPrivilegeRequiredModal } from './SuperAdminPrivilegeRequiredModal'

interface IModalInput {
  isOpen: boolean
  office: IOfficeListDetail | undefined
  closeModal: () => void
}

export const EditOfficeModal: React.FC<IModalInput> = (input) => {
  const { isOpen, office, closeModal: closeCallback } = input

  let assigned = false

  const { newToast: toast } = useCustomToast()
  function closeModal() {
    closeCallback()
  }

  function validateRate(value) {
    let error
    if (!value) {
      error = 'This rate is required'
    } else if (isNaN(value)) {
      error = 'This rate must be numeric'
    } else if (value < 0) {
      error = 'This rate cannot be negative'
    }
    return error
  }

  if (office === undefined) {
    return null
  }

  if (office.organization) {
    assigned = true
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={5}>Edit Office - {office.name} </ModalHeader>
          <ModalCloseButton />
          {office ? (
            <Formik
              initialValues={{
                creditRate: office.meeting_credit_rate,
                officeRate: office.rate,
                xeroAccountCode: office?.xero_account_code?.id ?? ''
              }}
              onSubmit={(values, actions) => {
                updateOffice(
                  office.id,
                  values.officeRate,
                  values.creditRate,
                  values.xeroAccountCode as number
                )
                  .then(() => {
                    toast({
                      status: 'success',
                      description: 'Office Updated'
                    })
                    closeCallback()
                  })
                  .catch(() => {
                    toast({
                      status: 'error',
                      description: 'Failed to update office'
                    })
                  })
                  .finally(() => {
                    actions.setSubmitting(false)
                  })
              }}
              render={(props) => (
                <form onSubmit={props.handleSubmit}>
                  <ModalBody pb={5}>
                    <KeyValueText
                      title={'Location'}
                      value={office.location_name ?? ''}
                    />
                    <KeyValueText
                      title={'Assigned Team'}
                      value={
                        office.organization
                          ? office.organization.name
                          : 'Unassigned'
                      }
                    />
                    <KeyValueText
                      title={'Capacity'}
                      value={`${office.number_of_desks}`}
                    />
                    <KeyValueText
                      title={'Total Meeting Credits'}
                      value={`${
                        props.values.creditRate * office.number_of_desks
                      } credit p/m`}
                    />

                    <Field
                      name="officeRate"
                      validate={validateRate}
                      render={({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.officeRate && form.touched.officeRate
                          }
                          pt={2}
                          pb={5}
                        >
                          <FormLabel htmlFor="officeRate">
                            Office Rate (p/m):
                          </FormLabel>
                          <InputGroup size="sm">
                            <InputLeftElement children="$" />
                            <Input
                              {...field}
                              id="officeRate"
                              placeholder="monthly rate"
                              variant="filled"
                            />
                          </InputGroup>
                          {assigned && (
                            <Text ml={2} color="red.500">
                              A change to the office rate will be applied to the
                              next month's invoice. However, this rate won't
                              apply to office memberships with a specific set
                              value. In that case, you should edit the
                              membership directly.
                            </Text>
                          )}
                          <FormErrorMessage>
                            {form.errors.officeRate}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                    <Field
                      name="creditRate"
                      validate={validateRate}
                      render={({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.creditRate && form.touched.creditRate
                          }
                          pb={5}
                        >
                          <FormLabel htmlFor="creditRate">
                            Meeting Credits per Desk:
                          </FormLabel>
                          <Input
                            {...field}
                            id="creditRate"
                            placeholder="meeting room credits"
                            variant="filled"
                          />
                          <FormErrorMessage>
                            {form.errors.creditRate}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                    <Field
                      name="xeroAccountCode"
                      as="select"
                      render={({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.xeroAccountCode &&
                            form.touched.xeroAccountCode
                          }
                          pb={5}
                        >
                          <FormLabel htmlFor="xeroAccountCode">
                            Xero Account
                          </FormLabel>
                          <XeroAccountDropdown
                            {...field}
                            id="xeroAccountCode"
                            variant="filled"
                          />

                          <FormErrorMessage>
                            {form.errors.xeroAccountCode}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      type="submit"
                      mr={3}
                      isLoading={props.isSubmitting}
                      disabled={props.isSubmitting}
                    >
                      Save
                    </Button>
                    <Button
                      disabled={props.isSubmitting}
                      variant="secondary"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </form>
              )}
            />
          ) : (
            <SuperAdminPrivilegeRequiredModal onClose={closeModal} />
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
