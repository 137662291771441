import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  Box,
  Text,
  Image,
  Skeleton,
  BoxProps,
  Flex,
  Stack,
  Textarea,
  DrawerFooter,
  Divider,
  useToast,
  useDisclosure,
  UnorderedList,
  ListItem,
  SimpleGrid
} from '@chakra-ui/react'
import {
  faCoffee,
  faComments,
  faCouch,
  faMapMarkerAlt,
  faPrint,
  faStopwatch,
  faWifi
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { differenceInSeconds } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz/esm'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { encodeQueryParams } from 'use-query-params'
import { getWorkspaceRoomTimeline, previewBookingCost } from '../../../../api'
import { BookingCostsComponent } from '../../../../components/BookingCostsComponent'
import { Button } from '../../../../components/Button'
import { CalendarFormInput } from '../../../../components/CalendarFormInput'
import { BookingConfirmModal } from '../../../../components/modals/BookingConfirmModal'
import { useAuth } from '../../../../context/auth-context'
import { useBookingContext } from '../../../../context/BookingContext'
import { routeStrings } from '../../../../routeStrings'
import {
  ICreateBookingRequest,
  IInterval,
  IOrganizationalUnit,
  IPreviewBookingCost,
  IWorkspace,
  Location
} from '../../../../types'
import { viewBookingQueryParams } from '../../../../utils/queryParams'
import { useRole } from '../../../../utils/rbac'
import { FeatureComponent } from '../../../../components/FeatureComponent'
import { secondsToTimeString } from '../../Workspaces/meetingRoomUtils'
import { WorkspaceTimeline } from '../../../../components/WorkspaceTimeline'
import {
  WORKSPACE_DAILY_PASS,
  WORKSPACE_PRIVATE_OFFICE
} from '../../../../utils/constants'

interface Props {
  workspace?: IWorkspace
  location: Location
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
  onToggle: () => void
}

export const PublicWorkspaceBookingDrawer: React.FC<Props> = (props) => {
  const { me, currentOrgUnit } = useAuth()
  const { isExternalMember } = useRole()
  const navigate = useNavigate()

  const { locations: bookableLocations, makeRoomBooking } = useBookingContext()
  const toast = useToast()
  const { isOpen, onClose, location, workspace } = props

  const [isBooking, setIsBooking] = useState(false)

  const isExternalBooking =
    isExternalMember ||
    !bookableLocations.find((l) => l.id === workspace?.location)
  const canBeBooked = true
  const {
    isOpen: isOpenBookingModal,
    onClose: onCloseBookingModal,
    onOpen: onOpenBookingModal
  } = useDisclosure()
  const [chosenDate, setChosenDate] = useState(new Date())
  const [bookingTimes, setBookingTimes] = useState<IInterval | undefined>()
  const [isCalculating, setIsCalculating] = useState<boolean>(false)
  const [bookingCost, setBookingCost] = useState<IPreviewBookingCost>()
  const [workspaceTimeline, setWorkspaceTimeline] = useState<IWorkspace>()
  const [isGettingTimeline, setIsGettingTimeline] = useState(false)
  const [isError, setIsError] = useState<boolean>(false)
  const dayBusinessHours =
    location.business_hours &&
    location.business_hours.find(
      (hours) => hours.day === chosenDate.getDay() - 1
    )
  const duration = bookingTimes
    ? secondsToTimeString(
        differenceInSeconds(bookingTimes.end, bookingTimes.start)
      )
    : undefined

  useEffect(() => {
    if (!isOpen) {
      setBookingCost(undefined)
      setBookingTimes(undefined)
      setIsBooking(false)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) handleChangeDate(chosenDate)
  }, [chosenDate, isOpen])

  const handleChangeDate = (newDate) => {
    if (!workspace || !chosenDate || isGettingTimeline) return
    setIsGettingTimeline(true)
    getWorkspaceRoomTimeline(workspace.id, newDate)
      .then((res) => {
        setIsGettingTimeline(false)
        setWorkspaceTimeline(res.data)
      })
      .catch((err) => {
        setIsGettingTimeline(false)
      })
  }

  const selectTimes = (timeSlot: IInterval | undefined) => {
    if (isCalculating) return
    setBookingTimes(timeSlot)
    if (!timeSlot || !workspace) return
    if (currentOrgUnit?.is_space_super_admin) return
    setIsCalculating(true)
    previewBookingCost({
      workspace: workspace.id,
      organizational_unit: currentOrgUnit?.id ?? 0,
      start_time: zonedTimeToUtc(
        timeSlot.start,
        workspace.timezone
      ).toISOString(),
      end_time: zonedTimeToUtc(timeSlot.end, workspace.timezone).toISOString()
    })
      .then(({ data }) => {
        setBookingCost(data)
        setIsCalculating(false)
      })
      .catch((err) => {
        setIsCalculating(false)
      })
  }

  const makeExternalBooking = async () => {
    if (!bookingTimes || !me || !currentOrgUnit) return

    // setIsBooking(true)
    onOpenBookingModal()
  }

  const confirmExternalBooking = () => {
    return new Promise<any>((resolve, reject) => {
      if (!currentOrgUnit || !bookingTimes || !workspace) {
        reject('')
        return
      }
      const bookingRequest = getBookingRequestObject(
        bookingTimes,
        workspace,
        currentOrgUnit
      )

      resolve(
        makeRoomBooking(bookingRequest)
          .then((booking) => {
            const params = encodeQueryParams(viewBookingQueryParams, {
              newBooking: true
            })

            const url = routeStrings.getMemberDashboardBooking(booking.id)
            navigate(url)
            return ''
          })
          .catch((err) => {
            toast({
              description:
                'The booking could not be confirmed. please try again later.',
              status: 'error'
            })
            return ''
          })
      )
    })
  }

  const validateBookingFields = () => {
    return true
  }

  const makeBooking = () => {
    if (!currentOrgUnit || !bookingTimes || !workspace || isBooking) return

    if (!validateBookingFields()) return

    const bookingRequest = getBookingRequestObject(
      bookingTimes,
      workspace,
      currentOrgUnit
    )
    setIsBooking(true)
    makeRoomBooking(bookingRequest)
      ?.then((booking) => {
        const params = encodeQueryParams(viewBookingQueryParams, {
          newBooking: true
        })
        setIsBooking(false)

        const url = routeStrings.getMemberDashboardBooking(booking.id)
        navigate(url)
      })
      .catch((err) => {
        setIsBooking(false)
        toast({
          description:
            'The booking could not be confirmed. please try again later.',
          status: 'error'
        })
      })
  }
  const getBookingRequestObject = (
    bookingTimes: IInterval,
    workspace: IWorkspace,
    currentOrgUnit: IOrganizationalUnit
  ) => {
    let start_time = zonedTimeToUtc(
      bookingTimes.start.toISOString(),
      workspace.timezone
    ).toISOString()
    let end_time = zonedTimeToUtc(
      bookingTimes.end.toISOString(),
      workspace.timezone
    ).toISOString()
    const bookingRequest = {
      start_time,
      end_time,
      organizational_unit: currentOrgUnit.id,
      workspace: workspace.id
    } as ICreateBookingRequest
    return bookingRequest
  }
  const bookingConfirmed = (status: boolean, data: any) => {
    if (status) {
      toast({ description: 'Booking created', status: 'success' })
    }
  }

  return (
    <Drawer placement={'right'} onClose={onClose} isOpen={isOpen} size={'md'}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          {workspace?.category === WORKSPACE_DAILY_PASS
            ? 'Day pass booking '
            : workspace?.category === WORKSPACE_PRIVATE_OFFICE
            ? 'Private office booking '
            : 'Workspace booking at '}
          at {location.name}
        </DrawerHeader>
        <DrawerBody>
          <MeetingRoomCard
            name={workspace?.name}
            location={location.name}
            image_url={workspace?.display_url ?? location?.thumbnail_url}
            duration={duration}
          />

          {canBeBooked && (
            <Box>
              <Box w={['100%', '80%', '50%']}>
                <CalendarFormInput
                  placement="bottom"
                  dateProps={{
                    chosenDate,
                    handleChangeDate: setChosenDate
                  }}
                  closeOnSelect={true}
                />
              </Box>
              <Divider my={2} />
              <Box pt={1}>
                <Text fontWeight={'bold'}>Select </Text>
              </Box>
              {isGettingTimeline && <Skeleton h={'16'} />}
              {!isGettingTimeline && (
                <Box py={8}>
                  {workspaceTimeline && location.opening_hours && (
                    <WorkspaceTimeline
                      chosenDate={chosenDate}
                      setTimeSlot={selectTimes}
                      workspace={workspaceTimeline}
                      setError={setIsError}
                      existingBooking={undefined}
                      openingHours={location.opening_hours}
                    />
                  )}
                </Box>
              )}
              <Box h={8}></Box>
              <Divider my={2} />
              <Text mb={6}>
                Enjoy of multiple amenities included with your booking at{' '}
                {location.name}
              </Text>
              <SimpleGrid columns={2} spacing={4}>
                <FeatureComponent
                  icon={<FontAwesomeIcon icon={faWifi} size={'xs'} />}
                  iconBg={'cyan.50'}
                  text="High-Speed Wi-Fi"
                  subtitle="Included with Day Pass"
                  size="lg"
                />
                <FeatureComponent
                  icon={<FontAwesomeIcon icon={faCoffee} size={'xs'} />}
                  iconBg={'cyan.50'}
                  text="Coffee & Tea"
                  size="lg"
                />
                <FeatureComponent
                  icon={<FontAwesomeIcon icon={faCouch} size={'xs'} />}
                  iconBg={'cyan.50'}
                  text="Lounge & Break Areas"
                  size="lg"
                />
                <FeatureComponent
                  icon={<FontAwesomeIcon icon={faComments} size={'xs'} />}
                  iconBg={'cyan.50'}
                  text="Networking Opportunities"
                  size="lg"
                />
              </SimpleGrid>
            </Box>
          )}
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px" py={1}>
          <BookingCostsComponent costs={bookingCost} />
        </DrawerFooter>
        <DrawerFooter borderTopWidth="1px">
          {canBeBooked ? (
            <Button
              disabled={!duration || isBooking}
              isLoading={isBooking}
              loadingText={'Booking Meeting Room'}
              colorScheme="blue"
              onClick={() => {
                if (isExternalBooking) {
                  if (validateBookingFields()) {
                    onOpenBookingModal()
                  }
                } else {
                  makeBooking()
                }
              }}
            >
              Book this Space
            </Button>
          ) : (
            <Text>Unavailable room for this user</Text>
          )}
        </DrawerFooter>
      </DrawerContent>
      {workspace && bookingTimes && bookingCost && (
        <BookingConfirmModal
          isOpen={isOpenBookingModal}
          onClose={() => {
            // setIsBooking(false)
            onCloseBookingModal()
          }}
          confirmBooking={confirmExternalBooking}
          resource={'MeetingRoom'}
          meeting_room={undefined}
          workspace={workspace}
          location={location}
          start_time={bookingTimes?.start}
          end_time={bookingTimes?.end}
          bookingCost={bookingCost}
        />
      )}
    </Drawer>
  )
}

interface CardProps extends BoxProps {
  name?: string
  location?: string
  image_url?: string
  duration?: string
}

const MeetingRoomCard: React.FC<CardProps> = (props) => {
  const { name, location, image_url, duration, ...rest } = props
  return (
    <Box position={'relative'} {...rest}>
      <Image
        src={props.image_url}
        h={'150px'}
        w={'100%'}
        objectFit="cover"
        // layout={'fill'}
      />
      {duration && (
        <Box position={'absolute'} bottom={0} bg="white" opacity={0.9} pr={2}>
          <FeatureComponent
            icon={<FontAwesomeIcon icon={faStopwatch} size={'1x'} />}
            iconBg={'green.100'}
            text={duration}
          />
        </Box>
      )}
      <Box
        position={'absolute'}
        bottom={0}
        right={0}
        p={2}
        background={'white'}
        opacity={0.9}
        minW={'100px'}
      >
        <Stack>
          <Text fontWeight={'bold'} fontSize={'md'}>
            {name}
          </Text>
          <Flex align={'center'}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <Text ml={1}>{location}</Text>
          </Flex>
        </Stack>
      </Box>
    </Box>
  )
}
