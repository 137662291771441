import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Stack,
  Flex,
  Divider,
  Spacer,
  HStack,
  Tooltip,
  Image,
  useDisclosure,
  Heading
} from '@chakra-ui/react'
import {
  faCreditCard,
  faTv,
  faUsers,
  faVideo
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { differenceInSeconds, format } from 'date-fns'
import React, { useState, useEffect } from 'react'
import { getBillingAccount, saveBillingDetailsConnect } from '../../api'
import { Button } from '../Button'
import { useAuth } from '../../context/auth-context'

import { CenteredSpinner } from '../../styled/CenteredSpinner'
import {
  IBillingAccountDetail,
  IBookingDisplay,
  IBookingSummary
} from '../../types'
import { Actions, useRBAC } from '../../utils/rbac'
import StripeConnectCardForm from '../../pages/CompleteProfile/StripeConnectCardForm'
import { secondsToTimeString } from '../../pages/MemberDashboard/Workspaces/meetingRoomUtils'
import { CancellationPolicyComponent } from '../CancellationPolicyComponent'
import {
  WORKSPACE_HOT_DESK,
  WORKSPACE_PRIVATE_OFFICE,
  WORKSPACE_TEXTS
} from '../../utils/constants'

interface IModalInput extends IBookingSummary {
  isOpen: boolean
  onClose: () => void
  confirmBooking: () => Promise<void>
}
export const BookingConfirmModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  confirmBooking,
  resource,
  meeting_room,
  workspace,
  location,
  start_time,
  end_time,
  bookingCost
}) => {
  const canViewBilling = useRBAC(Actions.ViewBillingForOwnTeam)

  const { currentOrganization } = useAuth()

  const [billingAccount, setBillingAccount] = useState<IBillingAccountDetail>()

  const [isBooking, setIsBooking] = useState(false)

  const [isPaymentDetailsConfirmed, setIsPaymentDetailsConfirmed] =
    useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    isOpen: isDisplayPaymentForm,
    onToggle: toggleDisplayPaymentForm,
    onOpen: onDisplayPaymentForm
  } = useDisclosure()
  const [isFetchingBillingDetails, setIsFetchingBillingDetails] =
    useState(false)

  useEffect(() => {
    fetchBillingAccount()
  }, [isOpen])

  const fetchBillingAccount = () => {
    if (isOpen && currentOrganization && location) {
      setIsFetchingBillingDetails(true)
      getBillingAccount(currentOrganization?.id, location.platform_tenant_id)
        .then((res) => {
          if (res.data) {
            setBillingAccount(res.data)
            if (!res.data.payment_gateway.active) {
              onDisplayPaymentForm()
            } else {
              setIsPaymentDetailsConfirmed(
                res.data.payment_gateway.payment_method?.payment_type === 'card'
              )
            }
          }
          setIsFetchingBillingDetails(false)
        })
        .catch((error) => {
          setIsFetchingBillingDetails(false)
        })
    }
  }

  const onConfirmBooking = () => {
    if (billingAccount && billingAccount.payment_gateway.active) {
      setIsBooking(true)
      confirmBooking()
        .then((res) => {
          setIsBooking(false)
        })
        .catch((error) => {
          setIsBooking(false)
        })
    }
  }

  const handleStripeConnectSubmit = async (
    {
      paymentMethodId,
      setupIntent
    }: { paymentMethodId: string | undefined; setupIntent: string | undefined },
    isEft: boolean
  ) => {
    if (isSubmitting || !currentOrganization) return
    setIsSubmitting(true)
    const key = window.localStorage.getItem('Token')
    try {
      await saveBillingDetailsConnect(
        paymentMethodId,
        isEft,
        currentOrganization.id,
        location.platform_tenant_id,
        setupIntent
      )
      toggleDisplayPaymentForm()
      fetchBillingAccount()
      setIsSubmitting(false)

      /* let paymentMethod = 'credit'
             analyticsTrack('Billing details changed', {
        paymentMethod
      }) */
    } catch (error: any) {
      setIsSubmitting(false)
      if (error.response) {
        alert(JSON.stringify(error.response.data))
      }
    }
  }

  const BookingSummaryCard: React.FC<{ booking: IBookingDisplay }> = ({
    booking
  }) => {
    const {
      startTime,
      endTime,
      meetingRoomImage,
      meetingRoomName,
      workspaceName,
      workspaceCategory,
      has_videoconference,
      has_screen_mirroring,
      capacity
    } = booking
    const placeholder = require('../../assets/placeholder.svg')
    const cardWidth = ['100%', '100%', '90%']

    const tax_charged = Number.parseFloat(bookingCost.tax_charged ?? '0')
    const total = Number.parseFloat(bookingCost.charges) + tax_charged

    const workspaceTexts =
      workspaceCategory === WORKSPACE_HOT_DESK
        ? WORKSPACE_TEXTS[WORKSPACE_HOT_DESK]
        : WORKSPACE_TEXTS[WORKSPACE_PRIVATE_OFFICE]
    return (
      <Flex
        // flexGrow={1}
        height="100%"
        flexDirection={'column'}
      >
        <Box bg="#f9f9f9" color="#000" rounded="md" p={4}>
          <Stack spacing={2} justifyContent="space-between">
            <Flex flexGrow={1}>
              {meetingRoomImage && (
                <Image
                  src={meetingRoomImage}
                  fallbackSrc={placeholder.default}
                  objectFit="cover"
                  maxHeight="100px"
                  mr={2}
                />
              )}
              <Box>
                <Text fontSize="22px" fontWeight="bold" letterSpacing="0.25px">
                  {meetingRoomName} {workspaceName}
                </Text>
                {workspaceCategory && (
                  <Text fontSize="14px" letterSpacing="0.25px">
                    {workspaceTexts.title}
                  </Text>
                )}
                <HStack>
                  {capacity && (
                    <Tooltip label="Capacity">
                      <Text fontSize={'xs'}>
                        <FontAwesomeIcon icon={faUsers} /> {capacity}
                      </Text>
                    </Tooltip>
                  )}
                  {has_videoconference && (
                    <Tooltip label="Video Conference">
                      <Text fontSize={'xs'}>
                        <FontAwesomeIcon icon={faVideo} /> VC
                      </Text>
                    </Tooltip>
                  )}
                  {has_screen_mirroring && (
                    <Tooltip label="Screen Mirroring">
                      <Text fontSize={'xs'}>
                        <FontAwesomeIcon icon={faTv} /> SM
                      </Text>
                    </Tooltip>
                  )}
                </HStack>
              </Box>
            </Flex>
            <Box>
              <Divider my={1} />
            </Box>
            <Text
              color={'#333'}
              fontSize="16px"
              fontWeight="bold"
              letterSpacing="0.25px"
            >
              {format(startTime, 'PP')}
            </Text>

            <Text
              color={'#6a6a6a'}
              fontSize="14px"
              fontWeight="bold"
              letterSpacing="0.25px"
            >
              {format(startTime, 'h:mm b')} - {format(endTime, 'h:mm b')}
            </Text>
          </Stack>

          <Text fontSize="14px" color={'#aaa'} letterSpacing="0.25px">
            Booking for{' '}
            {secondsToTimeString(differenceInSeconds(endTime, startTime))}
          </Text>
          <Box>
            <Divider my={1} />
            <Text fontSize="13px" fontWeight={'bold'}>
              Location details
            </Text>
            <Text fontSize="12px">
              {`${location.name}. ${location.address} `}
            </Text>
          </Box>
          <Box>
            <Divider my={1} mt={6} />

            <Text fontSize="13px" fontWeight={'bold'}>
              Booking Cost
            </Text>
            <HStack py={3} justifyContent="space-between">
              <Text>Subtotal</Text>
              <Text fontSize="14px">{`$${Number.parseFloat(
                bookingCost.charges
              ).toFixed(2)} `}</Text>
            </HStack>
            <HStack justifyContent="space-between">
              <Text>GST</Text>
              <Text fontSize="14px">{`$${tax_charged.toFixed(2)} `}</Text>
            </HStack>
            <Divider my={3} />
            <HStack py={3} justifyContent="space-between">
              <Text fontWeight={'bold'}>Total</Text>
              <Text fontSize="16px" fontWeight={'bold'}>
                $ {total.toFixed(2)}
              </Text>
            </HStack>
          </Box>
        </Box>
      </Flex>
    )
  }

  const PaymentDetailsPanelContent: React.FC<{}> = ({}) => {
    if (isFetchingBillingDetails || isSubmitting) return <CenteredSpinner />

    const isActiveStyle = {
      border: '2px solid #858484',
      borderColor: 'brandPrimary',
      background: '#fdfdfd',
      padding: '8px'
    }
    const isInactiveStyle = {
      border: '0px ',
      background: 'white',
      color: '#777',
      padding: '1px'
    }

    return (
      <Flex
        // flexGrow={1}

        flexDirection={'column'}
      >
        <Stack spacing={2} justifyContent="space-between" mb={2}>
          <Text
            fontSize="18px"
            fontWeight="bold"
            letterSpacing="0.25px"
            color={'#555'}
          >
            Payment Details
          </Text>
          {billingAccount &&
            !billingAccount.payment_gateway?.active &&
            !canViewBilling && (
              <Box>
                <Text>
                  A payment method has not been set up to make bookings at{' '}
                  <Text fontWeight={'bold'} as="span">
                    {location.platform_tenant_name}.
                  </Text>
                </Text>
                <br />
                <Text fontWeight={'bold'}>
                  Please, contact an Admin of your team to set up these details
                  first.
                </Text>
              </Box>
            )}
          {billingAccount && billingAccount.payment_gateway?.active && (
            <Stack>
              {billingAccount.payment_gateway?.payment_method?.payment_type ===
                'card' && (
                <Box
                  color="#000"
                  my={1}
                  rounded="md"
                  style={isDisplayPaymentForm ? isInactiveStyle : isActiveStyle}
                >
                  <HStack justifyContent={'space-between'} onClick={() => {}}>
                    <Stack>
                      {isDisplayPaymentForm && (
                        <Text fontWeight={'bold'}>Stored details</Text>
                      )}

                      <Flex>
                        <FontAwesomeIcon
                          icon={faCreditCard}
                          size={'2x'}
                          color="#bbb"
                        />
                        &nbsp; &nbsp;
                        <Text fontWeight={'bold'}>Credit card</Text>
                        &nbsp; &nbsp;
                        <Text>
                          {billingAccount.payment_gateway.payment_method.brand?.toUpperCase()}
                        </Text>
                      </Flex>
                      <Box>
                        <Text color={'#777'}>
                          {'**** **** **** **** ' +
                            billingAccount.payment_gateway.payment_method.last4}
                        </Text>
                        <Text color={'#777'}>
                          <Text as="span" fontWeight={'bold'}>
                            Exp:{' '}
                          </Text>
                          {billingAccount.payment_gateway.payment_method
                            .exp_month +
                            '/' +
                            billingAccount.payment_gateway.payment_method
                              .exp_year}
                        </Text>
                      </Box>
                    </Stack>
                  </HStack>
                </Box>
              )}
              <Flex>
                <Spacer />
                {canViewBilling && (
                  <Button
                    onClick={toggleDisplayPaymentForm}
                    variant="secondary"
                    size={'xs'}
                  >
                    {isDisplayPaymentForm
                      ? 'Cancel'
                      : 'Use another Credit Card'}
                  </Button>
                )}
              </Flex>
            </Stack>
          )}
        </Stack>

        {canViewBilling && isDisplayPaymentForm && (
          <Stack>
            <Text color="brandPrimary" fontWeight="bold" fontSize="sm">
              Credit Card
            </Text>
            <StripeConnectCardForm
              platformTenant={{
                id: location?.platform_tenant_id ?? 0,
                locations: []
              }}
              isSubmitting={isSubmitting}
              handleResult={handleStripeConnectSubmit}
              buttonText={'Save Payment method'}
            />
          </Stack>
        )}
      </Flex>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent minW="450px" borderRadius="md">
        <ModalHeader pb={2} pl={[2, 10]} color={'gray.500'}>
          Booking at
          <Text as="span" color="black">
            {' '}
            {location.name}, by {location.platform_tenant_name}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir={['row']}>
            <Box width={'50%'}>
              <BookingSummaryCard
                booking={{
                  location: meeting_room?.location || workspace?.location || 1,
                  meetingRoomImage: meeting_room?.display_url ?? '',
                  meetingRoomName: meeting_room?.name,
                  workspaceName: workspace?.name,
                  workspaceCategory: workspace?.category,
                  timezone: meeting_room?.timezone ?? 'Perth',
                  startTime: start_time,
                  endTime: end_time,

                  has_videoconference:
                    meeting_room?.has_videoconference || false,
                  has_screen_mirroring:
                    meeting_room?.has_screen_mirroring || false,
                  capacity: meeting_room?.capacity,
                  is_external: true
                }}
              />
            </Box>

            <Spacer />
            <Box width={['100%', '45%']}>
              <Heading size={'md'}>CHECKOUT</Heading>
              <Divider my={2} />
              <PaymentDetailsPanelContent />
              <Divider mt={4} mb={2} />
              <CancellationPolicyComponent />
            </Box>
          </Flex>

          <ModalFooter>
            <Spacer />
            <Button
              variant="secondary"
              onClick={onClose}
              disabled={isBooking || isSubmitting}
            >
              Cancel
            </Button>

            <Button
              disabled={
                !isPaymentDetailsConfirmed ||
                isDisplayPaymentForm ||
                isBooking ||
                isSubmitting
              }
              isLoading={isBooking}
              loadingText={'Completing Payment'}
              onClick={onConfirmBooking}
              ml={3}
            >
              Confirm booking
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
