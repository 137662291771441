import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { title } from 'process'
import React from 'react'

interface FeatureProps {
  text?: string
  subtitle?: string
  iconBg?: string
  shape?: 'round' | 'square'
  size?: 'sm' | 'md' | 'lg'
  reverse?: boolean
  icon?: React.ReactNode
  content?: React.ReactNode
}

export const FeatureComponent = ({
  text,
  icon,
  iconBg,
  content,
  size,
  shape,
  reverse,
  subtitle
}: FeatureProps) => {
  const sizeNumber = size === 'sm' ? 4 : size === 'md' ? 6 : 8
  return (
    <Stack direction={reverse ? 'row-reverse' : 'row'} align={'center'}>
      {icon && (
        <Flex
          w={sizeNumber}
          h={sizeNumber}
          align={'center'}
          justify={'center'}
          rounded={shape === 'square' ? 'md' : 'full'}
          bg={iconBg ?? 'gray.100'}
        >
          {icon}
        </Flex>
      )}
      {title || subtitle ? (
        <Box>
          {text && <Text fontWeight={[100, 100, 600]}>{text}</Text>}
          {subtitle && <Text>{subtitle}</Text>}
        </Box>
      ) : null}
      {content}
    </Stack>
  )
}
