import React from 'react'

import {
  WORKSPACE_HOT_DESK,
  WORKSPACE_PRIVATE_OFFICE
} from '../../../../utils/constants'
import { PublicLocationWorkspaces } from './PublicLocationWorkspaces'

export const WrapperPublicLocationWorkspacesHotDesk: React.FC = () => {
  return <PublicLocationWorkspaces category={WORKSPACE_HOT_DESK} />
}
export const WrapperPublicLocationWorkspacesPrivateOffice: React.FC = () => {
  return <PublicLocationWorkspaces category={WORKSPACE_PRIVATE_OFFICE} />
}
