import {
  Box,
  Container,
  Flex,
  SimpleGrid,
  Image,
  Text,
  Stack,
  ContainerProps,
  Spacer,
  useDisclosure,
  HStack
} from '@chakra-ui/react'
import {
  faClock,
  faHourglassHalf,
  faMoneyBill1Wave,
  faPeopleGroup
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button } from '../../../../components/Button'
import { useAuth } from '../../../../context/auth-context'
import { useAuthDrawer } from '../../../../context/auth-drawer-context'
import { useBookingContext } from '../../../../context/BookingContext'
import { useFeatureFlags } from '../../../../context/feature-flag-context'
import { usePlatformTenant } from '../../../../context/platform-tenant-context'
import { CenteredSpinner } from '../../../../styled/CenteredSpinner'
import { analyticsTrack } from '../../../../utils/analytics'
import {
  useListWorkspacesTimeline,
  useLocationExternalProfile
} from '../../../../utils/apiHooks'
import { useRole } from '../../../../utils/rbac'

import { useNavigate, useParams } from 'react-router-dom'
import { FeatureComponent } from '../../../../components/FeatureComponent'
import { IWorkspace } from '../../../../types'
import { LocationDetailsContainerV2 } from './LocationDetailsContainerV2'
import {
  WORKSPACE_HOT_DESK,
  WORKSPACE_PRIVATE_OFFICE,
  WORKSPACE_TEXTS
} from '../../../../utils/constants'
import { PublicWorkspaceBookingDrawer } from './PublicWorkspaceBookingDrawer'
import { StyledSelect } from '../../../../components/StyledSelect'

interface PublicLocationWorkspacesProps {
  category: string
}

export const PublicLocationWorkspaces: React.FC<
  PublicLocationWorkspacesProps
> = (props) => {
  const { category } = props
  const { me } = useAuth()

  const [location_id, setLocationId] = useState<number>()

  const [tenant, setTenant] = useState('')

  const navigate = useNavigate()
  const { isExternalMember } = useRole()
  const { locations: bookableLocations } = useBookingContext()
  const [chosenDate, setChosenDate] = useState<Date>(new Date())
  const { data: location } = useLocationExternalProfile(location_id)
  const { setThemeTenantLocation, platformTenant } = usePlatformTenant()
  const [workspace, setWorkspace] = useState<IWorkspace>()

  const { data: workspaces, mutate: revalidateWorkspaces } =
    useListWorkspacesTimeline(
      chosenDate,
      location ? location.id : 0,
      {
        category: category
      },
      {
        revalidateOnFocus: true
      }
    )

  const { publicBookingsEnabled } = useFeatureFlags()
  const params = useParams<{
    location_id: string
    tenant: string
    location_name: string
  }>()
  const {
    onClose: onCloseDrawer,
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onToggle: onToggleDrawer
  } = useDisclosure()
  const { handleOpenSignInDrawer } = useAuthDrawer()

  const TEXTS =
    category === WORKSPACE_HOT_DESK
      ? WORKSPACE_TEXTS[WORKSPACE_HOT_DESK]
      : WORKSPACE_TEXTS[WORKSPACE_PRIVATE_OFFICE]

  const isExternalBooking =
    isExternalMember || !bookableLocations.find((l) => l.id === location?.id)
  const canBeBooked = workspace !== undefined

  const roomAnalyticsData = {
    location: location_id,
    locationName: location?.name,
    tenant_name: platformTenant?.business_name,
    tenant_id: platformTenant?.id,
    workspace_category: WORKSPACE_HOT_DESK
  }
  useEffect(() => {
    if (workspaces && workspaces.length > 0) {
      const workspace = workspaces[0]
      setWorkspace(workspace)
    }
  }, [workspaces])
  useEffect(() => {
    if (params) {
      const paramsLocationId = Number.parseInt(params.location_id || '0')
      const bookableLocation = bookableLocations.find(
        (l) => l.id === paramsLocationId
      )
      if (!bookableLocation) {
      }
      setLocationId(paramsLocationId)
      setTenant(params.tenant || '')
      setThemeTenantLocation(Number.parseInt(params.tenant || '0'))
      analyticsTrack('PublicBookings Room Loaded', roomAnalyticsData)
    }
  }, [params])

  const boxStyles: ContainerProps = {
    py: 8,
    px: [2, 5, 10],
    bg: 'white',
    shadow: 'lg',
    rounded: 'lg'
  }

  if (!publicBookingsEnabled || !location) return <CenteredSpinner />

  return (
    <>
      <Container
        maxW={'6xl'}
        {...boxStyles}
        mb={4}
        borderTopColor={isExternalBooking ? 'gray.400' : undefined}
        borderTopWidth={isExternalBooking ? 3 : 0}
      >
        <Flex flexDirection={['column-reverse', 'column-reverse', 'row']}>
          <Box mb={2}>
            <Text fontSize={['md', 'lg', '3xl']} fontWeight="bold">
              {TEXTS.title} at {location?.name}
            </Text>
            <Text
              color={'gray.500'}
              fontSize={['sm', 'lg']}
              fontWeight={'normal'}
            >
              {location?.address}, {location?.city?.name},{' '}
              <Text color={'gray.500'} fontWeight={'bold'} as="span">
                {location?.city?.name}
              </Text>
            </Text>
          </Box>
          <Spacer />
          <Box>
            <Image src={platformTenant?.logo_long} height={['5', '8', '10']} />
          </Box>
        </Flex>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Flex>
            <Image
              rounded={'md'}
              alt={'meeting room image'}
              src={location?.thumbnail_url}
              objectFit={'cover'}
              maxH={'container.xl'}
            />
          </Flex>
          <Stack spacing={4}>
            <Text color={'gray.500'} fontSize={'lg'}>
              {/* {meetingRoom?.about} */}
            </Text>
            {workspaces && (
              <Stack spacing={4}>
                <Box w={['100%']}>
                  <Text fontWeight={'bold'} fontSize={'sm'} mb={2}>
                    {TEXTS.selectLabel}
                  </Text>
                  <StyledSelect
                    placeholder={TEXTS.selectPlaceholder}
                    value={workspace?.id}
                    isRequired={true}
                    onChange={(ev) => {
                      const workspaceId = Number.parseInt(ev.target.value)
                      const workspace = workspaces?.find(
                        (w) => w.id === workspaceId
                      )
                      setWorkspace(workspace)
                    }}
                  >
                    {workspaces?.map((workspace) => (
                      <option key={workspace.id} value={workspace.id}>
                        {workspace.name}
                      </option>
                    ))}
                  </StyledSelect>
                </Box>
                {workspace?.capacity && workspace.capacity > 1 && (
                  <Box>
                    <Flex mb={4}>
                      <FeatureComponent
                        icon={
                          <FontAwesomeIcon icon={faPeopleGroup} size={'1x'} />
                        }
                        iconBg={'blue.50'}
                        subtitle={`Capacity for ${workspace.capacity} people`}
                      />
                    </Flex>
                  </Box>
                )}
                {workspace && (
                  <Box>
                    <Flex mb={4}>
                      <Text fontSize={'lg'} fontWeight={'bold'}>
                        Members price
                      </Text>
                    </Flex>
                    <HStack spacing={4}>
                      <FeatureComponent
                        icon={
                          <FontAwesomeIcon icon={faHourglassHalf} size={'1x'} />
                        }
                        iconBg={'blue.50'}
                        text={`$${workspace.member_price_half_day_price} `}
                        subtitle="Half-day"
                      />

                      <FeatureComponent
                        icon={
                          <FontAwesomeIcon
                            icon={faMoneyBill1Wave}
                            size={'1x'}
                          />
                        }
                        iconBg={'blue.50'}
                        text={`$${workspace.member_price_full_day_price} `}
                        subtitle="Full-day"
                      />
                    </HStack>
                  </Box>
                )}
                {workspace && (
                  <Box mt={4}>
                    <Flex mb={4}>
                      <Text
                        fontSize={'sm'}
                        fontWeight={'bold'}
                        color={'gray.500'}
                      >
                        Non members price
                      </Text>
                    </Flex>
                    <HStack spacing={4}>
                      <FeatureComponent
                        icon={<FontAwesomeIcon icon={faClock} size={'1x'} />}
                        iconBg={'yellow.50'}
                        text={`$${workspace.non_member_price_half_day_price} `}
                        subtitle="Half-day"
                      />

                      <FeatureComponent
                        icon={<FontAwesomeIcon icon={faClock} size={'1x'} />}
                        iconBg={'yellow.50'}
                        text={`$${workspace.non_member_price_full_day_price} `}
                        subtitle="Full-day"
                      />
                    </HStack>
                  </Box>
                )}
              </Stack>
            )}
            {canBeBooked && (
              <Flex p={4} justifyContent={'center'}>
                {me ? (
                  <Button
                    w={'90%'}
                    shadow={'xl'}
                    onClick={() => {
                      analyticsTrack(
                        'PublicBookings authenticated Book room clicked',
                        roomAnalyticsData
                      )
                      onToggleDrawer()
                    }}
                  >
                    Proceed with booking
                  </Button>
                ) : (
                  <Button
                    w={'90%'}
                    shadow={'xl'}
                    onClick={() => {
                      analyticsTrack(
                        'PublicBookings anonymous Book room clicked',
                        roomAnalyticsData
                      )
                      handleOpenSignInDrawer()
                    }}
                  >
                    Start a booking
                  </Button>
                )}
              </Flex>
            )}
          </Stack>
        </SimpleGrid>
      </Container>
      <LocationDetailsContainerV2
        location={location}
      ></LocationDetailsContainerV2>

      <PublicWorkspaceBookingDrawer
        workspace={workspace}
        location={location}
        onClose={onCloseDrawer}
        isOpen={isOpenDrawer}
        onOpen={onOpenDrawer}
        onToggle={onToggleDrawer}
      />
    </>
  )
}
