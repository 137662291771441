import { Box, Flex, Heading, Image, useTheme } from '@chakra-ui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom'

import { BackButton } from '../../components/BackButton'
import { Button } from '../../components/Button'
import { FormInput } from '../../components/FormInput'
import { PhoneWrapper } from '../../components/PhoneWrapper'
import { routeStrings } from '../../routeStrings'
import { InputWarning } from '../../styled/InputWarning'
import { Phone } from '../../styled/Phone'
import { User } from '../../types'
import { useUserDetails } from '../../context/user-onboard-context'

export const Onboarding3Details: React.FC = () => {
  const { setUserDetails, userDetails } = useUserDetails()
  const navigate = useNavigate()
  const theme: any = useTheme()
  return (
    <Formik
      enableReinitialize
      initialValues={{
        mobileNumber: userDetails!.mobileNumber,
        postcode: userDetails!.postcode
      }}
      validate={(values): User => {
        const errors: any = {}
        if (!values.mobileNumber) {
          errors.mobileNumber = 'Required'
        } else {
          if (!isValidPhoneNumber(values.mobileNumber)) {
            errors.mobileNumber = 'Invalid phone number'
          }
        }
        if (!values.postcode) {
          errors.postcode = 'Required'
        } else {
          if ((values.postcode + '').length > 10) {
            errors.postcode = 'Invalid Postcode'
          }
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        setUserDetails((currentValues: any) => ({
          ...currentValues,
          ...values
        }))
        navigate(routeStrings.onboarding4Title)
      }}
    >
      {({ isSubmitting }) => (
        <Box p={3}>
          <Image
            src={theme.logos.logo_long}
            width="128px"
            className="location-logo"
          />
          <Box mt={6} bg={'white'} boxShadow={'lg'} p={8} rounded={'xl'}>
            <Heading size="xs" color="gray.400" my={6}>
              Step 3 of 6
            </Heading>
            <Box py={3}>
              <Heading size="sm" mb={2}>
                User registration
              </Heading>
            </Box>
            <Form>
              <Flex flexDir={['column']}>
                <Box width={['auto', '80%']} mr={2}>
                  <PhoneWrapper>
                    <Field
                      name="mobileNumber"
                      label="Mobile"
                      autoFocus={true}
                      component={Phone}
                    />
                  </PhoneWrapper>
                  <br />
                  <ErrorMessage name="mobileNumber" component={InputWarning} />
                </Box>

                <Box width={['auto', '80%']}>
                  <Field
                    type="number"
                    name="postcode"
                    id="postcode"
                    label="Postcode"
                    data-testid="onboarding-postcode-field"
                    placeholder="Enter your postcode"
                    component={FormInput}
                  />
                  <ErrorMessage name="postcode" component={InputWarning} />
                </Box>
              </Flex>

              <br />
              <Button
                ml="auto"
                type="submit"
                data-testid="onboarding-next"
                style={{ float: 'right', display: 'inline-block' }}
                disabled={isSubmitting}
              >
                Next
              </Button>
              <BackButton>Back</BackButton>
            </Form>
          </Box>
        </Box>
      )}
    </Formik>
  )
}
