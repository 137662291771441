import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  ModalProps,
  Stack,
  Text,
  useDisclosure,
  useToast,
  AccordionIcon,
  useTheme
} from '@chakra-ui/react'

import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {
  fetchLocationBookingSettings,
  updateLocationBookingSettings
} from '../../../../api'
import { EditableHTMLTextArea } from '../../../../components/EditableHTMLTextArea'
import { ILocationBooking, Location } from '../../../../types'
import { BusinessHoursConfig } from './BusinessHoursConfig'

interface Props extends ModalProps {
  location: Location
}

export const PanelExternalBookingsSettings: React.FC<Props> = ({
  location,
  isOpen,
  ...rest
}) => {
  const theme = useTheme()
  const toast = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [bookingSettings, setBookingSettings] = useState<ILocationBooking>()

  const {
    isOpen: isOpenBookingEmail,
    onClose: onCloseBookingEmail,
    onOpen: onOpenBookingEmail
  } = useDisclosure()

  const {
    isOpen: isOpenAbout,
    onClose: onCloseAbout,
    onOpen: onOpenAbout
  } = useDisclosure()

  const {
    isOpen: isOpenRules,
    onClose: onCloseRules,
    onOpen: onOpenRules
  } = useDisclosure()

  useEffect(() => {
    setIsSubmitting(false)
    fetchLocationSettings()
  }, [isOpen])

  const fetchLocationSettings = () => {
    if (isOpen && location) {
      setIsSubmitting(true)
      fetchLocationBookingSettings(location?.id)
        .then((res) => {
          setBookingSettings(res.data)
          setIsSubmitting(false)
        })
        .catch((err) => {
          setIsSubmitting(false)
        })
    }
  }

  const handleSaveBookingEmail = (text: string) => {
    if (!location || isSubmitting) return
    setIsSubmitting(true)
    updateLocationBookingSettings(location.id, {
      id: location.id,
      external_booking_message: text
    })
      .then((res) => {
        onCloseBookingEmail()
        setIsSubmitting(false)
        fetchLocationSettings()
        toast({
          status: 'success',
          description: 'External booking message updated'
        })
      })
      .catch((error) => {
        onCloseBookingEmail()
        setIsSubmitting(false)
        toast({
          status: 'error',
          title: 'An error occurred',
          description: error.response.data
        })
      })
  }

  const handleSaveAbout = (text: string) => {
    if (!location || isSubmitting) return
    setIsSubmitting(true)
    updateLocationBookingSettings(location.id, {
      id: location.id,
      booking_about: text
    })
      .then((res) => {
        onCloseAbout()
        setIsSubmitting(false)
        fetchLocationSettings()
        toast({
          status: 'success',
          description: 'Space About updated'
        })
      })
      .catch((error) => {
        onCloseAbout()
        setIsSubmitting(false)
        toast({
          status: 'error',
          title: 'An error occurred',
          description: error.response.data
        })
      })
  }

  const handleSaveRules = (text: string) => {
    if (!location || isSubmitting) return
    setIsSubmitting(true)
    updateLocationBookingSettings(location.id, {
      id: location.id,
      booking_rules: text
    })
      .then((res) => {
        onCloseRules()
        setIsSubmitting(false)
        fetchLocationSettings()
        toast({
          status: 'success',
          description: 'Space Rules updated'
        })
      })
      .catch((error) => {
        onCloseRules()
        setIsSubmitting(false)
        toast({
          status: 'error',
          title: 'An error occurred',
          description: error.response.data
        })
      })
  }

  return (
    <Stack>
      <Box>
        <Text fontWeight="bold" fontSize="sm" mb={1}>
          External Booking settings
        </Text>
        <Text color="#4F4F4F" fontSize="xs">
          Meeting rooms and workspaces{' '}
          <Text as={'span'} fontWeight="bold">
            enabled to be booked across the Spacecubed network
          </Text>{' '}
          will use these settings when an external member tries to book them.
        </Text>
        <Text color="#4F4F4F" fontSize="xs">
          An external member is someone registered in the platform and is hosted
          by a different coworking space, or a member in your network whose
          membership has finished, and only wants to book rooms or desks.
        </Text>
      </Box>
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton
              _expanded={{ bg: theme.colors.brandPrimary, color: 'white' }}
            >
              <Box as="span" flex="1" textAlign="left">
                Operations
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <BusinessHoursConfig location={location} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              _expanded={{ bg: theme.colors.brandPrimary, color: 'white' }}
            >
              <Box as="span" flex="1" textAlign="left">
                Booking Confirmation
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box borderTopWidth={1} pt={2}>
              <Text fontWeight="bold" fontSize="sm" mb={1}>
                Booking Confirmation email
              </Text>
              <Box color="#4F4F4F" fontSize="xs" mb={1}>
                <Text>
                  This message should give more context about the location to
                  anyone who has successfully booked a meeting a room. e.g:
                  parking, opening hours, facilities, access to the space, etc.
                </Text>
                <Text>
                  <Text as={'span'} fontWeight="bold">
                    All booking details&nbsp;
                  </Text>
                  will be included (Location, date and time)
                </Text>
                <EditableHTMLTextArea
                  initialValue={bookingSettings?.external_booking_message}
                  isSubmitting={isSubmitting}
                  isOpen={isOpenBookingEmail}
                  onOpen={onOpenBookingEmail}
                  onClose={onCloseBookingEmail}
                  onSaveText={handleSaveBookingEmail}
                />
              </Box>
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton
              _expanded={{ bg: theme.colors.brandPrimary, color: 'white' }}
            >
              <Box as="span" flex="1" textAlign="left">
                Location's profile
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box borderTopWidth={1} pt={2}>
              <Text fontWeight="bold" fontSize="sm" mb={1}>
                About this space
              </Text>
              <Box color="#4F4F4F" fontSize="xs" mb={1}>
                <Text>
                  Tell members and non-members what this space is about. Every
                  space has a its own profile. Make it shine!
                </Text>

                <EditableHTMLTextArea
                  initialValue={bookingSettings?.booking_about}
                  isSubmitting={isSubmitting}
                  isOpen={isOpenAbout}
                  onOpen={onOpenAbout}
                  onClose={onCloseAbout}
                  onSaveText={handleSaveAbout}
                />
              </Box>
            </Box>
            <Box borderTopWidth={1} pt={2}>
              <Text fontWeight="bold" fontSize="sm" mb={1}>
                Space Rules
              </Text>
              <Box color="#4F4F4F" fontSize="xs" mb={1}>
                <Text>
                  These rules are displayed on each Meeting Room page.
                </Text>
                <EditableHTMLTextArea
                  initialValue={bookingSettings?.booking_rules}
                  isSubmitting={isSubmitting}
                  isOpen={isOpenRules}
                  onOpen={onOpenRules}
                  onClose={onCloseRules}
                  onSaveText={handleSaveRules}
                />
              </Box>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Box></Box>
    </Stack>
  )
}
