import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useTheme,
  Flex,
  Box,
  Spacer
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  createOrUpdateLocation,
  updateLocationInvoicePrefix
} from '../../../api'
import { Button } from '../../../components/Button'
import { EditableInputText } from '../../../components/EditableInputText'
import { ImageUploadWithPreview } from '../../../components/ImageUploadWithPreview'
import { StyledInput } from '../../../components/StyledInputs'
import { StyledSelect } from '../../../components/StyledSelect'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { ILocationUpdateCreate, Location } from '../../../types'
import { PanelExternalBookingsSettings } from '../Components/LocationExternalBookings/PanelExternalBookingsSettings'
import { PanelLocationAdmins } from '../Components/LocationExternalBookings/PanelLocationAdmins'

interface Props extends ModalProps {
  locationToEdit?: Location
}
export const CreateLocationModal: React.FC<Props> = ({
  locationToEdit,
  ...rest
}) => {
  const toast = useToast()
  const theme: any = useTheme()

  const { isOpen, onClose } = rest
  const { platformTenant, cities } = usePlatformTenant()
  const [isUpdatingInvoicePrefix, setIsUpdatingInvoicePrefix] = useState(false)
  const [removeImage, setRemoveImage] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setRemoveImage(false)
    }
  }, [isOpen])

  if (!platformTenant) {
    return null
  }
  const StyledTab = styled(Tab)`
    /* tab / default */
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.brandPrimary};
    padding-bottom: 16px;
    border: unset;

    /* tab / active */
    &[aria-selected='true'] {
      border: unset;
      border-bottom: 2px solid ${theme.colors.brandPrimary};
      color: ${theme.colors.brandPrimary};
    }
  `
  const onUpdateInvoicePrefix = (valueToUpdate: string) => {
    if (!locationToEdit || isUpdatingInvoicePrefix) return
    let message = ''
    if (valueToUpdate === '') {
      message = 'Please, enter a valid value'
    } else if (valueToUpdate.length > 10) {
      message = 'Max 10 characters'
    }
    if (message != '') {
      alert(message)
      return
    }

    setIsUpdatingInvoicePrefix(true)
    updateLocationInvoicePrefix(locationToEdit.id, valueToUpdate)
      .then((res) => {
        setIsUpdatingInvoicePrefix(false)
      })
      .catch((err) => {
        setIsUpdatingInvoicePrefix(false)
      })
  }

  return (
    <Modal {...rest} size="3xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent minW={'450px'} borderRadius="6px">
        <ModalHeader pb={5}>
          {locationToEdit
            ? `Edit Location: ${locationToEdit.name}`
            : 'Create Location'}{' '}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <StyledTab>Basic Info</StyledTab>

              {locationToEdit && (
                <StyledTab isLazy data-testid="tab-external-booking">
                  External Bookings
                </StyledTab>
              )}
              {locationToEdit && (
                <StyledTab isLazy data-testid="tab-space-admins">
                  Space Admins
                </StyledTab>
              )}
            </TabList>

            <TabPanels>
              <TabPanel>
                <Formik
                  initialValues={{
                    name: locationToEdit?.name ?? '',
                    address: locationToEdit?.address ?? '',
                    city: locationToEdit?.city.id ?? 0,
                    thumbnail: undefined,
                    order_of_preference:
                      locationToEdit?.order_of_preference ?? -1,
                    hidden_to_signup: locationToEdit?.hidden_to_signup ?? false,
                    accounting_department_code: undefined
                  }}
                  onSubmit={(values, actions) => {
                    const newLocation: ILocationUpdateCreate = values

                    if (locationToEdit) {
                      newLocation.id = locationToEdit.id
                    }
                    createOrUpdateLocation(newLocation, platformTenant?.id)
                      .then(() => {
                        actions.setSubmitting(false)
                        toast({
                          status: 'success',
                          description:
                            'Location ' + locationToEdit ? 'Updated' : 'Created'
                        })
                        rest.onClose()
                      })
                      .catch(() => {
                        actions.setSubmitting(false)
                        toast({
                          status: 'error',
                          description: 'Failed to complete request'
                        })
                      })
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    isSubmitting,
                    touched,
                    errors,
                    handleSubmit
                  }) => (
                    <Form>
                      <ModalBody display={{ md: 'flex' }}>
                        <Stack mr={3} w="100%">
                          <Field
                            name="name"
                            render={({ field, form }) => (
                              <FormControl
                                isRequired
                                isInvalid={
                                  form.errors.name && form.touched.name
                                }
                                pt={2}
                              >
                                <FormLabel htmlFor="name">Name</FormLabel>
                                <StyledInput {...field} id="name" w="100%" />

                                <FormErrorMessage>
                                  {form.errors.name}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          />
                          <Flex>
                            <Box w={'50%'} pt={2} mr={2}>
                              <Field
                                name="address"
                                render={({ field, form }) => (
                                  <FormControl
                                    isRequired
                                    isInvalid={
                                      form.errors.address &&
                                      form.touched.address
                                    }
                                  >
                                    <FormLabel htmlFor="address">
                                      Address
                                    </FormLabel>
                                    <StyledInput
                                      {...field}
                                      id="address"
                                      w="100%"
                                    />

                                    <FormErrorMessage>
                                      {form.errors.address}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              />
                            </Box>
                            <Box w={'50%'} pt={2}>
                              <Field
                                name="category"
                                render={({ field, form }) => (
                                  <FormControl
                                    isRequired
                                    isInvalid={
                                      form.errors.city && form.touched.city
                                    }
                                  >
                                    <FormLabel htmlFor="city">City</FormLabel>
                                    <StyledSelect
                                      placeholder="Select a city"
                                      value={form.values.city}
                                      name="city"
                                      onChange={(e) => {
                                        setFieldValue(
                                          'city',
                                          parseInt(e.target.value)
                                        )
                                      }}
                                    >
                                      {cities.map((city) => (
                                        <option key={city.id} value={city.id}>
                                          {city.name}
                                        </option>
                                      ))}
                                    </StyledSelect>

                                    <FormErrorMessage>
                                      {form.errors.category}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              />
                            </Box>
                          </Flex>
                          {locationToEdit && (
                            <Flex py={2} w={['100%', '70%']}>
                              <Stack spacing={0}>
                                <Text fontSize={'md'}>
                                  Accounting department code
                                </Text>
                                <Text fontSize="xx-small">
                                  This code is used to identify lineitems on
                                  invoices with one of your coworking spaces for
                                  accounting purposes. Ex "Flux" (max length 10
                                  characters)
                                </Text>
                                <EditableInputText
                                  defaultValue={
                                    locationToEdit.invoice_prefix?.prefix ?? ''
                                  }
                                  onSubmitValue={onUpdateInvoicePrefix}
                                  isUpdating={isUpdatingInvoicePrefix}
                                />
                              </Stack>
                              <Spacer />
                            </Flex>
                          )}
                          {!locationToEdit && (
                            <Field
                              name="accounting_department_code"
                              render={({ field, form }) => (
                                <FormControl
                                  isRequired
                                  isInvalid={
                                    form.errors.accounting_department_code &&
                                    form.touched.accounting_department_code
                                  }
                                  pt={2}
                                >
                                  <FormLabel htmlFor="accounting_department_code">
                                    Accounting department code
                                  </FormLabel>
                                  <Text fontSize="xs">
                                    This code is used to identify lineitems on
                                    invoices with one of your coworking spaces
                                    for accounting purposes. Ex "Flux" (max
                                    length 10 characters)
                                  </Text>
                                  <StyledInput
                                    placeholder={values.name}
                                    maxLength={10}
                                    {...field}
                                    id="accounting_department_code"
                                    w="10em"
                                  />

                                  <FormErrorMessage>
                                    {form.errors.accounting_department_code}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />
                          )}
                          <Field
                            name="hidden_to_signup"
                            render={({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.hidden_to_signup &&
                                  form.touched.hidden_to_signup
                                }
                                pt={2}
                              >
                                <FormLabel htmlFor="hidden_to_signup">
                                  Hide from self serve sign-up
                                </FormLabel>
                                <Checkbox
                                  {...field}
                                  isChecked={values.hidden_to_signup}
                                  id="hidden_to_signup"
                                  w="100%"
                                />

                                <FormErrorMessage>
                                  {form.errors.hidden_to_signup}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          />
                          <Field
                            name="thumbnail"
                            render={({ field, form }) => (
                              <FormControl
                                // the image is required when creating a new location
                                // when editing, the image can be left out
                                isRequired={locationToEdit ? false : true}
                                isInvalid={
                                  form.errors.thumbnail &&
                                  form.touched.thumbnail
                                }
                                pb={8}
                                borderBottom="1px solid #E0E0E0"
                              >
                                <FormLabel htmlFor="thumbnail">
                                  Display image
                                </FormLabel>
                                <Text mb="16px" fontSize="xs" color="eastBay07">
                                  Only .jpg and .png files. Recommended
                                  dimensions of 160px x 160px.
                                </Text>
                                <ImageUploadWithPreview
                                  h="160px"
                                  initialUrl={locationToEdit?.thumbnail_url}
                                  uploadedImage={values.thumbnail}
                                  setUploadedImage={(image) =>
                                    setFieldValue('thumbnail', image)
                                  }
                                  setRemoveImage={(state) =>
                                    setRemoveImage(state)
                                  }
                                  removeImage={removeImage}
                                />

                                <FormErrorMessage>
                                  {form.errors.display_image_file}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          />
                        </Stack>
                        <Stack></Stack>
                      </ModalBody>

                      <ModalFooter justifyContent="flex-start" pt={2}>
                        <Button disabled={isSubmitting} type="submit" mr={3}>
                          Save
                        </Button>
                        <Button variant="secondary" onClick={rest.onClose}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </TabPanel>
              {locationToEdit && (
                <TabPanel>
                  <PanelExternalBookingsSettings
                    location={locationToEdit}
                    {...rest}
                  />
                </TabPanel>
              )}
              {locationToEdit && (
                <TabPanel>
                  <PanelLocationAdmins location={locationToEdit} {...rest} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
