import React from 'react'
import { StatusBox } from './StatusBox'

export const RenderStatus = (status) => {
  return (
    <StatusBox
      status={status}
      mapping={{
        PENDING: { color: 'yellow' },
        ERROR: { color: 'negative' },
        COMPLETED: { color: 'positive' },
        APPROVED: { color: 'positive' },
        CHANGE: { color: 'positive' },
        CANCELLATION: { color: 'negative' }
      }}
    />
  )
}
