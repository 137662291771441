export const USER_SUPPORT_LINK = 'mailto:ophelie@spacecubed.com'

/** https://emailregex.com/ */
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const SPACECUBED_HUBSPOT_CODE = '5849306'

/*
See linear ticket for context
https://linear.app/exponent/issue/EXP-1029/mirvac-scope-for-customised-signup

This Constant is added as part of this specialized feature built for allendale tenants in the building that Fern is located.
the tenants in the building are onboarded to this specific plan and have some customizations to the frontend app behaviour
The implementation has been done using a role assigned to users who have this as their active/ongoing plan.

See rbac.tsx 

*/
export const FERN_ALLENDALE_PLAN_UUID = 'fe00fef6-365f-46c9-b872-2c057d8886dd'
export const ILUMA_PLAN_UUID = '46699d8e-525c-11ec-bf63-0242ac130002'
export const SKELETON_SIX_ROWS = [1, 2, 3, 4, 5, 6]
export const SKELETON_TEN_ROWS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

export const WORKSPACE_HOT_DESK = 'HOT_DESK'
export const WORKSPACE_DEDICATED_DESK = 'dedicated-desk'
export const WORKSPACE_PRIVATE_OFFICE = 'PRIVATE_OFFICE'
export const WORKSPACE_DAILY_PASS = 'daily-pass'
// export const WORKSPACE_MAIN_SPACE = 'main-space'

export const WORKSPACES_CATEGORIES = [
  { value: WORKSPACE_HOT_DESK, label: 'Hot Desk' },
  { value: WORKSPACE_PRIVATE_OFFICE, label: 'Private Office' }
]

export const WORKSPACE_TEXTS = {
  [WORKSPACE_HOT_DESK]: {
    title: 'Day pass',
    description:
      'Book a desk in our shared workspace and enjoy the benefits of a vibrant coworking community.',
    selectLabel: 'Select a Day Pass',
    selectPlaceholder: ' -'
  },
  [WORKSPACE_PRIVATE_OFFICE]: {
    title: 'Private Office pass',
    description:
      'Book a private office for you and your team and enjoy the benefits of a vibrant coworking community.',
    selectLabel: 'Select the office for your team',
    selectPlaceholder: ' -'
  }
}
