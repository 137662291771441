import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
  Wrap,
  WrapItem
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAppTourContextProvider } from '../../../context/app-tour-context'
import {
  IOfficeDetail,
  IOfficeSubscriptionManager,
  IOrganizationBasic
} from '../../../types'
import { Actions, useRBAC } from '../../../utils/rbac'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import {
  useOffice,
  useOfficeAssignationRequests,
  useOfficeSubscriptions
} from '../../../utils/apiHooks'
import { useNavigate } from 'react-router-dom'
import { DrawerComponent } from '../Components/DrawerComponent'
import { TeamLink } from '../Tables/TeamLink'
import { Button } from '../../../components/Button'
import { OfficeRequestChangeModal } from '../Modals/OfficeRequestChangeModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDay, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { FeatureComponent } from '../../../components/FeatureComponent'
import { OfficeAssignationModal } from '../Modals/OfficeAssignationModal'
import { CalendarCustomIcon, DotsIcon } from '../../../components/icons/icons'

interface Props {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  officeId?: number
}

export const AdminOfficeManagementDrawer: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose, officeId } = props
  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen
  } = useDisclosure()
  const {
    isOpen: isAssignationOpen,
    onClose: onAssignationClose,
    onOpen: onAssignationOpen
  } = useDisclosure()
  const { adminTourActive, setRun } = useAppTourContextProvider()
  const [organization, setOrganization] = useState<IOrganizationBasic>()
  const [manager, setManager] = useState<IOfficeSubscriptionManager>()

  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  const { platformTenant } = usePlatformTenant()
  const navigate = useNavigate()

  const { data: office, error } = useOffice(officeId, {
    refreshInterval: 0,
    revalidateOnFocus: false
  })
  const { data: managers } = useOfficeSubscriptions(office?.id, {})

  const {
    data: pendingRequests,
    mutate: mutateRequests,
    isLoading: isLoadingRequests
  } = useOfficeAssignationRequests(
    'PENDING',
    isOpen ? platformTenant?.id : undefined,
    office?.id
  )
  const canAdministerOffices = useRBAC(Actions.AdministerOffice)

  const handleOnClose = () => {
    onClose()
  }

  const handleOnOpen = () => {
    onOpen()
  }

  const handleAssignationClose = () => {
    onAssignationClose()
    mutateRequests()
  }

  useEffect(() => {
    if (!officeId) {
      // navigate({
      //   pathname: 'admin/dashboard/locations'
      // })
      handleOnClose()
    } else {
      onOpen()
      mutateRequests()
    }
  }, [officeId])

  return (
    <DrawerComponent
      isOpen={isOpen}
      onClose={handleOnClose}
      size={'lg'}
      title={`Office Management`}
    >
      <Stack spacing={4}>
        <Card variant={'outline'}>
          <CardHeader>
            <Flex justifyContent={'space-between'}>
              <Box>
                <Badge
                  mb={1}
                  colorScheme={office?.organization ? 'green' : 'blue'}
                >
                  {office?.organization ? 'Leased' : 'Vacant'}
                </Badge>
                <Heading size="md">
                  {office?.name} @ {office?.location.name}
                </Heading>
              </Box>

              {canAdministerOffices && (
                <Button type="button" onClick={onAssignationOpen} mr={3}>
                  Assign a Team
                </Button>
              )}
            </Flex>
          </CardHeader>
          <CardBody>
            <Wrap>
              {[
                { key: 'rate', value: office?.rate },
                {
                  key: 'meeting_credit_rate',
                  value: office?.meeting_credit_rate
                },
                {
                  key: 'xero_account_code',
                  value: office?.xero_account_code?.code
                }
              ].map((item, i) => (
                <WrapItem key={i} w={'40%'}>
                  <Box>
                    <Text
                      fontSize="md"
                      textTransform="capitalize"
                      fontWeight={'bold'}
                    >
                      {item.key}
                    </Text>
                    <Text pb="2" fontSize="xs">
                      {item?.value}
                    </Text>
                  </Box>
                </WrapItem>
              ))}
            </Wrap>
            {pendingRequests &&
              !isLoadingRequests &&
              pendingRequests.length > 0 && (
                <Stack spacing={2} mt={4}>
                  <Divider />
                  <Text
                    fontWeight={'bold'}
                    fontSize={'sm'}
                    textTransform={'uppercase'}
                    color={'grey'}
                  >
                    Pending Requests
                  </Text>
                  {pendingRequests.map((request, index) => (
                    <Box key={index} flexDirection={['column', 'row']}>
                      <Text
                        fontSize="sm"
                        textTransform="capitalize"
                        fontWeight={'bold'}
                      >
                        {request.organization.name}
                      </Text>
                      <Text pb="2" fontSize="xs">
                        To start on {request.start_date}
                      </Text>
                    </Box>
                  ))}
                </Stack>
              )}
          </CardBody>
        </Card>
        <Stack spacing={2}>
          <Text
            fontWeight={'medium'}
            fontSize={'md'}
            textTransform={'uppercase'}
            color={'grey'}
            my={2}
          >
            Assigned Teams
          </Text>
          {managers &&
            managers.map((manager, index) => (
              <Box key={index}>
                <OfficeManagerCard
                  key={manager.id}
                  office={office}
                  manager={manager}
                  actions={
                    !canAdministerOffices
                      ? []
                      : [
                          {
                            id: 'manage',
                            text: 'Manage',
                            testIdPrefix: 'manage',
                            action: () => {
                              setManager(manager)
                              setOrganization(manager.organization)
                              onModalOpen()
                            }
                          }
                        ]
                  }
                />
                <Divider mt={8} />
              </Box>
            ))}
          {managers && managers.length === 0 && (
            <Text fontSize={'md'} textAlign={'center'} color={'#bababa'} my={2}>
              No team is assigned to this office
            </Text>
          )}
        </Stack>
      </Stack>
      <OfficeRequestChangeModal
        office={office}
        organization={organization}
        closeModal={onModalClose}
        isOpen={isModalOpen}
        manager={manager}
      />
      <OfficeAssignationModal
        office={office}
        closeModal={handleAssignationClose}
        isOpen={isAssignationOpen}
      />
    </DrawerComponent>
  )
}

interface OfficeManagerCardProps {
  manager: IOfficeSubscriptionManager
  office?: IOfficeDetail
  actions: {
    id: string
    text: string
    testIdPrefix: string
    action: () => void
  }[]
}

const OfficeManagerCard: React.FC<OfficeManagerCardProps> = ({
  manager,
  office,
  actions
}) => {
  const {
    organization,
    current_subscription,
    next_subscription,
    start_date,
    end_date,
    id
  } = manager

  return (
    <Box>
      <Flex justifyContent={'space-between'}>
        <Flex
          flexDirection={'row'}
          w={'100%'}
          justifyContent={'start'}
          alignItems={'center'}
        >
          <TeamLink
            organizationId={organization.id}
            organizationName={organization.name}
            external
            style={{
              fontSize: '18px',
              marginLeft: '10px',
              marginRight: '10px'
            }}
          />
        </Flex>
        {actions.length > 0 && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<DotsIcon />}
              variant="outline"
            />
            <MenuList>
              <MenuGroup title="Manage">
                <MenuItem
                  icon={<CalendarCustomIcon />}
                  onClick={() =>
                    actions.find((action) => action.id === 'manage')?.action()
                  }
                >
                  Request a change
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        )}
      </Flex>

      <HStack spacing={10} pr={10} mt={2}>
        <FeatureComponent
          icon={<FontAwesomeIcon icon={faCalendarDay} size={'1x'} />}
          iconBg={'blue.50'}
          text={'Start Date'}
          subtitle={start_date}
        />

        <FeatureComponent
          icon={<FontAwesomeIcon icon={faCalendarDay} size={'1x'} />}
          iconBg={end_date ? 'red.300' : 'blue.50'}
          text={end_date ? 'Ends on' : 'Ongoing'}
          subtitle={end_date}
        />
        <FeatureComponent
          icon={<FontAwesomeIcon icon={faDollarSign} size={'1x'} />}
          iconBg={'blue.50'}
          text={'Approved rate'}
          subtitle={`${
            current_subscription?.override_value ?? office?.rate ?? 'Unknown'
          }`}
        />
      </HStack>
    </Box>
  )
}
