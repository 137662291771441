import {
  Text,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
  Stack,
  Checkbox,
  Divider,
  Flex,
  useToast,
  HStack,
  Heading,
  Alert
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { BaseModal } from '../../../components/modals/BaseModal'
import { IOfficeAssignationRequest } from '../../../types'
import { format } from 'date-fns'
import { declineChangeRequest, approveChangeRequest } from '../../../api'
import { FeatureComponent } from '../../../components/FeatureComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDesktop,
  faMoneyBillWave,
  faUserAlt,
  faUsersRectangle
} from '@fortawesome/free-solid-svg-icons'
import { CalendarCustomIcon } from '../../../components/icons/icons'

interface IModalInput {
  request?: IOfficeAssignationRequest
  closeModalCallback: () => void
}

export const ReviewOfficeAssignationRequestModal: React.FC<IModalInput> = (
  input
) => {
  const { request, closeModalCallback } = input
  const toast = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [overrideValue, setOverrideValue] = useState(
    request?.override_value ? request?.override_value : undefined
  )
  const [overrideReason, setOverrideReason] = useState<string | undefined>(
    request?.override_reason
  )
  const [
    isFirstSubscriptionNonChargeable,
    setIsFirstSubscriptionNonChargeable
  ] = useState(false)

  function closeModal() {
    setIsSubmitting(false)
    setOverrideValue(undefined)
    setOverrideReason(undefined)
    closeModalCallback()
  }

  useEffect(() => {
    setOverrideValue(request?.override_value)
    setOverrideReason(request?.override_reason)
    setIsFirstSubscriptionNonChargeable(false)
  }, [request])

  const validate = () => {
    let isValid = true
    if (overrideValue) {
      if (!overrideReason) {
        toast({
          title: 'Please provide a reason for the rate change',
          status: 'error'
        })
        isValid = false
      } else if (overrideReason.length > 100) {
        toast({ title: 'Reason is too long', status: 'error' })
        isValid = false
      }
      if (isNaN(overrideValue)) {
        toast({ title: 'Override value must be a number', status: 'error' })
        isValid = false
      } else if (overrideValue < 0) {
        toast({
          title: 'Override value must be greater than 0',
          status: 'error'
        })
        isValid = false
      }
    }
    return isValid
  }

  function approveRequest() {
    if (request && validate()) {
      if (isSubmitting) return
      setIsSubmitting(true)
      approveChangeRequest({
        requestId: request.id,
        overrideValue,
        overrideReason,
        isFirstSubscriptionNonChargeable
      })
        .then(() => {
          toast({
            status: 'success',
            description: 'Request Approved'
          })
          closeModal()
        })
        .catch(() => {
          toast({
            status: 'error',
            description: 'Failed to Approve Request'
          })
          closeModal()
        })
    }
  }

  function declineRequest() {
    if (request) {
      declineChangeRequest(request.id)
        .then(() => {
          toast({
            status: 'success',
            description: 'Request Declined'
          })
          closeModal()
        })
        .catch(() => {
          toast({
            status: 'error',
            description: 'Failed to Decline Request'
          })
          closeModal()
        })
    }
  }

  if (request === undefined) {
    return null
  }

  return (
    <BaseModal
      title="Office Assignation Request"
      isOpen={request !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText="Approve Request"
      primaryAction={approveRequest}
      secondaryButtonText="Decline Request"
      secondaryAction={declineRequest}
      isSubmitting={isSubmitting}
      isLoading={isSubmitting}
    >
      <Stack spacing={4}>
        <HStack spacing={5}>
          <FeatureComponent
            icon={<FontAwesomeIcon icon={faUserAlt} />}
            text="Requested By"
            subtitle={request.requested_by?.name ?? ''}
          />
          <FeatureComponent
            icon={<CalendarCustomIcon />}
            text={`Requested on`}
            subtitle={`${format(Date.parse(request.created_at), 'dd/MM/yyyy')}`}
          />
        </HStack>
        <HStack spacing={5}>
          <FeatureComponent
            text="Office"
            subtitle={`${request.office.name} @ ${request.office.location.name}`}
            icon={<FontAwesomeIcon icon={faDesktop} />}
            iconBg={'gray.100'}
          />

          <FeatureComponent
            text="Current rate"
            subtitle={`$${request.office.rate}`}
            icon={<FontAwesomeIcon icon={faMoneyBillWave} />}
            iconBg={'gray.100'}
          />
        </HStack>
        <FeatureComponent
          text="Team"
          subtitle={`${request.organization.name}`}
          icon={<FontAwesomeIcon icon={faUsersRectangle} />}
          iconBg={'gray.100'}
        />
        <HStack spacing={5}>
          <FeatureComponent
            text={`Start date`}
            subtitle={`${format(Date.parse(request.start_date), 'dd/MM/yyyy')}`}
            icon={<CalendarCustomIcon />}
            iconBg={'gray.100'}
          />
          {request.end_date && (
            <FeatureComponent
              text={`End date`}
              subtitle={`${format(Date.parse(request.end_date), 'dd/MM/yyyy')}`}
            />
          )}
        </HStack>
        {request.override_value !== null &&
          request.override_value !== undefined && (
            <HStack spacing={5}>
              <FeatureComponent
                text="Requested Rate"
                subtitle={`$${request.override_value}`}
              />
              <FeatureComponent
                text="Reason"
                subtitle={request.override_reason ?? ''}
              />
            </HStack>
          )}
      </Stack>

      <>
        <Divider my={3} />
        <Heading size={'sm'} mb={2} color={'red.500'}>
          Requested Changes
        </Heading>
        <Text fontSize={'xs'} color={'gray.500'}>
          Make changes to this request
        </Text>
        <Checkbox
          isChecked={isFirstSubscriptionNonChargeable}
          onChange={(e) =>
            setIsFirstSubscriptionNonChargeable(e.target.checked)
          }
          size={'sm'}
          my={1}
        >
          Should the first subscription be marked as non billable?
        </Checkbox>

        <Flex mt={2} alignItems={'center'} justifyContent={'space-between'}>
          <FormLabel fontSize={'sm'}>Override Value:</FormLabel>
          <InputGroup width={'50%'} size="sm">
            <InputLeftElement children="$" />
            <Input
              value={overrideValue}
              onChange={(e) => setOverrideValue(parseFloat(e.target.value))}
              id="overrideValue"
              placeholder="Override Value"
              variant="flushed"
              type="number"
            />
          </InputGroup>
        </Flex>

        {overrideValue && !isNaN(overrideValue) && (
          <Flex mt={2} alignItems={'center'} justifyContent={'space-between'}>
            <FormLabel fontSize={'sm'}>Override Reason:</FormLabel>
            <InputGroup width={'50%'} size="sm">
              <Input
                value={overrideReason}
                onChange={(e) => setOverrideReason(e.target.value)}
                id="overrideReason"
                placeholder="Override Reason"
                variant="flushed"
                maxLength={100}
              />
            </InputGroup>
          </Flex>
        )}
      </>
      <Alert status="warning" mt={4}>
        <Text fontSize="sm">
          By approving this request,{' '}
          <strong>{request.organization.name}</strong> will be assigned to the
          office{' '}
          <strong>
            {request.office.name} @ {request.office.location.name}
          </strong>
          , commencing its membership on{' '}
          <strong>
            {request.start_date}

            {request.end_date ? ` and ending on ${request.end_date}` : ''}
          </strong>
          . <br />
          <strong>
            The rate will be of $
            {overrideValue && !isNaN(overrideValue)
              ? overrideValue
              : request.office.rate}{' '}
          </strong>
          exc. GST.
        </Text>
      </Alert>
    </BaseModal>
  )
}
