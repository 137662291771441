import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Switch,
  useToast
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { createWorkspace, updateWorkspace } from '../../../api'
import { Button } from '../../../components/Button'
import { StyledInput } from '../../../components/StyledInputs'
import { StyledSelect } from '../../../components/StyledSelect'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { IWorkspaceSuperAdmin, INewWorkspace } from '../../../types'
import { useAccountCodes } from '../../../utils/apiHooks'
import {
  WORKSPACE_HOT_DESK,
  WORKSPACE_PRIVATE_OFFICE,
  WORKSPACES_CATEGORIES
} from '../../../utils/constants'
import { validateNumberError } from '../../MemberDashboard/MeetingRooms/meetingRoomUtils'

interface IModalInput {
  isOpen: boolean
  onClose: (status?: boolean) => void
  workspaceToEdit?: IWorkspaceSuperAdmin
}

export const CreateWorkspaceModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  workspaceToEdit
}) => {
  const toast = useToast()
  const { platformTenant } = usePlatformTenant()
  const { data: accountCodes } = useAccountCodes(platformTenant?.id)

  const isMainSpace = workspaceToEdit?.is_main_space ?? false

  function validateRate(value) {
    let error
    if (!value) {
      error = 'This rate is required'
    } else if (isNaN(value)) {
      error = 'This rate must be numeric'
    } else if (value < 0) {
      error = 'This rate cannot be negative'
    }
    return error
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
      <ModalOverlay />
      <ModalContent borderRadius="6px">
        <ModalHeader pb={5}>
          {workspaceToEdit ? 'Edit ' : 'Create '}Workspace{' '}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          validateOnChange={false}
          initialValues={{
            id: -1,
            name: workspaceToEdit?.name ?? '',
            location: workspaceToEdit?.location ?? 1,
            is_main_space: workspaceToEdit?.is_main_space ?? false,
            is_bookable: workspaceToEdit?.is_bookable ?? true,
            public_booking: workspaceToEdit?.public_booking ?? true,
            category: workspaceToEdit?.category ?? WORKSPACE_HOT_DESK,
            capacity: workspaceToEdit?.capacity || 0,
            xero_account_code: workspaceToEdit?.xero_account_code ?? -1,
            member_price_half_day_price:
              workspaceToEdit?.member_price_half_day_price || 10,
            member_price_full_day_price:
              workspaceToEdit?.member_price_full_day_price || 10,
            non_member_price_half_day_price:
              workspaceToEdit?.non_member_price_half_day_price || 10,
            non_member_price_full_day_price:
              workspaceToEdit?.non_member_price_full_day_price || 10
          }}
          onSubmit={(values, actions) => {
            const newWorkspace: INewWorkspace = values

            if (newWorkspace.category === WORKSPACE_HOT_DESK) {
              newWorkspace.capacity = 1
            }
            if (
              newWorkspace.category === WORKSPACE_PRIVATE_OFFICE &&
              newWorkspace.capacity <= 1
            ) {
              toast({
                status: 'error',
                description: 'Private office capacity must be greater than 1'
              })
              actions.setSubmitting(false)
              return
            }
            if (workspaceToEdit) {
              // update
              updateWorkspace(workspaceToEdit.id, newWorkspace)
                .then(() => {
                  toast({
                    status: 'success',
                    description: 'Workspace Updated'
                  })
                  onClose(true)
                })
                .catch((error) => {
                  toast({
                    status: 'error',
                    description: 'Failed to update workspace'
                  })
                })
                .finally(() => {
                  actions.setSubmitting(false)
                })
            } else {
              // create

              createWorkspace(newWorkspace)
                .then(() => {
                  toast({
                    status: 'success',
                    description: 'Workspace Created'
                  })
                  onClose(true)
                })
                .catch((e) => {
                  console.log({ e })
                  toast({
                    status: 'error',
                    description: e.response.data
                  })
                })
                .finally(() => {
                  actions.setSubmitting(false)
                })
            }
          }}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            touched,
            errors,
            handleSubmit
          }) => (
            <Form>
              <ModalBody>
                <SimpleGrid mt="32px" columns={[1, 2]} spacing={6}>
                  <Field
                    name="location"
                    render={({ field, form }) => (
                      <FormControl
                        isDisabled={!!workspaceToEdit}
                        isRequired
                        isInvalid={
                          form.errors.location && form.touched.location
                        }
                      >
                        <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                          Location
                        </FormLabel>
                        <StyledSelect
                          {...field}
                          placeholder="Select a location"
                          value={form.values.location}
                          name="location"
                          onChange={(e) => {
                            setFieldValue('location', parseInt(e.target.value))
                          }}
                        >
                          {platformTenant?.locations?.map((location) => (
                            <option key={location.id} value={location.id}>
                              {location.name}
                            </option>
                          ))}
                        </StyledSelect>

                        <FormErrorMessage>
                          {form.errors.category}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="xero_account_code"
                    render={({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.xero_account_code &&
                          form.touched.xero_account_code
                        }
                      >
                        <FormLabel color="#4F4F4F" mb={1}>
                          XERO account code
                        </FormLabel>
                        <StyledSelect
                          placeholder="Select the XERO account code"
                          {...field}
                        >
                          {accountCodes?.map((accountCode) => (
                            <option
                              key={accountCode.code}
                              value={accountCode.id}
                            >
                              {`${accountCode.title} (${accountCode.code})`}
                            </option>
                          ))}
                        </StyledSelect>

                        <FormErrorMessage>
                          {form.errors.xero_account_code}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </SimpleGrid>
                <Field
                  name="name"
                  render={({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.name && form.touched.name}
                      pt={2}
                    >
                      <FormLabel htmlFor="name">Name</FormLabel>
                      <StyledInput {...field} id="name" w="100%" />

                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                />

                {/* {!workspaceToEdit ? (
                  <Field
                    name="is_main_space"
                    render={({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.is_main_space &&
                          form.touched.is_main_space
                        }
                      >
                        <FormLabel color="#4F4F4F" mt={4}>
                          Is this the main space in the location
                          <Switch
                            ml={2}
                            size="sm"
                            colorScheme="red"
                            id="is_main_space"
                            isChecked={form.values.is_main_space}
                            {...field}
                          />
                        </FormLabel>

                        <FormErrorMessage>
                          {form.errors.is_main_space}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                ) : (
                  <>
                    {isMainSpace ? (
                      <FormLabel color="#4F4F4F" mt={4}>
                        This is the main space
                      </FormLabel>
                    ) : null}
                  </>
                )} */}
                <Divider mt={4} />

                <SimpleGrid
                  mt="32px"
                  columns={{
                    base: 1,
                    md: 2
                  }}
                  spacing={6}
                >
                  <Field
                    name="category"
                    render={({ field, form }) => (
                      <FormControl
                        isDisabled={!!workspaceToEdit}
                        isRequired
                        isInvalid={
                          form.errors.category && form.touched.category
                        }
                      >
                        <FormLabel mb={1}>Workspace category</FormLabel>
                        <StyledSelect
                          {...field}
                          placeholder="Select a category"
                          value={form.values.category}
                          name="category"
                          onChange={(e) => {
                            setFieldValue('category', e.target.value)
                          }}
                        >
                          {WORKSPACES_CATEGORIES.map((category) => (
                            <option key={category.value} value={category.value}>
                              {category.label}
                            </option>
                          ))}
                        </StyledSelect>

                        <FormErrorMessage>
                          {form.errors.category}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="capacity"
                    validate={validateNumberError}
                    render={({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.capacity && form.touched.capacity
                        }
                      >
                        <FormLabel htmlFor="capacity">Capacity</FormLabel>
                        <InputGroup>
                          <StyledInput
                            placeholder="1"
                            {...field}
                            id="capacity"
                            w="100%"
                            isDisabled={
                              values.category !== WORKSPACE_PRIVATE_OFFICE
                            }
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.capacity}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />

                  <Field
                    name="is_bookable"
                    render={({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.is_bookable && form.touched.is_bookable
                        }
                      >
                        <FormLabel color="#4F4F4F" mb={1}>
                          Accepts bookings
                          <Switch
                            ml={2}
                            size="sm"
                            colorScheme="red"
                            id="is_bookable"
                            isChecked={form.values.is_bookable}
                            {...field}
                          />
                        </FormLabel>

                        <FormErrorMessage>
                          {form.errors.is_bookable}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="public_booking"
                    render={({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.public_booking &&
                          form.touched.public_booking
                        }
                      >
                        <FormLabel color="#4F4F4F" mb={1}>
                          Accepts public bookings
                          <Switch
                            ml={2}
                            size="sm"
                            colorScheme="red"
                            id="public_booking"
                            isChecked={form.values.public_booking}
                            {...field}
                          />
                        </FormLabel>

                        <FormErrorMessage>
                          {form.errors.is_bookable}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />

                  <Field
                    name="member_price_half_day_price"
                    validate={validateRate}
                    render={({ field, form }) => (
                      <FormControl
                        isReadOnly={!!workspaceToEdit}
                        isRequired
                        isInvalid={
                          form.errors.member_price_half_day_price &&
                          form.touched.member_price_half_day_price
                        }
                      >
                        <FormLabel htmlFor="member_price_half_day_price">
                          Member price per Half Day:
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            children="$"
                            pr="1.5rem"
                            color="eastBay05"
                          />
                          <StyledInput
                            placeholder="0"
                            {...field}
                            id="member_price_half_day_price"
                            w="100%"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.member_price_half_day_price}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="member_price_full_day_price"
                    validate={validateRate}
                    render={({ field, form }) => (
                      <FormControl
                        isReadOnly={!!workspaceToEdit}
                        isRequired
                        isInvalid={
                          form.errors.member_price_full_day_price &&
                          form.touched.member_price_full_day_price
                        }
                      >
                        <FormLabel htmlFor="member_price_full_day_price">
                          Member price Full Day:
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            children="$"
                            pr="1.5rem"
                            color="eastBay05"
                          />
                          <StyledInput
                            placeholder="0"
                            {...field}
                            id="member_price_full_day_price"
                            w="100%"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.member_price_full_day_price}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="non_member_price_half_day_price"
                    validate={validateRate}
                    render={({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.non_member_price_half_day_price &&
                          form.touched.non_member_price_half_day_price
                        }
                      >
                        <FormLabel htmlFor="non_member_price_half_day_price">
                          Non-member Price per Half Day:
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            children="$"
                            pr="1.5rem"
                            color="eastBay05"
                          />
                          <StyledInput
                            placeholder="0"
                            {...field}
                            id="non_member_price_half_day_price"
                            w="100%"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.non_member_price_half_day_price}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="non_member_price_full_day_price"
                    validate={validateRate}
                    render={({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.non_member_price_full_day_price &&
                          form.touched.non_member_price_full_day_price
                        }
                      >
                        <FormLabel htmlFor="non_member_price_full_day_price">
                          Non-member price per Full Day:
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            children="$"
                            pr="1.5rem"
                            color="eastBay05"
                          />
                          <StyledInput
                            placeholder="0"
                            {...field}
                            id="non_member_price_full_day_price"
                            w="100%"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.non_member_price_full_day_price}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </SimpleGrid>
              </ModalBody>

              <ModalFooter justifyContent="flex-start">
                <Button disabled={isSubmitting} type="submit" mr={3}>
                  Save
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    onClose()
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
