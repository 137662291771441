import {
  Box,
  Flex,
  Text,
  useToast,
  Checkbox,
  Divider,
  HStack
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { requestOfficeAssignation } from '../../../api'
import { ChakraModal } from '../../../components/ChakraModal'

import { IOfficeDetail } from '../../../types'
import { Button } from '../../../components/Button'
import { FilterableOrganizationsSelect } from '../../../components/FilterableOrganizationsSelect'
import { CalendarFormInput } from '../../../components/CalendarFormInput'
import { useOfficeSubscriptions } from '../../../utils/apiHooks'
import { addDays, isAfter, subDays } from 'date-fns'
import { StyledSelect } from '../../../components/StyledSelect'
import { FeatureComponent } from '../../../components/FeatureComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faDollarSign,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import { StyledInput } from '../../../components/StyledInputs'
import endOfMonth from 'date-fns/endOfMonth'

interface IModalInput {
  isOpen: boolean
  office: IOfficeDetail | undefined
  closeModal: () => void
}

interface IDateRangeOption {
  startDate: Date | null
  endDate?: Date
}

export const OfficeAssignationModal: React.FC<IModalInput> = (input) => {
  const { isOpen, office, closeModal: closeCallback } = input

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedRange, setSelectedRange] = useState(0)

  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<number>(0)
  const [chosenStartDate, setChosenStartDate] = useState<Date>(new Date())
  const [chosenEndDate, setChosenEndDate] = useState<Date>()
  const [overrideValue, setOverrideValue] = useState<string>()
  const [overrideReason, setOverrideReason] = useState<string>()
  const [notifyAdmins, setNotifyAdmins] = useState(false)
  const [ranges, setRanges] = useState<IDateRangeOption[]>([
    { startDate: null, endDate: undefined }
  ])
  const [noEndDate, setNoEndDate] = useState(true)
  const [enableNoEndDate, setEnableNoEndDate] = useState(true)
  const {
    data: managers,
    isLoading: isLoadingManagers,
    mutate
  } = useOfficeSubscriptions(office?.id, {
    refreshInterval: 0,
    revalidateOnFocus: false
  })
  const toast = useToast()

  const closeModal = () => {
    setSelectedOrganizationId(0)
    setSelectedRange(0)
    setChosenEndDate(undefined)
    setChosenStartDate(new Date())
    setOverrideValue('')
    setOverrideReason('')
    closeCallback()
  }

  const validateForm = () => {
    let isValid = true
    if (!office) {
      return false
    }
    if (!selectedOrganizationId) {
      toast({ title: 'Please select an organization', status: 'error' })
      isValid = false
    }
    if (!chosenStartDate) {
      toast({ title: 'Please select a start date', status: 'error' })
      isValid = false
    }
    if (!noEndDate && !chosenEndDate) {
      toast({ title: 'Please select an end date', status: 'error' })
      isValid = false
    }
    if (overrideValue) {
      if (isNaN(parseFloat(overrideValue))) {
        toast({ title: 'Please enter a valid rate', status: 'error' })
        isValid = false
      }
      if (!overrideReason) {
        toast({
          title: 'Please provide a reason for the rate override',
          status: 'error'
        })
        isValid = false
      }
    }
    return isValid
  }

  const submit = () => {
    if (!validateForm()) return
    if (office && selectedOrganizationId) {
      const _overrideValue = overrideValue
        ? parseFloat(overrideValue)
        : undefined

      setIsSubmitting(true)
      requestOfficeAssignation(
        office.id,
        selectedOrganizationId,
        chosenStartDate,
        noEndDate ? undefined : chosenEndDate,
        _overrideValue,
        overrideReason,
        notifyAdmins
      )
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: `Office Request Created`
          })
          closeModal()
        })
        .catch((e) => {
          setIsSubmitting(false)
          toast({
            status: 'error',
            description: 'Failed to assign office'
          })
        })
    }
  }

  useEffect(() => {
    if (isOpen) {
      // fetch approved Office Managers

      mutate()
    }
  }, [isOpen])
  useEffect(() => {
    if (ranges && isOpen) {
      const hasEndDate = !!ranges[selectedRange].endDate
      const initialStartDate = ranges[selectedRange].startDate ?? new Date()
      const chosen = hasEndDate
        ? initialStartDate
        : addDays(endOfMonth(initialStartDate), 1)
      setChosenStartDate(chosen)
      setChosenEndDate(addDays(chosen, 1))
      if (ranges[selectedRange].endDate) {
        setNoEndDate(false)
        setEnableNoEndDate(false)
        setChosenEndDate(ranges[selectedRange].endDate)
      } else {
        setEnableNoEndDate(true)
        setChosenEndDate(endOfMonth(chosen))
      }
    }
  }, [selectedRange, isOpen])

  useEffect(() => {
    if (managers && isOpen) {
      const mRanges: IDateRangeOption[] = [
        { startDate: null, endDate: undefined }
      ]
      let currentDate = new Date()
      for (let i = 0; i < managers.length; i++) {
        const manager = managers[i]
        const r = mRanges[mRanges.length - 1]
        const parsedSubsStartDate = manager.start_date
          ? new Date(Date.parse(manager.start_date))
          : null
        const parsedSubsEndDate = manager.end_date
          ? new Date(Date.parse(manager.end_date))
          : null
        const parsedStartDate = parsedSubsStartDate
          ? parsedSubsStartDate
          : manager.start_date
          ? new Date(Date.parse(manager.start_date))
          : null
        const parsedEndDate = parsedSubsEndDate
          ? parsedSubsEndDate
          : manager.end_date
          ? new Date(Date.parse(manager.end_date))
          : null
        if (!parsedStartDate) {
          continue
        }
        if (!r.startDate) {
          if (isAfter(parsedStartDate, currentDate)) {
            r.startDate = currentDate
            r.endDate = subDays(parsedStartDate, 1)
            if (parsedEndDate) {
              mRanges.push({
                startDate: addDays(parsedEndDate, 1),
                endDate: undefined
              })
              currentDate = addDays(parsedStartDate, 1)
              continue
            } else {
              mRanges.push({
                startDate: addDays(parsedStartDate, 1),
                endDate: undefined
              })
              break
            }
          } else {
            if (!parsedEndDate) {
              r.startDate = currentDate
              break
            }
            r.startDate = addDays(parsedEndDate, 1)
            continue
          }
        } else {
          if (isAfter(parsedStartDate, currentDate)) {
            r.endDate = subDays(parsedStartDate, 1)
            if (parsedEndDate) {
              mRanges.push({
                startDate: addDays(parsedEndDate, 1),
                endDate: undefined
              })
              currentDate = addDays(parsedStartDate, 1)
              continue
            } else {
              break
            }
          } else {
            if (parsedEndDate) {
              r.startDate = addDays(parsedEndDate, 1)
              continue
            } else {
              r.startDate = addDays(parsedStartDate, 1)
              continue
            }
          }
        }
      }

      setRanges(mRanges)
      if (mRanges.length > 0) {
        setSelectedRange(0)
        setChosenStartDate(mRanges[0].startDate ?? new Date())
      }
    }
  }, [managers, isOpen])

  return (
    <ChakraModal
      title={`Office Assignation - ${office?.name}`}
      isOpen={isOpen}
      onClose={closeModal}
    >
      <HStack spacing={10}>
        <FeatureComponent
          icon={<FontAwesomeIcon icon={faBuilding} size={'xs'} />}
          iconBg={'gray.100'}
          text="Location"
          subtitle={office?.location.name}
          size="lg"
        />
        <FeatureComponent
          icon={<FontAwesomeIcon icon={faDollarSign} size={'xs'} />}
          iconBg={'gray.100'}
          text="Current rate"
          subtitle={office?.rate ? `$${office.rate}` : '$0.00'}
          size="lg"
        />
        <FeatureComponent
          icon={<FontAwesomeIcon icon={faUsers} size={'xs'} />}
          iconBg={'gray.100'}
          text="Capacity"
          subtitle={office?.desks ? `${office.desks.length}` : 'N/A'}
          size="lg"
        />
      </HStack>
      <Divider my={3} />
      <FilterableOrganizationsSelect
        name="Select a team"
        initialValue={selectedOrganizationId}
        onSelect={(n) => setSelectedOrganizationId(n)}
      />

      {ranges.length > 1 && (
        <Box mt={3}>
          <Text mt={2} fontWeight={'bold'}>
            Date Range
          </Text>
          <StyledSelect
            placeholder="Select a Date Range"
            value={`${selectedRange}`}
            onChange={(val) =>
              setSelectedRange(Number.parseInt(val.target.value))
            }
          >
            {ranges?.map((range, index) => {
              const { startDate, endDate } = range
              return (
                <option key={index} value={index.toString()}>
                  Option {index + 1}: {startDate?.toLocaleDateString()} -{' '}
                  {endDate ? endDate.toLocaleDateString() : 'Ongoing'}
                </option>
              )
            })}
          </StyledSelect>
        </Box>
      )}
      <Box mt={3}>
        <Box>
          <CalendarFormInput
            label=" Start Date"
            dateProps={{
              chosenDate: chosenStartDate,
              handleChangeDate: (value) => {
                setChosenStartDate(value)
                if (!noEndDate) {
                  setChosenEndDate(endOfMonth(value))
                }
              }
            }}
            minDate={ranges[selectedRange].startDate ?? new Date()}
            maxDate={ranges[selectedRange].endDate}
            closeOnSelect={true}
            placement="bottom"
          />
        </Box>
        {enableNoEndDate && (
          <Box>
            {' '}
            <Checkbox
              isChecked={noEndDate}
              isDisabled={!enableNoEndDate}
              onChange={(e) => {
                setNoEndDate(e.target.checked)
                setChosenEndDate(undefined)
              }}
            >
              Auto renew enabled
            </Checkbox>
            <Text fontSize={'xs'}>
              This will set the membership to automatically renew every month.{' '}
            </Text>
          </Box>
        )}
        {(!noEndDate || !enableNoEndDate) && (
          <Box mt={3}>
            <CalendarFormInput
              label={
                'Membership will end on ' +
                (ranges[selectedRange].endDate ? ' (Required)' : '')
              }
              dateProps={{
                chosenDate: chosenEndDate,
                handleChangeDate: setChosenEndDate
              }}
              minDate={
                chosenStartDate
                  ? addDays(chosenStartDate, 1)
                  : addDays(ranges[selectedRange].startDate ?? new Date(), 1)
              }
              maxDate={ranges[selectedRange].endDate}
              closeOnSelect={true}
              placement="bottom"
            />
            <Text fontSize={'xs'}>
              This office membership will end on the selected date.
            </Text>
          </Box>
        )}
        <Divider my={3} />
        <Text mt={3}>Alter rate value ($AUD per month)</Text>
        <StyledInput
          type="number"
          value={overrideValue}
          onChange={(e) => setOverrideValue(e.target.value)}
          id="numberOfDesks"
          placeholder="Enter a rate value ($AUD per month)"
          w="100%"
        />
        {!!overrideValue && (
          <>
            <Text>Enter a reason for the rate</Text>
            <StyledInput
              value={overrideReason}
              onChange={(e) => setOverrideReason(e.target.value)}
              id="numberOfDesks"
              w="100%"
            />
          </>
        )}
      </Box>

      <Flex flexDirection="row" mt={10}>
        <Button
          key="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          data-testid="assign"
          onClick={submit}
          mr="8px"
        >
          Send Request
        </Button>
        <Button
          data-testid="cancel"
          disabled={isSubmitting}
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </Flex>
    </ChakraModal>
  )
}
