import { Box, Flex, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { FilterableOrganizationsSelect } from '../../components/FilterableOrganizationsSelect'
import {
  ITab,
  SecondaryGroupingHeaderLayout
} from '../../components/GroupingHeaderLayout'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { useAuth } from '../../context/auth-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import {
  IOfficeAssignationRequest,
  IOfficeMembershipChangeRequest
} from '../../types'
import {
  useOfficeAssignationRequests,
  useOfficeMembershipChangeRequests,
  usePaginatedFilteredOfficeAssignationRequests,
  usePaginatedFilteredOfficeChangeRequests
} from '../../utils/apiHooks'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'

import { useFeatureFlags } from '../../context/feature-flag-context'
import { OfficeAssignationRequests } from './Tables/OfficeAssignationRequests'
import { OfficeMembershipChangeRequests } from './Tables/OfficeMembershipChangeRequests'

export interface TableDropdownOption {
  text: string
  testIdPrefix?: string
  variant: string
  onClick: (row: any) => void
}
const PENDING_REQUESTS = 'pending_requests'

const OFFICE_ASSIGNATION_REQUESTS = 'office_assignation_requests'
const OFFICE_CHANGE_REQUESTS = 'office_change_requests'

export const AdminDashboardOfficeRequests: React.FC = () => {
  const { adminTourActive, setRun } = useAppTourContextProvider()
  const {} = useFeatureFlags()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])

  const [requestsFilter, setRequestsFilter] = useState<string>(PENDING_REQUESTS)

  const [selectedOrganizationId, setSelectedOrganizationId] = useState(0)

  const [filterOfficeAssignRequests, setFilterOfficeAssignRequests] = useState({
    page: 1,
    organizationId: 0
  })

  const [filterOfficeChangeRequests, setFilterOfficeChangeRequests] = useState({
    page: 1,
    organizationId: 0
  })

  const { currentOrgUnit } = useAuth()
  const { platformTenant } = usePlatformTenant()

  const headerGroups: ITab[] = [
    {
      active: requestsFilter === PENDING_REQUESTS,
      text: 'Pending Requests',
      selectAction: () => {
        setRequestsFilter(PENDING_REQUESTS)
      }
    },
    {
      active: requestsFilter === OFFICE_ASSIGNATION_REQUESTS,
      text: 'Office Assignations',
      selectAction: () => {
        setRequestsFilter(OFFICE_ASSIGNATION_REQUESTS)
      }
    },
    {
      active: requestsFilter === OFFICE_CHANGE_REQUESTS,
      text: 'Office Change Requests',
      selectAction: () => {
        setRequestsFilter(OFFICE_CHANGE_REQUESTS)
      }
    }
  ]

  const {
    mutate: revalidatePendingOfficeAssignationRequests,
    data: pendingOfficeAssignationRequests
  } = useOfficeAssignationRequests(
    'PENDING',
    currentOrgUnit?.organization.parent_platform_tenant,
    undefined,
    undefined,
    undefined,
    undefined,
    { revalidateOnFocus: false }
  )

  const {
    mutate: revalidatePendingOfficeChangeRequests,
    data: pendingOfficeChangeRequests
  } = useOfficeMembershipChangeRequests(
    'PENDING',
    currentOrgUnit?.organization.parent_platform_tenant,
    undefined,
    undefined,
    undefined,
    undefined,
    { revalidateOnFocus: false }
  )

  const {
    mutate: revalidatePaginatedOfficeAssignationRequests,
    data: paginatedOfficeAssignationRequests,
    isValidating: isValidatingPaginatedOfficeAssignationRequests
  } = usePaginatedFilteredOfficeAssignationRequests(
    platformTenant?.id,
    filterOfficeAssignRequests,
    {
      revalidateOnFocus: false
    }
  )

  const {
    mutate: revalidatePaginatedOfficeChangeRequests,
    data: paginatedOfficeChangeRequests,
    isValidating: isValidatingPaginatedOfficeChangeRequests
  } = usePaginatedFilteredOfficeChangeRequests(
    platformTenant?.id,
    filterOfficeAssignRequests,
    {
      revalidateOnFocus: false
    }
  )

  const [allOfficeAssignationRequests, setAllOfficeAssignationRequests] =
    useState<IOfficeAssignationRequest[]>([])

  const [allOfficeChangeRequests, setAllOfficeChangeRequests] = useState<
    IOfficeMembershipChangeRequest[]
  >([])

  useEffect(() => {
    if (paginatedOfficeAssignationRequests) {
      if (filterOfficeAssignRequests.page === 1) {
        setAllOfficeAssignationRequests(
          paginatedOfficeAssignationRequests?.results
        )
      } else {
        setAllOfficeAssignationRequests(
          allOfficeAssignationRequests.concat(
            paginatedOfficeAssignationRequests?.results
          )
        )
      }
    }
  }, [paginatedOfficeAssignationRequests])

  useEffect(() => {
    if (paginatedOfficeChangeRequests) {
      if (filterOfficeAssignRequests.page === 1) {
        setAllOfficeChangeRequests(paginatedOfficeChangeRequests?.results)
      } else {
        setAllOfficeChangeRequests(
          allOfficeChangeRequests.concat(paginatedOfficeChangeRequests?.results)
        )
      }
    }
  }, [paginatedOfficeChangeRequests])

  const handleSelectOrganization = (orgId: number) => {
    const filter = {
      organizationId: orgId,
      page: 1
    }
    setFilterOfficeAssignRequests(filter)
  }

  if (pendingOfficeAssignationRequests === undefined) return null

  return (
    <LayoutAdminDashboard>
      <SecondaryGroupingHeaderLayout tabs={headerGroups} />

      <AdminBodyWrapper>
        {[PENDING_REQUESTS].includes(requestsFilter) && (
          <OfficeAssignationRequests
            pendingRequests={pendingOfficeAssignationRequests}
            status={'PENDING'}
            revalidateRequests={() => {
              revalidatePendingOfficeAssignationRequests()
            }}
          />
        )}
        {[PENDING_REQUESTS].includes(requestsFilter) && (
          <OfficeMembershipChangeRequests
            pendingRequests={pendingOfficeChangeRequests}
            status={'PENDING'}
            revalidateRequests={() => {
              revalidatePendingOfficeChangeRequests()
            }}
          />
        )}

        {![PENDING_REQUESTS].includes(requestsFilter) && (
          <Flex mb={1} flexDir="column">
            <VStack
              bg="white"
              w="100%"
              boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
              rounded="md"
              p={1}
              position="relative"
              color="eastBay09"
              align="center"
              justifyContent="space-between"
            >
              <Box w={['full']} my={2}>
                <Box p="4" bg={'white'} maxW={'md'}>
                  <FilterableOrganizationsSelect
                    name="Filter by team"
                    initialValue={selectedOrganizationId}
                    onSelect={(n) => {
                      handleSelectOrganization(n)
                    }}
                  />
                </Box>
              </Box>
            </VStack>
          </Flex>
        )}

        {[OFFICE_ASSIGNATION_REQUESTS].includes(requestsFilter) && (
          <OfficeAssignationRequests
            pendingRequests={allOfficeAssignationRequests}
            revalidateRequests={() => {
              revalidatePendingOfficeAssignationRequests()
            }}
            pagination={{
              hasMoreResults:
                paginatedOfficeAssignationRequests?.next != undefined,
              isLoading: isValidatingPaginatedOfficeAssignationRequests,
              fetchMoreResults: () => {
                setFilterOfficeAssignRequests((prev_state) => ({
                  ...prev_state,
                  page: prev_state.page + 1
                }))
              },
              count: paginatedOfficeAssignationRequests?.count ?? 0
            }}
          />
        )}
        {[OFFICE_CHANGE_REQUESTS].includes(requestsFilter) && (
          <OfficeMembershipChangeRequests
            pendingRequests={allOfficeChangeRequests}
            revalidateRequests={() => {
              revalidatePendingOfficeChangeRequests()
            }}
            pagination={{
              hasMoreResults: paginatedOfficeChangeRequests?.next != undefined,
              isLoading: isValidatingPaginatedOfficeChangeRequests,
              fetchMoreResults: () => {
                setFilterOfficeChangeRequests((prev_state) => ({
                  ...prev_state,
                  page: prev_state.page + 1
                }))
              },
              count: paginatedOfficeChangeRequests?.count ?? 0
            }}
          />
        )}
      </AdminBodyWrapper>
    </LayoutAdminDashboard>
  )
}
