import { useTheme } from '@chakra-ui/react'
import React, { useState } from 'react'
import Joyride, {
  StoreHelpers,
  ACTIONS,
  CallBackProps,
  Step
} from 'react-joyride'
import { useNavigate } from 'react-router-dom'
import { useAppTourContextProvider } from '../../context/app-tour-context'

import { routeStrings } from '../../routeStrings'
import { tourStepsArrays, tourTargetStrings } from '../../tourStepsArrays'

interface Props {}

export const AdminTourJoyride: React.FC<Props> = (props) => {
  const { run, setRun, setAdminTourActive, stepIndex } =
    useAppTourContextProvider()

  const { spaceAdminSteps: steps } = tourStepsArrays

  const navigate = useNavigate()
  const theme = useTheme()
  const [helpers, setHelpers] = useState<StoreHelpers>()

  const navigateTargetView = (step_target: string) => {
    let step = step_target.substring(1)
    let route = ''
    switch (step) {
      case tourTargetStrings.adminheaderRequests:
        route = routeStrings.adminDashboardPendingRequests
        break
      case tourTargetStrings.adminheaderMembers:
        route = routeStrings.adminDashboardMembers
        break
      case tourTargetStrings.adminheaderTeams:
        route = routeStrings.adminDashboardTeams
        break
      case tourTargetStrings.adminheaderLocations:
        route = routeStrings.adminDashboardLocations
        break
      case tourTargetStrings.adminheaderPlans:
        route = routeStrings.AdminDashboardPlans
        break
      case tourTargetStrings.adminheaderReports:
        route = routeStrings.adminDashboardReports
        break
      case tourTargetStrings.adminheaderMeetingrooms:
        route = routeStrings.adminDashboardMeetingRooms
        break

      case tourTargetStrings.adminheaderWorkspaces:
        route = routeStrings.adminDashboardWorkspaces
        break
      case tourTargetStrings.adminheaderCommunity:
        route = routeStrings.adminDashboardCommunity
        break
      case tourTargetStrings.adminheaderSettings:
        route = routeStrings.adminDashboardPlatformTenantSettings
        break
      default:
        return undefined
    }

    return route
  }
  const navigatePrevTargetView = (step_target: string) => {
    let step = step_target.substring(1)
    let route = ''
    let dashed_step = step.split('-')
    if (dashed_step.length < 3) return undefined
    let base =
      dashed_step.length === 3
        ? step
        : `${dashed_step[0]}-${dashed_step[1]}-${dashed_step[2]}`
    switch (base) {
      case tourTargetStrings.adminheaderRequests:
        route = routeStrings.adminDashboardPendingRequests
        break
      case tourTargetStrings.adminheaderMembers:
        route = routeStrings.adminDashboardMembers
        break
      case tourTargetStrings.adminheaderTeams:
        route = routeStrings.adminDashboardTeams
        break
      case tourTargetStrings.adminheaderLocations:
        route = routeStrings.adminDashboardLocations
        break
      case tourTargetStrings.adminheaderPlans:
        route = routeStrings.AdminDashboardPlans
        break
      case tourTargetStrings.adminheaderReports:
        route = routeStrings.adminDashboardReports
        break
      case tourTargetStrings.adminheaderMeetingrooms:
        route = routeStrings.adminDashboardMeetingRooms
        break

      case tourTargetStrings.adminheaderWorkspaces:
        route = routeStrings.adminDashboardWorkspaces
        break
      case tourTargetStrings.adminheaderCommunity:
        route = routeStrings.adminDashboardCommunity
        break
      case tourTargetStrings.adminheaderSettings:
        route = routeStrings.adminDashboardPlatformTenantSettings
        break
      default:
        return undefined
    }

    return route
  }

  const getNextStep = (step: Step, index: number) => {
    let currentStepIndex = steps.findIndex((val) => {
      return val.target === step.target
    })
    if (currentStepIndex >= 0 && steps.length > currentStepIndex + 1) {
      let nextStep = steps[currentStepIndex + 1]
      let route = navigateTargetView(nextStep.target.toString())
      if (route !== undefined && !window.location.pathname.includes(route)) {
        setRun(false)
      }
      route && navigate(route)
    }
  }
  const getPrevStep = (step: Step, index: number) => {
    let currentStepIndex = steps.findIndex((val) => {
      return val.target === step.target
    })
    if (currentStepIndex - 1 > 0) {
      let prevStep = steps[currentStepIndex - 1]
      let route = navigatePrevTargetView(prevStep.target.toString())
      if (route !== undefined && route !== window.location.pathname) {
        setRun(false)
        navigate(route)
      }
    }
  }

  const callbackHandler = (value: CallBackProps) => {
    const { action, index, lifecycle, type, step } = value
    if (type === 'step:after' && index === 0 /* or step.target === '#home' */) {
      getNextStep(step, index)
    } else if (type === 'step:after' && index >= 1) {
      if (action === ACTIONS.NEXT) {
        getNextStep(step, index)
      } else {
        getPrevStep(step, index)
      }
    }
    if (action === ACTIONS.RESET || action === ACTIONS.CLOSE) {
      if (helpers) {
        setAdminTourActive(false)
        setRun(false)
        helpers.go(0)
      }
    }
  }

  return (
    <Joyride
      run={run}
      styles={{
        options: {
          arrowColor: '#fff',
          backgroundColor: '#fff',
          primaryColor: theme.colors.brandPrimary,
          textColor: theme.black
        },
        tooltipTitle: { color: theme.colors.brandPrimary, fontWeight: 'bold' }
      }}
      steps={steps}
      stepIndex={stepIndex}
      callback={callbackHandler}
      continuous
      getHelpers={(_helpers) => {
        if (!helpers) {
          setHelpers(_helpers)
        }
      }}
      disableOverlayClose
    />
  )
}
