import {
  Box,
  Text,
  useToast,
  Checkbox,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Divider,
  HStack,
  Alert
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { requestOfficeMembershipChange } from '../../../api'

import {
  IOfficeDetail,
  IOfficeSubscriptionManager,
  IOrganizationBasic,
  ITabMapping
} from '../../../types'
import { CalendarFormInput } from '../../../components/CalendarFormInput'
import { useOfficeSubscriptions } from '../../../utils/apiHooks'
import {
  addDays,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isFuture,
  isSameDay,
  isWithinInterval,
  subDays
} from 'date-fns'
import { CalendarCustomIcon } from '../../../components/icons/icons'
import { StyledTab } from '../../../components/StyledTab'
import { BaseModal } from '../../../components/modals/BaseModal'
import { FeatureComponent } from '../../../components/FeatureComponent'
import { StyledInput } from '../../../components/StyledInputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'

interface IModalInput {
  isOpen: boolean
  office: IOfficeDetail | undefined
  organization: IOrganizationBasic | undefined
  manager: IOfficeSubscriptionManager | undefined
  closeModal: () => void
}

interface IDateRangeOption {
  startDate: Date | null
  endDate?: Date
}

export const OfficeRequestChangeModal: React.FC<IModalInput> = (input) => {
  const {
    isOpen,
    office,
    organization,
    manager,
    closeModal: closeCallback
  } = input
  const [tabIndex, setTabIndex] = useState<number>(0)

  const [originalDates, setOriginalDates] = useState<{
    startDate: Date
    endDate: Date | undefined
  }>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedRange, setSelectedRange] = useState(0)

  const [chosenStartDate, setChosenStartDate] = useState<Date>(new Date())
  const [chosenEndDate, setChosenEndDate] = useState<Date>()
  const [overrideValue, setOverrideValue] = useState<string>()
  const [overrideReason, setOverrideReason] = useState<string>()
  const [notifyAdmins, setNotifyAdmins] = useState(false)
  const [ranges, setRanges] = useState<IDateRangeOption[]>([
    { startDate: null, endDate: undefined }
  ])
  const [noEndDate, setNoEndDate] = useState(false)
  const [confirmIsDeletion, setConfirmIsDeletion] = useState(false)
  const [isDeletion, setIsDeletion] = useState(false)
  const [enableNoEndDate, setEnableNoEndDate] = useState(true)
  const [endDateSet, setEndDateSet] = useState(false)
  const {
    data: managers,
    isLoading: isLoadingManagers,
    mutate
  } = useOfficeSubscriptions(office?.id, {
    refreshInterval: 0,
    revalidateOnFocus: false
  })
  const toast = useToast()

  const closeModal = () => {
    setConfirmIsDeletion(false)
    setNoEndDate(false)
    setEnableNoEndDate(false)
    setTabIndex(0)
    setIsSubmitting(false)
    setOverrideReason('')
    setOverrideValue('')
    closeCallback()
    setEnableNoEndDate(true)
  }

  const validateForm = () => {
    let isValid = true
    if (!office) {
      return false
    }

    if (!chosenStartDate) {
      toast({ title: 'Please select a start date', status: 'error' })
      isValid = false
    }
    if (!noEndDate && !chosenEndDate) {
      toast({ title: 'Please select a valid end date', status: 'error' })
      isValid = false
    }
    if (chosenStartDate && chosenEndDate && chosenStartDate >= chosenEndDate) {
      toast({
        title: 'End date must be after start date',
        status: 'error'
      })
      isValid = false
    }
    if (!enableNoEndDate && noEndDate) {
      toast({
        title: 'An end date must be set',
        status: 'error'
      })
      isValid = false
    }
    return isValid
  }

  const submit = () => {
    if (!validateForm()) return
    if (manager) {
      setIsSubmitting(true)
      const canChangeStartDate =
        originalDates?.startDate && isFuture(originalDates.startDate)
      const startDateChange = originalDates?.startDate
        ? isSameDay(originalDates.startDate, chosenStartDate)
          ? undefined
          : chosenStartDate
        : undefined

      requestOfficeMembershipChange(
        manager.id,
        {
          startDate: canChangeStartDate ? startDateChange : undefined,
          endDate: noEndDate ? undefined : chosenEndDate,
          isDeletion: false,
          noEndDate: noEndDate
        },
        notifyAdmins
      )
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: `Office Request Created`
          })
          closeModal()
        })
        .catch((e) => {
          setIsSubmitting(false)
          toast({
            status: 'error',
            description: 'Failed to assign office'
          })
        })
    }
  }

  const submitCancellation = () => {
    if (!confirmIsDeletion) {
      toast({
        title: 'Please, confirm the cancellation by ticking the box.',
        status: 'error'
      })

      return
    }
    if (manager) {
      setIsSubmitting(true)
      requestOfficeMembershipChange(
        manager.id,
        {
          isDeletion: true
        },
        notifyAdmins
      )
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: `Office Request Created`
          })
          closeModal()
        })
        .catch((e) => {
          setIsSubmitting(false)
          toast({
            status: 'error',
            description: 'Failed to assign office'
          })
        })
    }
  }

  const submitRateChange = () => {
    if (overrideValue === '') {
      toast({ title: 'Please select a start date', status: 'error' })
      return
    } else {
      if (overrideValue && parseFloat(overrideValue) < 0) {
        toast({ title: 'Please enter a valid rate', status: 'error' })
        return
      }
    }
    if (overrideReason === '') {
      toast({
        title: 'Please enter a reason for the rate change',
        status: 'error'
      })
      return
    }
    if (manager) {
      setIsSubmitting(true)
      requestOfficeMembershipChange(
        manager.id,
        {
          overrideReason: overrideReason,
          overrideValue: parseFloat(overrideValue ?? '0')
        },
        notifyAdmins
      )
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: `Office Request Created`
          })
          closeModal()
        })
        .catch((e) => {
          setIsSubmitting(false)
          toast({
            status: 'error',
            description: 'Failed to assign office'
          })
        })
    }
  }

  useEffect(() => {
    if (isOpen) {
      mutate()
    }
  }, [isOpen])
  useEffect(() => {
    if (isOpen && manager) {
      const startDate = manager.start_date
        ? new Date(Date.parse(manager.start_date))
        : new Date()
      const endDate = manager.end_date
        ? new Date(Date.parse(manager.end_date))
        : undefined
      setOriginalDates({
        startDate,
        endDate
      })
      setChosenStartDate(startDate)
      setChosenEndDate(endDate)
      setNoEndDate(!endDate)
      setEndDateSet(!!endDate)
    }
  }, [manager, isOpen])

  useEffect(() => {
    if (managers && manager && isOpen) {
      const managerStartDate = manager.start_date
        ? new Date(Date.parse(manager.start_date))
        : new Date()
      const mRanges: IDateRangeOption[] = [
        { startDate: null, endDate: undefined }
      ]
      let currentDate = new Date()
      const filteredManagers = managers.filter((m) => m.id !== manager.id)
      for (let i = 0; i < filteredManagers.length; i++) {
        const otherManager = filteredManagers[i]
        const r = mRanges[mRanges.length - 1]
        const parsedSubsStartDate = otherManager.start_date
          ? new Date(Date.parse(otherManager.start_date))
          : null
        const parsedSubsEndDate = otherManager.end_date
          ? new Date(Date.parse(otherManager.end_date))
          : null
        const parsedStartDate = parsedSubsStartDate
          ? parsedSubsStartDate
          : otherManager.start_date
          ? new Date(Date.parse(otherManager.start_date))
          : null
        const parsedEndDate = parsedSubsEndDate
          ? parsedSubsEndDate
          : otherManager.end_date
          ? new Date(Date.parse(otherManager.end_date))
          : null
        if (!parsedStartDate) {
          continue
        }
        if (!r.startDate) {
          if (
            isAfter(parsedStartDate, currentDate) &&
            !isSameDay(parsedStartDate, currentDate)
          ) {
            r.startDate = currentDate
            r.endDate = subDays(parsedStartDate, 1)
            if (parsedEndDate) {
              mRanges.push({
                startDate: addDays(parsedEndDate, 1),
                endDate: undefined
              })
              currentDate = addDays(parsedStartDate, 1)
              continue
            } else {
              mRanges.push({
                startDate: addDays(parsedStartDate, 1),
                endDate: undefined
              })
              break
            }
          } else {
            if (!parsedEndDate) {
              r.startDate = currentDate
              break
            }
            r.startDate = addDays(parsedEndDate, 1)
            continue
          }
        } else {
          if (isAfter(parsedStartDate, currentDate)) {
            r.endDate = subDays(parsedStartDate, 1)
            if (parsedEndDate) {
              mRanges.push({
                startDate: addDays(parsedEndDate, 1),
                endDate: undefined
              })
              currentDate = addDays(parsedStartDate, 1)
              continue
            } else {
              break
            }
          } else {
            if (parsedEndDate) {
              r.startDate = addDays(parsedEndDate, 1)
              continue
            } else {
              r.startDate = addDays(parsedStartDate, 1)
              continue
            }
          }
        }
      }

      setRanges(mRanges)
      try {
        if (mRanges.length > 0) {
          const chosenRangeIndex = mRanges.findIndex((r) => {
            if (!r.startDate) return false
            if (r.endDate) {
              return (
                isWithinInterval(managerStartDate, {
                  start: r.startDate ?? new Date(),
                  end: r.endDate
                }) ||
                ((isSameDay(managerStartDate, r.startDate) ||
                  isAfter(managerStartDate, r.startDate)) &&
                  isBefore(managerStartDate, r.endDate))
              )
            } else {
              return (
                isSameDay(managerStartDate, r.startDate) ||
                isAfter(managerStartDate, r.startDate)
              )
            }
          })
          if (chosenRangeIndex !== -1) {
            setSelectedRange(chosenRangeIndex)
            setChosenEndDate(addDays(managerStartDate, 1))
            if (ranges[selectedRange].endDate) {
              setNoEndDate(false)
              setEnableNoEndDate(false)
              setChosenEndDate(ranges[selectedRange].endDate)
            } else {
              setEnableNoEndDate(true)
              setChosenEndDate(endOfMonth(managerStartDate))
            }
          }
        }
      } catch (error) {
        closeModal()
      }
    }
  }, [managers, manager, isOpen])

  const tabMapping: ITabMapping[] = [
    {
      tabName: 'Date Adjustment',
      buttonText: 'Send Request',
      action: () => {
        submit()
      }
    },
    {
      tabName: 'Rate Adjustment',
      buttonText: 'Send Request',
      action: () => {
        submitRateChange()
      }
    },
    {
      tabName: 'Cancel membership',
      buttonText: 'Send Request',
      action: () => {
        submitCancellation()
      },
      disabled: originalDates?.startDate && !isFuture(originalDates.startDate)
    }
  ]

  return (
    <BaseModal
      title={`Office Membership Change Request`}
      isOpen={isOpen}
      closeModalCallback={closeModal}
      primaryButtonText={tabMapping[tabIndex].buttonText}
      primaryAction={tabMapping[tabIndex].action}
      secondaryAction={closeModal}
      isLoading={isSubmitting}
      isSubmitting={isSubmitting}
      size="xl"
    >
      <Heading size={'sm'} mb={2}>
        {organization?.name} - {office?.name} @ {office?.location.name}
      </Heading>

      {manager && (
        <HStack spacing={5}>
          <FeatureComponent
            icon={<CalendarCustomIcon />}
            text="Start Date"
            subtitle={
              manager.start_date
                ? `${format(Date.parse(manager?.start_date), 'dd/MM/yyyy')}`
                : ''
            }
            iconBg={'gray.100'}
            size="lg"
          />

          <FeatureComponent
            text={`End date`}
            subtitle={
              manager.end_date
                ? `${format(Date.parse(manager.end_date), 'dd/MM/yyyy')}`
                : 'Not set'
            }
          />
        </HStack>
      )}
      <Tabs mt={6} mb={4} onChange={(index) => setTabIndex(index)}>
        <TabList>
          {tabMapping
            .filter((t) => !!!t.disabled)
            .map((tab, i) => (
              <StyledTab key={i}>{tab.tabName}</StyledTab>
            ))}
        </TabList>

        <TabPanels mt={2}>
          <TabPanel>
            <Text fontSize={'xs'} colorScheme="yellow" mb={2}>
              Setting an end date of a membership works like a cancellation.
              Past the selected end date, the office will no longer be
              associated to the team.
            </Text>
            {originalDates?.startDate && isFuture(originalDates.startDate) && (
              <Box>
                <CalendarFormInput
                  label="Start Date"
                  dateProps={{
                    chosenDate: chosenStartDate,
                    handleChangeDate: (value) => {
                      setChosenStartDate(value)
                      if (!noEndDate) {
                        setChosenEndDate(endOfMonth(value))
                      }
                    }
                  }}
                  minDate={ranges[selectedRange].startDate ?? new Date()}
                  maxDate={ranges[selectedRange].endDate}
                  closeOnSelect={true}
                  placement="bottom"
                />
              </Box>
            )}
            {!ranges[selectedRange].endDate && (
              <Box>
                <Checkbox
                  isChecked={noEndDate}
                  isDisabled={!enableNoEndDate}
                  onChange={(e) => {
                    setNoEndDate(e.target.checked)
                    setChosenEndDate(undefined)
                  }}
                >
                  Auto renew enabled
                </Checkbox>
                <Text fontSize={'xs'}>
                  This will set the membership to automatically renew every
                  month.{' '}
                </Text>
                <Divider my={3} />
              </Box>
            )}

            {!noEndDate && (
              <CalendarFormInput
                label={
                  'End Date ' +
                  (ranges[selectedRange].endDate ? ' (Required)' : '')
                }
                dateProps={{
                  chosenDate: chosenEndDate,
                  handleChangeDate: setChosenEndDate
                }}
                minDate={
                  ranges[selectedRange].startDate
                    ? addDays(ranges[selectedRange].startDate, 1)
                    : new Date()
                }
                maxDate={ranges[selectedRange].endDate}
                closeOnSelect={true}
                placement="bottom"
              />
            )}
            <Divider my={3} />
          </TabPanel>
          <TabPanel>
            <FeatureComponent
              icon={<FontAwesomeIcon icon={faDollarSign} size={'xs'} />}
              iconBg={'gray.100'}
              text="Office monthly rate"
              subtitle={office?.rate ? `$${office.rate}` : '$0.00'}
              size="lg"
            />
            {manager &&
              manager.current_subscription?.override_value !== undefined && (
                <FeatureComponent
                  icon={<FontAwesomeIcon icon={faDollarSign} size={'xs'} />}
                  iconBg={'yellow.300'}
                  text="This membership's approved rate"
                  subtitle={
                    manager.current_subscription?.override_value
                      ? `$${manager.current_subscription?.override_value}`
                      : '$0.00'
                  }
                  size="lg"
                />
              )}
            <Divider my={3} />
            <Text fontSize={'xs'} colorScheme="yellow" mb={2}>
              Please note, rate changes take effect once the have been approved,
              and will be applied to the following month's invoice. e.g: If you
              change and approve this rate in March, the April invoice will
              reflect the new rate.
            </Text>

            <Text mt={3}>New rate ($AUD per month)</Text>
            <StyledInput
              type="number"
              value={overrideValue}
              onChange={(e) => setOverrideValue(e.target.value)}
              id="rate"
              placeholder="0.00"
              w="100%"
            />
            {!!overrideValue && (
              <>
                <Text>Enter a reason for the rate</Text>
                <StyledInput
                  value={overrideReason}
                  onChange={(e) => setOverrideReason(e.target.value)}
                  id="reason"
                  w="100%"
                />
              </>
            )}
          </TabPanel>

          <TabPanel>
            <Alert status="warning">
              <Text fontSize={'sm'} colorScheme="yellow" mb={2}>
                You can cancel a membership before it starts.
                <br />
                <strong>
                  Please note that this action will only proceed once it has
                  been approved.
                </strong>
              </Text>
            </Alert>
            <Checkbox
              my={3}
              isChecked={confirmIsDeletion}
              onChange={(e) => {
                setConfirmIsDeletion(e.target.checked)
              }}
            >
              I confirm that I want to cancel this membership
            </Checkbox>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </BaseModal>
  )
}
