import { ISimpleBooking } from './../../../types'
import { areIntervalsOverlapping, fromUnixTime } from 'date-fns'
import { IMeetingRoomSuperAdmin, IBooking } from '../../../types'

interface IsMeetingRoomAvailableArguments {
  meetingRoom: IMeetingRoomSuperAdmin
  bookings: ISimpleBooking[]
  chosenStartDateTime: Date
  chosenEndDateTime: Date
}

export function isMeetingRoomAvailable({
  bookings,
  chosenEndDateTime,
  chosenStartDateTime,
  meetingRoom
}: IsMeetingRoomAvailableArguments) {
  let available = true

  if (chosenEndDateTime < chosenStartDateTime) {
    return available
  }

  // filter the bookings array by bookings which match the meeting room ID
  const bookingsForMeetingRoom = bookings.filter(
    (booking) => meetingRoom.id === booking.meeting_room
  )

  // use that result from above rather than meetingRoom.bookings (which doesn't exist)
  bookingsForMeetingRoom.forEach((booking) => {
    if (
      areIntervalsOverlapping(
        {
          start: fromUnixTime(Date.parse(booking.start_time) / 1000),
          end: fromUnixTime(Date.parse(booking.end_time) / 1000)
        },
        { start: chosenStartDateTime, end: chosenEndDateTime }
      )
    ) {
      available = false
    }
  })

  return available
}

export const castHoursToDate = (hours: string | undefined, date?: Date) => {
  if (!hours) return null
  date = date ? new Date(date.getTime()) : new Date()
  try {
    let hoursArray = hours.split(':')
    date.setHours(parseInt(hoursArray[0]))
    date.setMinutes(parseInt(hoursArray[1]))
    date.setSeconds(0)
    date.setMilliseconds(0)
  } catch (error) {
    console.error(error)
  }
  return date
}

export const secondsToTimeString = (seconds: number) => {
  var hours = Math.floor(seconds / 3600)
  var minutes = Math.floor((seconds - hours * 3600) / 60)

  return `${hours > 0 ? hours : ''} ${
    hours === 1 ? 'hour' : hours > 0 ? 'hours' : ''
  }${minutes > 0 ? ` ${minutes}` : ''}${
    minutes > 0 ? (minutes == 1 ? ' minute' : ' minutes') : ''
  }`
}

export const validateRateError = (value: number | undefined) => {
  let error = ''
  if (!value) {
    error = 'This rate is required'
  } else if (isNaN(value)) {
    error = 'This rate must be numeric'
  } else if (value < 0) {
    error = 'This rate cannot be negative'
  }
  return error
}
export const validateNumberError = (value: number | undefined) => {
  let error = ''
  if (!value) {
    error = 'This value is required'
  } else if (isNaN(value)) {
    error = 'This value must be numeric'
  } else if (value < 0) {
    error = 'This value cannot be negative'
  }
  return error
}
