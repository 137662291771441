import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Tab,
  TabList,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  ButtonGroup,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useBoolean,
  useDisclosure,
  useTheme,
  useToast,
  VStack,
  Badge,
  Switch
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  deleteXeroConnectionForTenant,
  importAccountsFromXero,
  updateXeroIntegrationSettings
} from '../../api'
import { AlertBox } from '../../components/AlertBox'
import { Button } from '../../components/Button'
import { StyledSelect } from '../../components/StyledSelect'
import { Table, TableDataProp } from '../../components/table/Table'
import { useAuth } from '../../context/auth-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import {
  IAccountCode,
  IXeroAccountCode,
  IXeroIntegrationSettingsUpdate
} from '../../types'
import {
  useAccountCodes,
  useAccountCodesFromXero,
  useXeroIntegrationSettings
} from '../../utils/apiHooks'
import { DeleteAccountCodeModal } from './Modals/DeleteAccountCodeModal'

const { REACT_APP_XERO_CLIENT_ID, REACT_APP_XERO_REDIRECT_URI } = process.env

export const XeroConnectionSettings: React.FC = () => {
  const { platformTenant } = usePlatformTenant()
  const { data: xeroSettings } = useXeroIntegrationSettings(platformTenant?.id)
  const { currentOrgUnit } = useAuth()
  const theme: any = useTheme()

  const StyledTab = styled(Tab)`
    /* tab / default */
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.brandPrimary};
    padding-bottom: 16px;
    border: unset;

    /* tab / active */
    &[aria-selected='true'] {
      border: unset;
      border-bottom: 2px solid ${theme.colors.brandPrimary};
      color: ${theme.colors.brandPrimary};
    }
  `
  return (
    <Box w="100%">
      <Flex>
        <Box p="4">
          <Text fontSize="16px" fontWeight={600}>
            Xero Accounting Integration
          </Text>
        </Box>
        {xeroSettings?.connected_xero ? (
          <Box p="2" ml="30px">
            <Badge colorScheme="green">OK</Badge>
          </Box>
        ) : null}
        {currentOrgUnit && !xeroSettings?.connected_xero && (
          <Box p="2" ml="30px">
            <Tooltip
              label="Authorise the Spacecubed Platform to access your Xero account to enable Invoice and Payment syncing"
              aria-label="A tooltip"
              placement="right"
            >
              <Button
                flexShrink={1}
                onClick={() => {
                  window.location.replace(
                    `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${REACT_APP_XERO_CLIENT_ID}&redirect_uri=${REACT_APP_XERO_REDIRECT_URI}&scope=openid offline_access accounting.contacts accounting.transactions accounting.settings&state=state=${currentOrgUnit.id}`
                  )
                }}
              >
                Enable
              </Button>
            </Tooltip>
          </Box>
        )}
      </Flex>
      <VStack align="left" w="100%">
        <HStack justify="space-between"></HStack>
        <Tabs w="100%">
          <TabList>
            {xeroSettings?.connected_xero && (
              <StyledTab>Integration Features</StyledTab>
            )}

            {xeroSettings?.connected_xero && (
              <StyledTab>Xero Chart of Accounts</StyledTab>
            )}
          </TabList>

          <TabPanels>
            {xeroSettings?.connected_xero && (
              <TabPanel>
                <XeroSyncSetting />
              </TabPanel>
            )}
            {xeroSettings?.connected_xero && (
              <TabPanel>
                <XeroAccountCodes />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </VStack>
    </Box>
  )
}

const XeroAccountCodes: React.FC = () => {
  const { platformTenant } = usePlatformTenant()
  const { data: accountCodesFromXero } = useAccountCodesFromXero(
    'ALL',
    platformTenant?.id
  )
  const { data, mutate, isValidating } = useAccountCodes(platformTenant?.id)
  const [tableData, setTableData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedAccount, setSelectedAccount] = useState<IAccountCode>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isLoadingQuery, loadingQuery] = useBoolean()

  const handleClose = () => {
    onClose()
    setSelectedAccount(undefined)
    mutate()
  }
  useEffect(() => {
    const isSynced = (rowData: any) => {
      return (
        data?.find((acc) => acc.xero_account_id === rowData.xero_account_id) !==
        undefined
      )
    }
    const accountCodeActions = (
      rowData: IXeroAccountCode | IAccountCode,
      state?: 'error'
    ) => {
      const act: any[] = []

      if (isSynced(rowData) || state === 'error') {
        // Only show this action if the xero account is synced
        act.push({
          text: 'Delete Account From Platform and Bulk Reassign Resources',
          testIdPrefix: 1,
          action: (rowId: number) => {
            if (platformTenant && accountCodesFromXero && data) {
              const code = data?.find((acc) => {
                let xero_account_id = rowData['xero_account_id']
                let account_code_id = rowData['id']
                return (
                  (xero_account_id &&
                    acc.xero_account_id === xero_account_id) ||
                  (account_code_id && acc.id === account_code_id)
                )
              })
              if (!code) {
                console.log('Code not found')
                return
              }
              setSelectedAccount(code)
              onOpen()
            }
          }
        })
      } else {
        act.push({
          text: 'Import Account',
          testIdPrefix: 2,
          action: async (rowId: number) => {
            if (platformTenant && accountCodesFromXero) {
              await importAccountsFromXero(platformTenant.id, [
                accountCodesFromXero[rowId]
              ])
              mutate()
            }
          }
        })
      }

      return act
    }
    if (accountCodesFromXero) {
      const td = accountCodesFromXero.map((acc, i) => {
        return {
          id: TableDataProp(i),
          title: TableDataProp(acc.title, acc.title),
          code: TableDataProp(acc.code, acc.code),
          status: TableDataProp(
            isSynced(acc) ? 'Imported' : 'Not Imported',
            isSynced(acc) ? 'Imported' : 'Not'
          ),

          actions: TableDataProp(accountCodeActions(acc))
        }
      })

      const pacc =
        data
          ?.filter(
            (platAcc) =>
              // Dont show payment accounts in this list
              !platAcc.is_payment_account &&
              !accountCodesFromXero.find(
                (ac) => ac.xero_account_id === platAcc.xero_account_id
              )
          )
          .map((acc, i) => {
            return {
              id: TableDataProp('error-acc' + i),
              title: TableDataProp(acc.title, acc.title),
              code: TableDataProp(acc.code, acc.code),
              status: TableDataProp('Not Found in Xero', 'Not Found in Xero'),

              actions: TableDataProp(accountCodeActions(acc, 'error'))
            }
          }) || []
      setTableData(td.concat(pacc))
      setLoading(false)
    } else {
      setTableData([])
      setLoading(true)
    }
  }, [accountCodesFromXero, platformTenant, data])

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Flex mb={3} alignItems="baseline" flexGrow={1}>
          <Text
            mb={0}
            mr={3}
            color="headingPrimary"
            fontSize="lg"
            textTransform="capitalize"
          >
            Chart of Accounts in Xero (Revenue)
          </Text>
          <Text fontSize="xs" color="grey">
            {tableData.length} Total
          </Text>
          <Flex flexGrow={1} justifyContent="flex-end">
            <Button
              isLoading={isValidating || isLoadingQuery}
              onClick={async () => {
                if (platformTenant && accountCodesFromXero) {
                  loadingQuery.on()
                  await importAccountsFromXero(
                    platformTenant.id,
                    accountCodesFromXero
                  )
                  loadingQuery.off()
                  mutate()
                }
              }}
            >
              Import All
            </Button>
          </Flex>
        </Flex>
        <Text>
          You may choose to import all of your accounts from Xero or just the
          ones that you want to use in the platform. When you import an account
          it will become available to assign to resources in your tenancy such
          as meeting rooms, offices and plans.
        </Text>
        <br />

        <Table
          headers={[
            { header: 'id', accessor: 'id', show: false },

            {
              header: 'Title',
              accessor: 'title',
              show: true,
              width: 3.5 / 12,
              enableFilter: true
            },
            {
              header: 'Code',
              accessor: 'code',
              show: true,
              width: 2 / 12,
              enableFilter: true
            },
            {
              header: 'Status',
              accessor: 'status',
              show: true,
              width: 2 / 12,
              enableFilter: true
            },

            { header: '', accessor: 'actions', show: true, width: 0.5 / 12 }
          ]}
          data={tableData}
          loading={loading}
        />
      </Box>
      <DeleteAccountCodeModal
        isOpen={isOpen}
        onClose={handleClose}
        accountCode={selectedAccount}
      />
    </div>
  )
}

const XeroSyncSetting: React.FC = () => {
  const { platformTenant } = usePlatformTenant()
  const { data: xeroSettings, mutate: mutateXeroSettings } =
    useXeroIntegrationSettings(platformTenant?.id)
  const toastPopup = useToast()
  const { data: accountCodesFromXero } = useAccountCodesFromXero(
    'BANK',
    platformTenant?.id
  )
  if (!xeroSettings || !accountCodesFromXero) {
    return null
  }

  const handleDisconnectXero = () => {
    platformTenant &&
      deleteXeroConnectionForTenant(platformTenant.id)
        .then((res) => {
          // @todo mutate
          mutateXeroSettings()
        })
        .catch((err) => {})
  }
  return (
    <VStack>
      <Formik
        initialValues={{
          invoice_sync_enabled: xeroSettings.invoice_sync_enabled ?? false,
          payment_sync_enabled: xeroSettings.payment_sync_enabled ?? false,
          xero_payment_account_code:
            xeroSettings.xero_payment_account_code?.xero_account_id || undefined
        }}
        validate={(values) => {
          const errors: any = {}
          if (values.payment_sync_enabled && !values.invoice_sync_enabled) {
            errors.payment_sync_enabled =
              'Payment sync requires invoice sync to be enabled'
          }
          if (
            values.payment_sync_enabled &&
            values.xero_payment_account_code === ''
          ) {
            errors.payment_sync_enabled =
              'Payment sync requires a payment account to be configured'
          }
          return errors
        }}
        onSubmit={async (
          values: IXeroIntegrationSettingsUpdate,
          { setSubmitting }
        ) => {
          setSubmitting(true)
          try {
            platformTenant &&
              (await updateXeroIntegrationSettings(platformTenant.id, values))
          } catch {
            toastPopup({
              position: 'top',
              duration: 5000,
              render: (onClose) => (
                <AlertBox onClose={onClose}>Something went wrong</AlertBox>
              ),
              isClosable: true
            })
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ values, setFieldValue, isSubmitting, touched, errors }) => (
          <Form>
            <VStack align="left">
              <Field
                name="invoice_sync_enabled"
                render={({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.invoice_sync_enabled &&
                      form.touched.invoice_sync_enabled
                    }
                  >
                    <FormLabel color="#4F4F4F" mb={1}>
                      Enable Invoice Sync
                      <Switch
                        ml={2}
                        size="sm"
                        colorScheme="red"
                        id="invoice_sync_enabled"
                        isChecked={form.values.invoice_sync_enabled}
                        {...field}
                      />
                    </FormLabel>

                    <FormErrorMessage>
                      {form.errors.invoice_sync_enabled}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Field
                name="xero_payment_account_code"
                render={({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.xero_payment_account_code &&
                      form.touched.xero_payment_account_code
                    }
                  >
                    <FormLabel color="#4F4F4F" mb={1}>
                      Payment Account
                    </FormLabel>
                    <StyledSelect
                      isRequired={true}
                      placeholder="Select a payment account"
                      value={form.values.xero_payment_account_code}
                      name="xero_payment_account_code"
                      onChange={(e) => {
                        setFieldValue(
                          'xero_payment_account_code',
                          e.target.value
                        )
                      }}
                    >
                      {accountCodesFromXero?.map((acc) => (
                        <option
                          key={acc.xero_account_id}
                          value={acc.xero_account_id}
                        >
                          {`${acc.title} (${acc.code})`}
                        </option>
                      ))}
                    </StyledSelect>
                    <FormErrorMessage>
                      {form.errors.xero_payment_account_code}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Field
                name="payment_sync_enabled"
                render={({ field, form }) => (
                  <FormControl
                    isDisabled={form.values.xero_payment_account_code === ''}
                    isInvalid={
                      form.errors.payment_sync_enabled &&
                      form.touched.payment_sync_enabled
                    }
                  >
                    <FormLabel color="#4F4F4F" mb={1}>
                      Enable Payment Sync
                      <Switch
                        isDisabled={
                          form.values.xero_payment_account_code === ''
                        }
                        ml={2}
                        size="sm"
                        colorScheme="red"
                        id="payment_sync_enabled"
                        isChecked={form.values.payment_sync_enabled}
                        {...field}
                      />
                    </FormLabel>

                    <FormErrorMessage>
                      {form.errors.payment_sync_enabled}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Box>
                <Button type="submit" disabled={isSubmitting} mr={2}>
                  Update settings
                </Button>
                <Popover returnFocusOnClose={false} placement="right">
                  <PopoverTrigger>
                    <Button
                      disabled={isSubmitting}
                      bg="#EB4969"
                      border="unset"
                      leftIcon={<DeleteIcon />}
                    >
                      Disconnect Xero
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverHeader fontWeight="semibold">
                      Confirmation
                    </PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      Are you sure you want to disconnect Xero?
                    </PopoverBody>
                    <PopoverFooter display="flex" justifyContent="flex-end">
                      <ButtonGroup size="sm">
                        <Button>Cancel</Button>
                        <Button
                          bg="#EB4969"
                          border="none"
                          color="white"
                          onClick={handleDisconnectXero}
                        >
                          Delete Xero
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              </Box>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  )
}
