import { IRouteObj } from "./interfaces";
import { AdminDashboardCommunity } from "./pages/AdminDashboard/AdminDashboardCommunity";
import { AdminDashboardInvoiceView } from "./pages/AdminDashboard/AdminDashboardInvoiceView";
import { AdminDashboardLocations } from "./pages/AdminDashboard/AdminDashboardLocations";
import { AdminDashboardMeetingRooms } from "./pages/AdminDashboard/AdminDashboardMeetingRooms";
import { AdminDashboardMeetingRoomsAdmin } from "./pages/AdminDashboard/AdminDashboardMeetingRoomsAdmin";
import { AdminDashboardMeetingRoomsBookings } from "./pages/AdminDashboard/AdminDashboardMeetingRoomsBookings";
import { AdminDashboardMembers } from "./pages/AdminDashboard/AdminDashboardMembers";
import { AdminDashboardPlans } from "./pages/AdminDashboard/AdminDashboardPlans";
import { AdminDashboardPlatformTenantSettings } from "./pages/AdminDashboard/AdminDashboardPlatformTenantSettings";
import { AdminDashboardReports } from "./pages/AdminDashboard/AdminDashboardReports";
import { AdminDashboardRequests } from "./pages/AdminDashboard/AdminDashboardRequests";
import { AdminDashboardTeams } from "./pages/AdminDashboard/AdminDashboardTeams";
import { AdminDashboardWorkspaces } from "./pages/AdminDashboard/AdminDashboardWorkspaces";
import { AdminDashboardWorkspacesAdmin } from "./pages/AdminDashboard/AdminDashboardWorkspacesAdmin";
import { AdminDashboardWorkspacesBookings } from "./pages/AdminDashboard/AdminDashboardWorkspacesBookings";
import { AdminTeamSettings } from "./pages/AdminDashboard/AdminTeamSettings";
import { AdminDashboardInvoicesReports } from "./pages/AdminDashboard/Reports/Invoices";
import { XeroCallbackCodeRegistration } from "./pages/AdminDashboard/XeroCallbackCodeRegistration";

import { CompleteBookOnboarding } from "./pages/CompleteProfile/CompleteBookOnboarding";
import { CompleteLanding } from "./pages/CompleteProfile/CompleteLanding";
import { CompleteProfile1 } from "./pages/CompleteProfile/CompleteProfile1";
import { CompleteProfile3 } from "./pages/CompleteProfile/CompleteProfile3";
import { ContentDetail } from "./pages/DailyFive/ContentDetail";
import { ContentEdition } from "./pages/DailyFive/ContentEdition";
import { MemberDashboardCreatePost } from "./pages/DailyFive/MemberDashboardCreatePost";
import { ZoomCodeRegistration } from "./pages/DailyFive/ZoomCodeRegistration";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { EventsCalendarView } from "./pages/MemberDashboard/EventsCalendar/EventsCalendarView";
import { MemberHomepageV2 } from "./pages/MemberDashboard/Homepage/Homepage";
import { Bookings } from "./pages/MemberDashboard/MeetingRooms/Bookings";
import { MeetingRoomWrapper } from "./pages/MemberDashboard/MeetingRooms/MeetingRoomWrapper";
import { ViewBooking } from "./pages/MemberDashboard/MeetingRooms/ViewBooking";
import { MemberDashboardBillingInvoice } from "./pages/MemberDashboard/MemberDashboardBillingInvoice";
import { MemberDashboardBillingInvoiceView } from "./pages/MemberDashboard/MemberDashboardBillingInvoiceView";
import { MemberDashboardBillingUpdate } from "./pages/MemberDashboard/MemberDashboardBillingUpdate";
import { MemberDashboardBillingUpdateSuccess } from "./pages/MemberDashboard/MemberDashboardBillingUpdateSuccess";
import { MemberDashboardProfile } from "./pages/MemberDashboard/MemberDashboardProfile";
import { OnboardingAcceptInvite } from './pages/MemberDashboard/OnboardingAcceptInvite';
import { TeamDashboardHome } from "./pages/MemberDashboard/TeamDashboardHome";
import { WorkspaceBookings } from "./pages/MemberDashboard/Workspaces/WorkspaceBookings";
import { WorkspaceWrapper } from "./pages/MemberDashboard/Workspaces/WorkspaceWrapper";
import { OnboardingAcceptTC } from "./pages/Onboarding/OnboardingAcceptTC";
import { OnboardingChooseLocation } from "./pages/Onboarding/OnboardingChooseLocation";
import { OnboardingChoosePlan } from "./pages/Onboarding/OnboardingChoosePlan";
import { OnboardingChoosePlanAllendaleFern } from "./pages/Onboarding/OnboardingChoosePlanAllendaleFern";
import { OnboardingChoosePlanIluma } from "./pages/Onboarding/OnboardingChoosePlanIluma";
import { ExternalOnboardingAcceptTC } from "./pages/ExternalOnboarding/ExternalOnboardingAcceptTC";
import { ExternalOnboardingThankyou } from "./pages/ExternalOnboarding/ExternalOnboardingThankyou";
import { OnboardingThankyou } from "./pages/Onboarding/OnboardingThankyou";
import { TeamAdminDashboardBilling } from "./pages/TeamAdminDashboard/TeamAdminDashboardBilling";
import { TeamAdminMemberProfileView } from "./pages/TeamAdminDashboard/TeamAdminMemberProfileView";
import { Onboarding0Invite } from "./pages/TeamsOnboarding/Onboarding0Invite";
import { Onboarding1Email } from "./pages/TeamsOnboarding/Onboarding1Email";
import { Onboarding2Name } from "./pages/TeamsOnboarding/Onboarding2Name";
import { Onboarding3Details } from "./pages/TeamsOnboarding/Onboarding3Details";
import { Onboarding4Title } from "./pages/TeamsOnboarding/Onboarding4Title";
import { Onboarding5SkillsAndBio } from "./pages/TeamsOnboarding/Onboarding5SkillsAndBio";
import { Onboarding6Password } from "./pages/TeamsOnboarding/Onboarding6Password";
import { Onboarding7Completed } from "./pages/TeamsOnboarding/Onboarding7Completed";
import { Onboarding1BusinessSettings } from "./pages/TenantOnboarding/Onboarding1BusinessSettings";
import { OnboardingTenant0Invite } from "./pages/TenantOnboarding/OnboardingTenant0Invite";
import { OnboardingTenant6Password } from "./pages/TenantOnboarding/OnboardingTenant6Password";
import { TenantOnboardingCompleted } from "./pages/TenantOnboarding/TenantOnboardingCompleted";
import { TenantOnboardingIntegrations } from "./pages/TenantOnboarding/TenantOnboardingIntegrations";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { FERN_ALLENDALE_PLAN_UUID, ILUMA_PLAN_UUID } from "./utils/constants";
import { MembersDirectoryWrapper } from "./pages/MemberDashboard/MembersDirectory/MembersDirectoryWrapper";
import { PublicMeetingRoom } from "./pages/MemberDashboard/MeetingRooms/ExternalProfile/PublicMeetingRoom";
import { OnboardingShortForm } from "./pages/Onboarding/OnboardingShortForm";
import { OnboardingStepsExternal } from "./pages/Onboarding/OnboardingStepsExternal";
import { AdminDashboardRequestsAdmin } from "./pages/AdminDashboard/AdminDashboardRequestsAdmin";
import { WrapperPublicLocationWorkspacesHotDesk, WrapperPublicLocationWorkspacesPrivateOffice } from "./pages/MemberDashboard/MeetingRooms/ExternalProfile/WrapperPublicLocationWorkspaces";


export const baseRoutes: IRouteObj<any> = {
    login: { path: '/login', component: Login },
    logout: { path: '/logout', component: Logout },
}


export const startRoutes: IRouteObj<any> = {
    location: { path: '/location', component: OnboardingChooseLocation },
    startAllendaleFernPlan: { path: `/plan/${FERN_ALLENDALE_PLAN_UUID}`, component: OnboardingChoosePlanAllendaleFern },
    startIlumaPlan: { path: `/plan/${ILUMA_PLAN_UUID}`, component: OnboardingChoosePlanIluma },
    plan: { path: '/plan', component: OnboardingChoosePlan },
    
    singlePageSignup: { path: '/single-page', component: OnboardingShortForm },
    termsAndConditions: { path: '/view-terms-and-conditions', component: TermsAndConditions },
    acceptTermsAndConditions: { path: '/terms-and-conditions', component: OnboardingAcceptTC },
    thankyou: { path: '/thank-you', component: OnboardingThankyou },
}


export const externalStartRoutes: IRouteObj<any> = {
   
    singlePageSignup: { path: '/start/:tenant', component: OnboardingStepsExternal },
    acceptTermsAndConditions: { path: '/start/terms-and-conditions', component: ExternalOnboardingAcceptTC },
    thankyou: { path: '/thank-you', component: ExternalOnboardingThankyou },
}

export const publicBookingRoutes: IRouteObj<any> = {
    test: { path: '/test', component: PublicMeetingRoom },
    externalMeetingRoom: { path: ':room_slug', component: PublicMeetingRoom },
}

export const publicWorkspacesBookingRoutes: IRouteObj<any> = {
    externalWorkspaces: { path: ':tenant/:location_name/:location_id', component: WrapperPublicLocationWorkspacesHotDesk, },
    externalOfficeWorkspaces: { path: 'private-office/:tenant/:location_name/:location_id', component: WrapperPublicLocationWorkspacesPrivateOffice },
}

export const inviteRoutes: IRouteObj<any> = {
    onboarding0Invite: { path: '/new/:token', component: Onboarding0Invite },
    onboarding1Email: { path: '/email', component: Onboarding1Email },
    onboarding2Name: { path: '/name', component: Onboarding2Name },
    onboarding3Details: { path: '/details', component: Onboarding3Details },
    onboarding4Title: { path: '/job-titile', component: Onboarding4Title },
    onboarding5SkillsAndBio: { path: '/skills-and-bio', component: Onboarding5SkillsAndBio },
    onboarding6Password: { path: '/password', component: Onboarding6Password },
    onboarding7Completed: { path: '/complete', component: Onboarding7Completed },

}

export const tenantInviteRoutes: IRouteObj<any> = {
    onboardingTenant0Invite: { path: '/tenant/new/:token', component: OnboardingTenant0Invite },
    OnboardingTenant6Password: { path: '/tenant/password', component: OnboardingTenant6Password },
}

export const teamAdminRoutes: IRouteObj<any> = {
    teamAdminDashboardBilling: { path: '/billing', component: TeamAdminDashboardBilling },
    memberDashboardBillingUpdate: { path: '/billing/update', component: MemberDashboardBillingUpdate },
    memberDashboardBillingUpdateSuccess: { path: '/billing/update/success', component: MemberDashboardBillingUpdateSuccess },
    memberDashboardBillingInvoice: { path: '/billing/invoice/:invoiceId', component: MemberDashboardBillingInvoice },
    memberDashboardBillingInvoiceView: { path: '/billing/invoice/:invoiceId/view', component: MemberDashboardBillingInvoiceView },
}

export const adminRoutes: IRouteObj<any> = {
    adminMemberProfileView: { path: '/dashboard/teams/:orgId/member/:ouId', component: TeamAdminMemberProfileView },
    adminDashboard: { path: '/dashboard', component: AdminDashboardMembers },
    adminDashboardPendingRequests: { path: '/dashboard/pending-requests', component: AdminDashboardRequestsAdmin },
    adminDashboardMeetingRooms: { path: '/dashboard/meeting-rooms', component: AdminDashboardMeetingRooms },
    adminDashboardMeetingRoomsBookings: { path: '/dashboard/meeting-rooms-admin-bookings', component: AdminDashboardMeetingRoomsBookings },
    adminDashboardMeetingRoomsAdmin: { path: '/dashboard/meeting-rooms-admin', component: AdminDashboardMeetingRoomsAdmin },
    adminDashboardWorkspaces: { path: '/dashboard/workspaces', component: AdminDashboardWorkspaces},
    adminDashboardWorkspacesBookings: { path: '/dashboard/workspaces-admin-bookings', component: AdminDashboardWorkspacesBookings },
    adminDashboardWorkspacesAdmin: { path: '/dashboard/workspaces-admin', component: AdminDashboardWorkspacesAdmin },
    adminDashboardMembers: { path: '/dashboard/members', component: AdminDashboardMembers },
    adminDashboardTeams: { path: '/dashboard/teams', component: AdminDashboardTeams },
    adminDashboardTeamDetail: { path: '/dashboard/teams/:orgId', component: TeamDashboardHome },
    adminDashboardTeamSettings: { path: '/dashboard/teams/:orgId/settings', component: AdminTeamSettings },
    adminRegisterXero: { path: '/dashboard/xero-callback', component: XeroCallbackCodeRegistration },
    AdminDashboardInvoicesReports: { path: '/dashboard/unpaid-invoices', component: AdminDashboardInvoicesReports },
    adminDashboardInvoiceView: { path: '/dashboard/invoice/:invoiceId/view', component: AdminDashboardInvoiceView },
    adminDashboardLocations: { path: '/dashboard/locations', component: AdminDashboardLocations },
    adminDashboardReports: { path: '/dashboard/reports', component: AdminDashboardReports },
    adminDashboardBilling: { path: '/management/billing/:orgId', component: TeamAdminDashboardBilling },
    adminDashboardCommunity: { path: '/dashboard/community', component: AdminDashboardCommunity },
    adminDashboardPlans: { path: '/dashboard/plans', component: AdminDashboardPlans },
    adminDashboardPlatformTenantSettings: { path: "/dashboard/coworking-business", component: AdminDashboardPlatformTenantSettings },
    
    onboarding1BusinessSettings: { path: '/onboarding/business', component: Onboarding1BusinessSettings },
    onboardingIntegrations: { path: '/onboarding/integrations', component: TenantOnboardingIntegrations },
    onboardingComplete: { path: '/onboarding/complete', component: TenantOnboardingCompleted },
}

export const completeProfileRoutes: IRouteObj<any> = {
    completeLanding: { path: '/', component: CompleteLanding },
    completeProfile1: { path: '/profile/1', component: CompleteProfile1 },
    completeProfile3: { path: '/profile/3', component: CompleteProfile3 },
    completeBookOnboarding: { path: '/book-onboarding', component: CompleteBookOnboarding },
}

export const memberRoutes: IRouteObj<any> = {
    memberDashboardHomeV2: { path: '/home', component: MemberHomepageV2 },
    memberDashboardProfile: { path: '/profile', component: MemberDashboardProfile },
    memberDashboardMeetingRooms: { path: '/meeting-rooms/', component: MeetingRoomWrapper },
    memberDashboardBookings: { path: '/bookings/', component: Bookings },
    memberDashboardWorkspaceBookings: { path: '/workspace-bookings/', component: WorkspaceBookings },
    memberDashboardWorkspaces: { path: '/workspaces/', component: WorkspaceWrapper },
    memberDashboardBooking: { path: '/booking/:bookingId', component: ViewBooking },
    teamDashboardHome: { path: '/team', component: TeamDashboardHome },
    teamAdminMemberProfileView: { path: '/team/member/:ouId', component: TeamAdminMemberProfileView },
    memberConnectZoom: { path: '/register-zoom/', component: ZoomCodeRegistration },
    memberDashboardCreatePost: { path: '/create-post', component: MemberDashboardCreatePost },
    memberDashboardEditPost: { path: '/content/edit/:contentId', component: ContentEdition },
    memberDashboardViewPost: { path: '/content/:contentId', component: ContentDetail },
    memberAcceptTeamInvite:{path: '/accept-invite/:token',component:OnboardingAcceptInvite},
    memberDashboardCalendar:{path: '/calendar/', component:EventsCalendarView},
    memberDashboardMembersDirectory:{path: '/directory/', component:MembersDirectoryWrapper},
}

export const mobileEmbedRoutes: IRouteObj<any> = {
    memberDashboardMeetingRooms: { path: '/dashboard/meeting-rooms/', component: MeetingRoomWrapper },
    memberDashboardBookings: { path: '/dashboard/bookings/', component: Bookings },
    memberDashboardBooking: { path: '/dashboard/booking/:bookingId', component: ViewBooking },
}