import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  SimpleGrid,
  Spacer,
  Spinner,
  Text
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { CalendarFormInput } from '../../../components/CalendarFormInput'
import { WorkspaceTimeLineCard } from '../../../components/WorkspaceTimelineCard'
import { useBookingContext } from '../../../context/BookingContext'
import { useCustomToast } from '../../../context/toast-context'
import { routeStrings } from '../../../routeStrings'
import { ISimpleBooking, IWorkspace } from '../../../types'
import { FilterableBookableLocationsSelect } from '../../../components/FilterableBookableLocationsSelect'
import { useRole } from '../../../utils/rbac'

export const MemberWorkspaces: React.FC = () => {
  const {
    loading,
    workspaces,
    chosenDate,
    locations,
    chosenLocation,
    revalOrgUnitBookings,
    handleChangeDate,
    setChosenLocation
  } = useBookingContext()
  const { locations: bookableLocations } = useBookingContext()
  const { isExternalMember } = useRole()

  const { newToast: toast } = useCustomToast()
  const navigate = useNavigate()
  const [mainWorkspace, setMainWorkspace] = useState<IWorkspace | undefined>()

  useEffect(() => {
    if (workspaces) {
      setMainWorkspace(workspaces.find((w) => w.is_main_space))
    } else {
      setMainWorkspace(undefined)
    }
  }, [workspaces])
  useEffect(() => {
    handleChangeDate(new Date())
  }, [])

  const bookingConfirmed = (status: boolean, data: any) => {
    if (status) {
      toast({ description: 'Booking created', status: 'success' })
      revalOrgUnitBookings()
    }
  }
  useEffect(() => {
    if (locations && locations.length > 0) {
      setChosenLocation(locations[0])
    }
  }, [locations])

  const workspacesBookings =
    workspaces && workspaces.length > 1
      ? workspaces
          .filter((w) => !w.is_main_space)
          .map((w) => w.bookings || [])
          .reduce(
            (b1: ISimpleBooking[], b2: ISimpleBooking[]) => b1.concat(b2),
            []
          )
      : []
  const mainSpaceBookings =
    workspaces && workspaces.length > 1
      ? workspaces
          .filter((w) => w.is_main_space)
          .map((w) => w.bookings || [])
          .reduce(
            (b1: ISimpleBooking[], b2: ISimpleBooking[]) => b1.concat(b2),
            []
          )
      : []

  return (
    <>
      <Box pb={6} px={8} bg="#FFFFFF" width="100%">
        <Text py={8} fontWeight="bold" fontSize="18px" color="black">
          Search for Workspaces
        </Text>

        <Flex flexDir={['column', 'column', 'row']}>
          <Box w={['100%', '80%', '30%']}>
            <CalendarFormInput
              dateProps={{
                chosenDate,
                handleChangeDate
              }}
              closeOnSelect={true}
            />
          </Box>
          <Box w={['100%', '80%', '30%']} ml={[0, 0, 3]}>
            {chosenLocation && (
              <FilterableBookableLocationsSelect
                name="Location"
                dataTestId="location-searchbox"
                initialValue={chosenLocation.id}
                onSelect={(loc) => {
                  setChosenLocation(loc)
                }}
              />
            )}
          </Box>
          <Spacer />
          <Box display="flex" alignItems="center" w={'20%'}>
            <HStack py={4}>
              <Button
                key={'action'}
                variant="secondary"
                onClick={() => {
                  navigate(routeStrings.memberDashboardWorkspaceBookings)
                }}
                data-testid=""
              >
                My Bookings
              </Button>
            </HStack>
          </Box>
        </Flex>
      </Box>

      <Flex
        bg="#F4F5F5"
        width="100%"
        height="100%"
        pos="relative"
        overflow="scroll"
      >
        {loading && (
          <Flex
            pos="absolute"
            align="center"
            justify="center"
            h="100%"
            w="100%"
            overflow="hidden"
            opacity={0.5}
            bg="#f4f5f5"
            cursor=""
          >
            <Spinner size="xl" />
          </Flex>
        )}
        <Container maxW="container.lg">
          {/* {loading && <Spinner />} */}
          {chosenLocation && chosenLocation.opening_hours ? (
            <Box mt="10px">
              <Box>
                {mainWorkspace && mainWorkspace.is_bookable && (
                  <Box w={'50%'}>
                    <Text color="gray" fontSize={'lg'} my={2}>
                      Book the whole space
                    </Text>
                    <Divider />
                    <WorkspaceTimeLineCard
                      openingHours={chosenLocation.opening_hours}
                      workspace={mainWorkspace}
                      chosenDate={chosenDate}
                      bookingConfirmed={bookingConfirmed}
                      otherBookings={workspacesBookings}
                    />
                  </Box>
                )}
                <Text color="gray" fontSize={'lg'} my={2}>
                  Book individual workspaces
                </Text>
                <Divider />
                <SimpleGrid columns={[1, 2]} spacing="10px">
                  {workspaces
                    ?.filter((w) => !w.is_main_space && w.is_bookable)
                    .map((workspace, i) => (
                      <WorkspaceTimeLineCard
                        key={i}
                        openingHours={chosenLocation.opening_hours}
                        workspace={workspace}
                        isExternal={
                          isExternalMember ||
                          !bookableLocations.find(
                            (l) => l.id === workspace.location
                          )
                        }
                        chosenDate={chosenDate}
                        bookingConfirmed={bookingConfirmed}
                        otherBookings={mainSpaceBookings}
                      />
                    ))}
                </SimpleGrid>
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Container>
      </Flex>
    </>
  )
}
