import {
  Box,
  Container,
  Flex,
  Button as ChakraButton,
  Heading,
  Text,
  Stack,
  ContainerProps,
  Avatar,
  Divider
} from '@chakra-ui/react'
import React from 'react'
import { useBookingContext } from '../../../../context/BookingContext'
import { ILocationBooking } from '../../../../types'
import { useRole } from '../../../../utils/rbac'
import { ReactNodeWithProps } from '../../../../component.types'
import { usePlatformTenant } from '../../../../context/platform-tenant-context'

interface Props {
  location?: ILocationBooking
}

export const LocationDetailsContainerV2: React.FC<
  Props & ReactNodeWithProps
> = (props) => {
  const { location, children } = props
  const { isExternalMember } = useRole()
  const { platformTheme } = usePlatformTenant()
  const { locations: bookableLocations } = useBookingContext()
  const isExternalBooking =
    isExternalMember || !bookableLocations.find((l) => l.id === location?.id)
  const bookableLocation = bookableLocations.find((l) => l.id === location?.id)
  const boxStyles: ContainerProps = {
    py: 8,
    px: 10,
    bg: 'white',
    shadow: 'lg',
    rounded: 'lg'
  }

  if (!platformTheme) return null

  return (
    <Container maxW={'6xl'} {...boxStyles}>
      <Flex flexDirection={['column-reverse', 'column-reverse', 'row']}>
        <Stack w={['100%', '100%', '50%']} pr={[1, 2, 5]}>
          <Box>
            <Stack mt={6} direction={'row'} spacing={4}>
              <Avatar src={platformTheme.logo_square} />
              <Stack
                direction={'column'}
                spacing={0}
                fontSize={'sm'}
                w={['100%', '100%']}
              >
                <Text fontWeight={600} fontSize="2xl">
                  {platformTheme.business_name}
                </Text>
                {/* <Text color={'gray.500'}>Something something</Text> */}
              </Stack>
            </Stack>
            <Box display={'row'} mt={5}>
              {platformTheme.contact_form_url && (
                <ChakraButton
                  colorScheme="gray"
                  variant={'solid'}
                  as={'a'}
                  href={platformTheme.contact_form_url}
                  target={'_blank'}
                  background={platformTheme.brand_primary_color}
                  textColor={'white'}
                  size={'sm'}
                >
                  Contact
                </ChakraButton>
              )}
            </Box>
            <>
              <Divider my={[2, 5, 6]} />
              <Stack spacing={4}>
                <Heading size={'sm'}>About this space</Heading>
                <Flex w="100%" flexShrink={0} p={2}>
                  <Text
                    wordBreak="break-word"
                    textAlign={'justify'}
                    whiteSpace="pre-line"
                    dangerouslySetInnerHTML={{
                      __html: location?.booking_about ?? ''
                    }}
                  ></Text>
                </Flex>
              </Stack>
              <Divider my={[2, 5, 6]} />

              <Stack spacing={4}>
                <Heading size={'sm'}>Rules</Heading>
                <Flex w="100%" flexShrink={0} p={2}>
                  <Text
                    wordBreak="break-word"
                    textAlign={'justify'}
                    whiteSpace="pre-line"
                    dangerouslySetInnerHTML={{
                      __html: location?.booking_rules ?? ''
                    }}
                  ></Text>
                </Flex>
              </Stack>
            </>
          </Box>
          <Stack></Stack>
        </Stack>
      </Flex>
    </Container>
  )
}
