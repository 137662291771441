import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerProps
} from '@chakra-ui/react'
import React from 'react'
import { IActionButton } from '../../../components/GroupingHeaderLayout'

interface Props extends DrawerProps {
  isOpen: boolean
  onClose: () => void
  title?: string
  titleComponent?: React.ReactNode
  actions?: IActionButton[]
  headerMenuActions?: IActionButton[]
}

export const DrawerComponent: React.FC<Props> = (props) => {
  const {
    title,
    titleComponent,
    actions,
    headerMenuActions,
    isOpen,
    onClose,
    children,
    ...rest
  } = props

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={props.size} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth={title || titleComponent ? '1px' : ''}>
          {title}
          {titleComponent}
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
