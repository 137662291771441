import { Container, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useUserDetails } from '../../context/user-onboard-context'
import { css } from '@emotion/react'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { routeStrings } from '../../routeStrings'
import { Steps } from 'antd'
import { ExternalOnboardingEmail } from '../ExternalOnboarding/ExternalOnboardingEmail'
import { ExternalOnboardingEmailValidation } from '../ExternalOnboarding/ExternalOnboardingEmailValidation'
import { ExternalOnboardingAcceptTC } from '../ExternalOnboarding/ExternalOnboardingAcceptTC'
import { useNavigate, useParams } from 'react-router-dom'

interface Props {
  selectedTenant?: number
  onFinishSignUp?: () => void
}
const STEP_EMAIL = 0
const STEP_EMAIL_VALIDATION = 1
const STEP_PASSWORD = 2
const steps = [
  {
    title: 'Account Info',
    key: ''
  },
  {
    title: 'Validation',
    key: ''
  },
  {
    title: 'Confirmation',
    key: ''
  }
]

export const OnboardingStepsExternal: React.FC<Props> = (props) => {
  const { selectedTenant, onFinishSignUp } = props
  const { userDetails, setUserDetails } = useUserDetails()
  const { setThemeTenantLocation, platformTheme } = usePlatformTenant()

  const [hasValidatedEmail, setHasValidatedEmail] = useState(false)
  const [emailValidated, setEmailValidated] = useState('')
  const [hasCompletedProfile, setHasCompletedProfile] = useState(false)
  const [step, setStep] = useState(STEP_EMAIL)
  const navigate = useNavigate()
  const [tenantId, setTenantId] = useState<number>()
  const toast = useToast()
  const { Step } = Steps

  let { tenant: param_tenant } = useParams<{ tenant: string | undefined }>()

  useEffect(() => {
    let tenantId: number | undefined = selectedTenant
    try {
      if (!param_tenant && !selectedTenant) navigate(-1)
      if (!tenantId && param_tenant) {
        tenantId = parseInt(param_tenant.split('-')[1])
      } else {
        if (!tenantId) {
          console.error('No tenant ID found')
          navigate('/')
        }
      }
      setThemeTenantLocation(tenantId, undefined)
      setTenantId(tenantId)
    } catch (error) {
      errorParsingURL()
    }
  }, [])
  const errorParsingURL = () => {
    toast({
      title: 'Error',
      description: 'Please, confirm that the URL is correct',
      status: 'error',
      duration: 8000,
      isClosable: true
    })

    navigate(-1)
  }

  const buttonStyles = css`
    border: 1px solid ${platformTheme?.brand_primary_color};
  `

  return (
    <Container
      centerContent
      maxW="3xl"
      boxShadow="2xl"
      borderRadius="xl"
      p={2}
      py={10}
    >
      <Container centerContent maxW="3xl" my={2}>
        {steps && (
          <Steps
            current={step}
            size="small"
            progressDot={true}
            style={{
              marginBottom: 18,
              paddingBottom: 18,
              boxShadow: '0px -1px 0 0 #e8e8e8 inset'
            }}
          >
            {steps.map((item, index) => (
              <Step
                key={item.title}
                title={item.title}
                onClick={() => {
                  // setStep(index)
                }}
                description={
                  index === STEP_EMAIL_VALIDATION
                    ? emailValidated
                      ? 'Verified'
                      : ''
                    : index === STEP_EMAIL
                    ? userDetails?.email
                    : ''
                }
              ></Step>
            ))}
          </Steps>
        )}
      </Container>
      {step === STEP_EMAIL && (
        <ExternalOnboardingEmail
          onCompleteCallback={() => {
            setHasCompletedProfile(true)

            if (hasValidatedEmail && emailValidated === userDetails.email) {
              setStep(STEP_PASSWORD)
            } else {
              setStep(STEP_EMAIL_VALIDATION)
            }
          }}
          setIsActive={() => {}}
          isActive={step === STEP_EMAIL}
          isComplete={hasCompletedProfile}
          setComplete={(complete: boolean) => {
            setHasCompletedProfile(complete)
          }}
        />
      )}
      {step === STEP_EMAIL_VALIDATION && (
        <ExternalOnboardingEmailValidation
          onCompleteCallback={() => {
            setHasValidatedEmail(true)
            setUserDetails((prevState) => ({
              ...prevState,
              emailVerified: true
            }))
            setEmailValidated(userDetails.email)
            setStep(STEP_PASSWORD)
          }}
          setIsActive={() => {}}
          isActive={step === STEP_EMAIL_VALIDATION}
          isComplete={hasValidatedEmail}
          setComplete={(complete: boolean) => {
            setHasValidatedEmail(true)
          }}
          goBack={() => {
            setStep(STEP_EMAIL)
          }}
        />
      )}
      {step === STEP_PASSWORD && (
        <ExternalOnboardingAcceptTC
          onCompleteCallback={() => {
            if (onFinishSignUp != undefined) {
              onFinishSignUp()
            } else {
              // By Default, redirect there
              navigate(routeStrings.externalThankyou)
            }
          }}
          setIsActive={() => {}}
          isActive={step === STEP_PASSWORD}
          isComplete={hasCompletedProfile}
          goBack={() => {
            setStep(STEP_EMAIL)
          }}
        />
      )}
    </Container>
  )
}
