import styled from '@emotion/styled'
import { Tab } from '@chakra-ui/react'

export const StyledTab = styled(Tab)`
  /* tab / default */
  font-weight: 600;
  font-size: 14px;
  color: #2f4858;
  padding-bottom: 16px;
  border: unset;

  /* tab / active */
  &[aria-selected='true'] {
    border: unset;
    border-bottom: 2px solid #2f4858;
    color: #2f4858;
  }
`
