import {
  Box,
  Flex,
  Button as ChakraButton,
  Stack,
  StackDivider,
  Text,
  Spacer
} from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { routeStrings } from '../../routeStrings'
import { useAuth } from '../../context/auth-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { stripeOnboardingLink } from '../../api'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'

export const TenantOnboardingIntegrations: React.FC = () => {
  const { platformTenant } = usePlatformTenant()
  const navigate = useNavigate()
  const { currentOrgUnit } = useAuth()

  const { REACT_APP_XERO_CLIENT_ID, REACT_APP_XERO_REDIRECT_URI } = process.env
  return (
    <OnboardingWrapper>
      {' '}
      <Box p={5} pl={10} pt={20} w="500px">
        <Box mt={6}>
          <Text className="teams-complete" fontSize="20px">
            <strong>{platformTenant?.business_name}</strong>
            <br />
            Set up Payment Integrations
          </Text>
          <br />
          <Stack
            spacing={4}
            divider={<StackDivider borderColor={'gray.700'} />}
          >
            <Flex>
              <Box p="4">
                <Text fontSize="16px" fontWeight={600}>
                  Stripe: Payments platform
                </Text>
              </Box>
              <Spacer />
              <Box p="2">
                <Button
                  flexShrink={1}
                  onClick={async () => {
                    if (!platformTenant) return
                    const link = await stripeOnboardingLink(platformTenant.id)
                    window.location.replace(link.data.url)
                  }}
                >
                  Connect
                </Button>
              </Box>
            </Flex>
            <Flex>
              <Box p="4">
                <Text fontSize="16px" fontWeight={600}>
                  XERO: Accounting Platform
                </Text>
              </Box>
              <Spacer />
              <Box p="2">
                <Button
                  flexShrink={1}
                  onClick={() => {
                    window.location.replace(
                      `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${REACT_APP_XERO_CLIENT_ID}&redirect_uri=${REACT_APP_XERO_REDIRECT_URI}&scope=openid offline_access accounting.contacts accounting.transactions accounting.settings&state=${currentOrgUnit?.id}`
                    )
                  }}
                >
                  Connect
                </Button>
              </Box>
            </Flex>
            {/* <Stack direction={'row'} align={'center'}>
            <Text fontSize="16px" fontWeight={600}>
              XERO: Accounting Platform
            </Text>
            <Button
              flexShrink={1}
              onClick={async () => {
                
              }}
            >
              Connect
            </Button>
          </Stack> */}
          </Stack>
          <br />

          <br />
          <br />
          <ChakraButton
            ml="auto"
            variant="link"
            data-testid="onboarding-next"
            style={{ float: 'right', display: 'inline-block' }}
            onClick={() => {
              navigate(routeStrings.onboardingTenantComplete)
            }}
            // disabled={isSubmitting}
          >
            Skip this step
          </ChakraButton>
        </Box>
      </Box>
    </OnboardingWrapper>
  )
}
