import React from 'react'
import { Flex, Image, Box, useTheme } from '@chakra-ui/react'

import { RouterLink } from '../../../components/Link'

const Progress: React.FC<{ progress: number | undefined }> = ({ progress }) => {
  return (
    <Box w="100%" bg="#EEEEEE" position="relative" h="4px">
      {progress !== undefined && (
        <Box
          transition="width 0.5s"
          position="absolute"
          bg="brandPrimary"
          w={`${progress * 100}%`}
          left={0}
          top={0}
          h="4px"
        />
      )}
    </Box>
  )
}

interface Props {
  progress?: number
  children: React.ReactNode
}

/**
 * This is the basic Spacecubed layout for onboarding screens.
 * It has a progress prop which goes from 0 to 1 to describe the percent complete.
 *
 * In some cases this component can be composed so that the progress automatically advances
 * eg in the case of `LayoutNewUserOnboarding`.
 */
export const BaseLayoutOnboarding: React.FC<Props> = ({
  progress,
  children
}) => {
  const theme: any = useTheme()
  return (
    <Flex direction="column" overflow="hidden" flexGrow={1}>
      <Flex as="nav" py={3} px={5} align="center" h={'66px'}>
        <RouterLink to={'/'} justifySelf="flex-start">
          <Image src={theme.logos.logo_long} width={200} />
        </RouterLink>
      </Flex>
      <Progress progress={progress} />
      <Flex
        flexDir="column"
        align="center"
        pt={3}
        flexGrow={1}
        h={'50vh'}
        overflowY={'auto'}
      >
        {children}
      </Flex>
    </Flex>
  )
}
