import {
  Box,
  Flex,
  HStack,
  Tab,
  TabList,
  Tabs,
  Text,
  useTheme
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { Button } from '../components/Button'
import { useAdminBodyWidth } from './AdminBodyWrapper'

export interface IGroupingHeader {
  title: string
  key: string
  selectAction: (key: string) => void
}

export interface IActionButton {
  text: string
  testIdPrefix?: string
  variant: string
  onClick: () => void
  className?: string
}

export interface ITab {
  text: string
  active: boolean
  className?: string
  selectAction: () => void
  hide?: boolean
}

interface IInputProps {
  basicTitle?: string
  titleComponent?: React.ReactNode
  // groups?: IGroupingHeader[]
  action?: IActionButton | IActionButton[]
  iconAction?: React.ReactNode | React.ReactNode[]
  totalValue?: string | number
  tabs?: ITab[]
  compact?: boolean
}

export const GroupingHeaderLayout: React.FC<IInputProps> = (props) => {
  const defaultHeader: IGroupingHeader = {
    title: '',
    key: '',
    selectAction: () => {}
  }
  const {
    action,
    basicTitle,
    titleComponent,
    iconAction,
    totalValue,
    compact,
    ...rest
  } = props
  const [currentgroup, setCurrentGroup] =
    useState<IGroupingHeader>(defaultHeader)
  const theme: any = useTheme()
  const isCompactComponent = compact === true
  const adminHeaderHeight = isCompactComponent ? '164px' : '194px'

  // useEffect(() => {
  //   // set grouping as the first in the groups
  //   groups && setCurrentGroup(groups.length > 0 ? groups[0] : defaultHeader)
  // }, [])

  const width = useAdminBodyWidth()
  const StyledTab = styled(Tab)`
    /* tab / default */
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.brandPrimary};
    padding-bottom: 16px;
    border: unset;

    /* tab / active */
    &[aria-selected='true'] {
      border: unset;
      border-bottom: 2px solid ${theme.colors.brandPrimary};
      color: ${theme.colors.brandPrimary};
    }
  `
  // const menu = (
  //   <Flex
  //     css={css`
  //       flex-direction: column;
  //       background: #ffff;
  //       box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  //       border-radius: 4px;
  //       padding: 16px;
  //     `}
  //   >
  //     {!!groups &&
  //       groups.map((group: IGroupingHeader, index) => {
  //         // console.log(row.original)
  //         if (group.key !== currentgroup.key) {
  //           return (
  //             <MenuText
  //               // variant={group.variant}
  //               ml="19px"
  //               onClick={() => {
  //                 setCurrentGroup(group)
  //                 group.selectAction(group.key)
  //               }}
  //               key={index}
  //               data-testid={group.title && group.title}
  //             >
  //               {group.title}
  //             </MenuText>
  //           )
  //         } else {
  //           return (
  //             <MenuText fontWeight="bold" key={index}>
  //               <Icon name={'check' as any} /> {group.title}
  //             </MenuText>
  //           )
  //         }
  //       })}
  //   </Flex>
  // )

  return (
    <Flex
      h={{
        base: '164px',
        md: adminHeaderHeight
      }}
      width="100%"
      justify="center"
      background="white"
    >
      <Flex
        width={width}
        justify="space-between"
        direction="row"
        alignSelf="bottom"
        alignItems="bottom"
        mt="auto"
        mb={props.tabs ? 0 : 3}
        mx={3}
        {...rest}
      >
        <Box>
          <Flex alignItems="baseline" mb={2}>
            <Text
              mr={3}
              fontSize={isCompactComponent ? '20px' : '27px'}
              mb={1}
              mt={isCompactComponent ? '10px' : undefined}
              opacity={0.65}
              textColor={theme.colors.brandPrimary}
            >
              {basicTitle ? basicTitle : currentgroup.title}
            </Text>
            {titleComponent !== undefined && <>{titleComponent}</>}
            <Flex>
              {iconAction &&
                (Array.isArray(iconAction) ? iconAction : [iconAction]).map(
                  (node, i) => {
                    return node
                  }
                )}
            </Flex>
            {/* {groups && groups.length > 1 && (
              <Dropdown
                overlay={menu}
                placement="bottomLeft"
                className="antdRhsDotsDropdown"
              >
                <Icon
                  name={'chevron-down' as any}
                  color="brandPrimary"
                  size="30px"
                  alignSelf="center"
                  cursor="pointer"
                  css={css`
                    background: #efefef;
                    border-radius: 28px;
                    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                    &:hover,
                    &.ant-dropdown-open {
                      transform: rotate(180deg);
                    }
                  `}
                />
              </Dropdown>
            )} */}
            <Text fontSize="xs" color="grey">
              {totalValue && totalValue + ' Total'}
            </Text>
          </Flex>
          {props.tabs && props.tabs.length > 0 && (
            <Tabs
              mb={'2px'}
              h={isCompactComponent ? '60px' : '45px'}
              defaultIndex={
                // This is to check if an 'active' prop has been passed, if not default to the first tab
                props.tabs.findIndex((tab) => tab.active) > -1
                  ? props.tabs.findIndex((tab) => tab.active)
                  : 0
              }
            >
              <TabList borderBottom={0}>
                {props.tabs
                  .filter((t) => !t.hide)
                  .map((tab) => (
                    <StyledTab
                      key={tab.text}
                      data-testid={tab.text}
                      className={tab.className}
                      onClick={tab.selectAction}
                    >
                      {tab.text}
                    </StyledTab>
                  ))}
              </TabList>
            </Tabs>
          )}
        </Box>

        <Box display="flex" alignItems="center">
          <HStack>
            {action !== undefined &&
              (Array.isArray(action) ? action : [action]).map((a, i) => (
                <Button
                  key={'action' + i}
                  className={a.className}
                  variant="secondary"
                  size={isCompactComponent ? 'xs' : undefined}
                  onClick={() => a.onClick()}
                  data-testid={a.testIdPrefix}
                >
                  {a.text}
                </Button>
              ))}
          </HStack>
        </Box>
      </Flex>
    </Flex>
  )
}

export const SecondaryGroupingHeaderLayout: React.FC<IInputProps> = (props) => {
  const defaultHeader: IGroupingHeader = {
    title: '',
    key: '',
    selectAction: () => {}
  }
  const { action, titleComponent, iconAction, totalValue, compact, ...rest } =
    props
  const [currentgroup, setCurrentGroup] =
    useState<IGroupingHeader>(defaultHeader)
  const theme: any = useTheme()
  const isCompactComponent = compact === true
  const hasTitle = !!currentgroup.title

  const width = useAdminBodyWidth()
  const StyledTab = styled(Tab)`
    /* tab / default */
    font-weight: 400;
    font-size: 14px;
    color: ${theme.colors.brandPrimary};

    /* tab / active */
    &[aria-selected='true'] {
      border: unset;
      color: ${theme.colors.brandPrimary};
      font-weight: 600;
      border: 1px solid ${theme.colors.brandPrimary};
      border-radius: 25px;
    }
  `
  return (
    <Flex width="100%" justify="center" background="white">
      <Flex
        width={width}
        justify="space-between"
        direction="row"
        alignSelf="bottom"
        alignItems="bottom"
        mt="auto"
        mb={props.tabs ? 0 : 3}
        mx={3}
        {...rest}
      >
        <Box>
          <Flex alignItems="baseline" mb={2}>
            {titleComponent !== undefined && <>{titleComponent}</>}
            <Flex>
              {iconAction &&
                (Array.isArray(iconAction) ? iconAction : [iconAction]).map(
                  (node, i) => {
                    return node
                  }
                )}
            </Flex>
          </Flex>
          {props.tabs && props.tabs.length > 0 && (
            <Tabs
              mb={'0px'}
              py={2}
              defaultIndex={
                // This is to check if an 'active' prop has been passed, if not default to the first tab
                props.tabs.findIndex((tab) => tab.active) > -1
                  ? props.tabs.findIndex((tab) => tab.active)
                  : 0
              }
              variant={'unstyled'}
            >
              <TabList borderBottom={0}>
                {props.tabs
                  .filter((t) => !t.hide)
                  .map((tab) => (
                    <StyledTab
                      key={tab.text}
                      data-testid={tab.text}
                      className={tab.className}
                      onClick={tab.selectAction}
                    >
                      {tab.text}
                    </StyledTab>
                  ))}
              </TabList>
            </Tabs>
          )}
        </Box>

        <Box display="flex" alignItems="center">
          <HStack>
            {action !== undefined &&
              (Array.isArray(action) ? action : [action]).map((a, i) => (
                <Button
                  key={'action' + i}
                  className={a.className}
                  variant="secondary"
                  size={isCompactComponent ? 'xs' : undefined}
                  onClick={() => a.onClick()}
                  data-testid={a.testIdPrefix}
                >
                  {a.text}
                </Button>
              ))}
          </HStack>
        </Box>
      </Flex>
    </Flex>
  )
}
