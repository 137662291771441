import { Checkbox, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BaseModal } from '../../../../components/modals/BaseModal'
import { IOrganizationSeat, Plan } from '../../../../types'
import { format } from 'date-fns'
import { requestPlanChange } from '../../../../api'

interface IModalInput {
  seat?: IOrganizationSeat
  newPlan: Plan
  changeDate: Date
  closeModalCallback: () => void
}

export const ConfirmChangeModal: React.FC<IModalInput> = (input) => {
  const { seat, newPlan, changeDate, closeModalCallback } = input
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isNotifyAdmins, setIsNotifyAdmins] = useState(true)
  const toast = useToast()

  function closeModal() {
    setIsNotifyAdmins(true)
    closeModalCallback()
  }

  function requestChange() {
    if (isSubmitting) return
    setIsSubmitting(true)
    if (seat && newPlan && changeDate) {
      requestPlanChange(seat.id, newPlan.id, changeDate, isNotifyAdmins)
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: `Plan change request sent`
          })
          closeModal()
        })
        .catch(() => {
          setIsSubmitting(false)
          toast({
            status: 'error',
            description: `Failed to send change request`
          })
        })
    }
  }

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNotifyAdmins(e.target.checked)
  }

  if (!!!seat) {
    return null
  }
  return (
    <BaseModal
      title="Confirm Change"
      isOpen={seat !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText={'Confirm'}
      primaryAction={requestChange}
      secondaryAction={closeModal}
      isSubmitting={isSubmitting}
      isLoading={isSubmitting}
    >
      <Stack spacing={4} fontSize={'md'}>
        {!seat.active_plan ? (
          <Text>
            Are you sure you want to change your membership from{' '}
            <Text as="span" fontWeight={'bold'}></Text>
            {' to '}
            <Text as="span" fontWeight={'bold'}>
              "{newPlan.name}"
            </Text>{' '}
            starting on {format(changeDate, 'dd/MM/yyyy')}?
          </Text>
        ) : (
          <Text>
            Are you sure you want to modify this new membership
            {' to '}
            <Text as="span" fontWeight={'bold'}>
              "{newPlan.name}"
            </Text>{' '}
            starting on {format(changeDate, 'dd/MM/yyyy')}?
          </Text>
        )}
        <Checkbox isChecked={isNotifyAdmins} onChange={onCheckboxChange}>
          <Text fontSize={'sm'}>
            Notify the team administrators about this change.
          </Text>
        </Checkbox>
      </Stack>
    </BaseModal>
  )
}
