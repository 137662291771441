import React, { useContext, useEffect, useState } from 'react'
import { useFeatureFlagsAPI } from '../utils/apiHooks'
import { useAuth } from './auth-context'
import { usePlatformTenant } from './platform-tenant-context'

export interface FeatureFlagProps {
  acuityEnabled?: boolean
  processingFeeEnabled?: boolean
  editWifiEnabled?: boolean
  loading: boolean
  dailyFiveEnabled?: boolean
  isBetaTester?: boolean
  officeManagementEnabled?: boolean
  coworkingSettingsEnabled?: boolean
  bondsUIEnabled?: boolean
  stripeConnectOnboardingEnabled?: boolean
  stripeConnectEnabled?: boolean
  workspaceBookingEnabled?: boolean
  workzoneSignupEnabled?: boolean
  membersDirectoryEnabled?: boolean
  AIMembersChatbotEnabled?: boolean
  AIMembersCustomChatbotEnabled?: boolean
  AIAdminChatbotEnabled?: boolean
  AIAdminCustomChatbotEnabled?: boolean
  publicBookingsEnabled?: boolean
  leadForensicsPluginEnabled?: boolean
  userDeletionRequestsEnabled?: boolean
}

const defaultProps: FeatureFlagProps = {
  acuityEnabled: undefined,
  processingFeeEnabled: undefined,
  loading: true,
  dailyFiveEnabled: undefined,
  isBetaTester: undefined,
  officeManagementEnabled: undefined,
  coworkingSettingsEnabled: undefined,
  stripeConnectOnboardingEnabled: undefined,
  stripeConnectEnabled: undefined,
  workspaceBookingEnabled: undefined,
  workzoneSignupEnabled: undefined,
  membersDirectoryEnabled: undefined,
  AIMembersChatbotEnabled: undefined,
  AIMembersCustomChatbotEnabled: undefined,
  AIAdminChatbotEnabled: undefined,
  AIAdminCustomChatbotEnabled: undefined,
  publicBookingsEnabled: undefined,
  leadForensicsPluginEnabled: undefined,
  userDeletionRequestsEnabled: undefined
}

export const FeatureFlagContext =
  React.createContext<FeatureFlagProps>(defaultProps)

export const FeatureFlagProvider = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [acuityEnabled, setAcuityEnabled] = useState<boolean>()
  const [processingFeeEnabled, setProcessingFeeEnabled] = useState<boolean>()
  const [editWifiEnabled, setEditWifiEnabled] = useState<boolean>()
  const [dailyFiveEnabled, setDailyFiveEnabled] = useState<boolean>()
  const [coworkingSettingsEnabled, setCoworkingSettingsEnabled] =
    useState<boolean>()
  const [stripeConnectOnboardingEnabled, setStripeConnectOnboardingEnabled] =
    useState<boolean>()
  const [stripeConnectEnabled, setStripeConnectEnabled] = useState<boolean>()
  const [workspaceBookingEnabled, setWorkspaceBookingEnabled] =
    useState<boolean>()
  const [workzoneSignupEnabled, setWorkzoneSignupEnabled] = useState<boolean>()
  const [isBetaTester, setIsBetaTester] = useState<boolean>()
  const [bondsUIEnabled, setBondsUIEnabled] = useState<boolean>()
  const [publicBookingsEnabled, setPublicBookingsEnabled] = useState<boolean>()
  const [membersDirectoryEnabled, setMembersDirectoryEnabled] =
    useState<boolean>()
  const [userDeletionRequestsEnabled, setUserDeletionRequestsEnabled] =
    useState<boolean>()
  const [leadForensicsPluginEnabled, setLeadForensicsPluginEnabledEnabled] =
    useState<boolean>()
  // BEGIN: AI Chatbots
  const [AIMembersChatbotEnabled, setAIMembersChatbotEnabled] =
    useState<boolean>()
  const [AIMembersCustomChatbotEnabled, setAIMembersCustomChatbotEnabled] =
    useState<boolean>()
  const [AIAdminChatbotEnabled, setAIAdminChatbotEnabled] = useState<boolean>()
  const [AIAdminCustomChatbotEnabled, setAIAdminCustomChatbotEnabled] =
    useState<boolean>()
  const [officeManagementEnabled, setOfficeManagementEnabled] =
    useState<boolean>()

  // END: AI Chatbots

  const { data: flags } = useFeatureFlagsAPI()
  const { me } = useAuth()
  const { platformTenant } = usePlatformTenant()
  useEffect(() => {
    if (flags) {
      const acuityFlag = flags.find((flag) => flag.name === 'acuity')
      if (acuityFlag) setAcuityEnabled(acuityFlag.enabled)
      const feeFlag = flags.find((flag) => flag.name === 'processing_fee')
      if (feeFlag) setProcessingFeeEnabled(feeFlag.enabled)
      const wifiFlag = flags.find((flag) => flag.name === 'edit_wifi_passwords')
      if (wifiFlag) setEditWifiEnabled(wifiFlag.enabled)
      const coworkingSettings = flags.find(
        (flag) => flag.name === 'coworking_business_settings'
      )
      if (coworkingSettings)
        setCoworkingSettingsEnabled(coworkingSettings.enabled)
      const officeManagementFlag = flags.find(
        (flag) => flag.name === 'office_management_'
      )
      if (officeManagementFlag)
        setOfficeManagementEnabled(officeManagementFlag.enabled)

      const workspacesBooking = flags.find(
        (flag) => flag.name === 'workspace_bookings'
      )
      setWorkspaceBookingEnabled(workspacesBooking && workspacesBooking.enabled)
      const stripeConnectOnboarding = flags.find(
        (flag) => flag.name === 'stripe_connect_onboarding'
      )
      if (stripeConnectOnboarding)
        setStripeConnectOnboardingEnabled(stripeConnectOnboarding.enabled)
      const stripeConnect = flags.find((flag) => flag.name === 'stripe_connect')
      if (stripeConnect) setStripeConnectEnabled(stripeConnect.enabled)
      const dailyFiveFlag = flags.find((flag) => flag.name === 'daily_five')
      const membersDirectoryEnabled = flags.find(
        (flag) => flag.name === 'enable_members_directory'
      )
      const userDeletionRequestsEnabledFlag = flags.find(
        (flag) => flag.name === 'enable_user_removal_requests'
      )
      setMembersDirectoryEnabled(
        membersDirectoryEnabled && membersDirectoryEnabled.enabled
      )
      setUserDeletionRequestsEnabled(
        userDeletionRequestsEnabledFlag &&
          userDeletionRequestsEnabledFlag.enabled
      )
      const aiMembersChatbotEnabled = flags.find(
        (flag) => flag.name === 'enable_members_ai_chatbot'
      )
      setAIMembersChatbotEnabled(
        aiMembersChatbotEnabled && aiMembersChatbotEnabled.enabled
      )
      const aiMembersCustomChatbotEnabled = flags.find(
        (flag) => flag.name === 'enable_custom_members_ai_chatbot'
      )
      setAIMembersCustomChatbotEnabled(
        aiMembersCustomChatbotEnabled && aiMembersCustomChatbotEnabled.enabled
      )
      const aiAdminChatbotEnabled = flags.find(
        (flag) => flag.name === 'enable_admin_ai_chat_bot'
      )
      setAIAdminChatbotEnabled(
        aiAdminChatbotEnabled && aiAdminChatbotEnabled.enabled
      )
      const aiAdminCustomChatbotEnabled = flags.find(
        (flag) => flag.name === 'enable_custom_admin_ai_chat_bot'
      )
      setAIAdminCustomChatbotEnabled(
        aiAdminCustomChatbotEnabled && aiAdminCustomChatbotEnabled.enabled
      )

      const publicBookingsEnabled = flags.find(
        (flag) => flag.name === 'enable_public_bookings_page'
      )
      setPublicBookingsEnabled(
        publicBookingsEnabled && publicBookingsEnabled.enabled
      )
      const leadForensicsPluginEnabled = flags.find(
        (flag) => flag.name === 'enable_lead_forensics_plugin'
      )
      setLeadForensicsPluginEnabledEnabled(
        leadForensicsPluginEnabled && leadForensicsPluginEnabled.enabled
      )
      if (me !== undefined) setIsBetaTester(me.user.is_beta_tester)
      if (dailyFiveFlag)
        setDailyFiveEnabled(
          dailyFiveFlag.enabled || me?.user.is_beta_tester || false
        )
      const bondsUIEnabledFlag = flags.find(
        (flag) => flag.name === 'bonds_UI_enabled'
      )
      if (bondsUIEnabledFlag) setBondsUIEnabled(true)

      //  Quick fix
      const workzoneSignup = flags.find(
        (flag) => flag.name === 'workzone_signup'
      )
      setWorkzoneSignupEnabled(workzoneSignup && workzoneSignup.enabled)

      // QUICKFIX, disables all the d5 features if the coworking profile flag is not true
      if (platformTenant && dailyFiveFlag)
        setDailyFiveEnabled(
          (platformTenant?.show_daily_five &&
            (dailyFiveFlag.enabled || me?.user.is_beta_tester)) ||
            false
        )
      setLoading(false)
    }
  }, [flags, me?.user.is_beta_tester, platformTenant, me])

  return (
    <FeatureFlagContext.Provider
      value={{
        acuityEnabled,
        processingFeeEnabled,
        editWifiEnabled,
        loading,
        dailyFiveEnabled,
        isBetaTester,
        officeManagementEnabled,
        coworkingSettingsEnabled,
        bondsUIEnabled,
        stripeConnectOnboardingEnabled,
        stripeConnectEnabled,
        workspaceBookingEnabled,
        membersDirectoryEnabled,
        userDeletionRequestsEnabled,
        leadForensicsPluginEnabled,
        AIMembersChatbotEnabled,
        AIMembersCustomChatbotEnabled,
        AIAdminChatbotEnabled,
        AIAdminCustomChatbotEnabled,
        workzoneSignupEnabled,
        publicBookingsEnabled
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext)
  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider')
  }
  return context
}
