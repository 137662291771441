import { Box, Image, useTheme, Text, Heading } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { updateOrganizationName, updateUserDetails } from '../../api'

import { Button } from '../../components/Button'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'
import { useAuth } from '../../context/auth-context'
import { useFeatureFlags } from '../../context/feature-flag-context'
import { routeStrings } from '../../routeStrings'
import { useUserDetails } from '../../context/user-onboard-context'
import { Roles, useRole } from '../../utils/rbac'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

interface Props {
  hubspot_link: string
}

export const OnBoardingHubspotIframe = ({ hubspot_link }: Props) => {
  return (
    <>
      <iframe
        title="Onboarding Scheduling"
        src={hubspot_link}
        width="100%"
        height="700"
        frameBorder="0"
      />
      <script
        type="text/javascript"
        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
      />
    </>
  )
}

export const CompleteBookOnboarding: React.FC = () => {
  const { acuityEnabled } = useFeatureFlags()
  const { userDetails } = useUserDetails()
  const { role, isExternalMember } = useRole()
  const navigate = useNavigate()
  const { currentOrgUnit, currentOrganization, me, getAndSetMe } = useAuth()
  const theme: any = useTheme()
  const [hubspotLink, setHubspotLink] = useState<string>()
  const allendaleTenant =
    role === Roles.FernAllendaleTenantTeamAdmin ||
    role === Roles.FernAllendaleTenantActiveMember

  const isIlumaUser =
    role === Roles.IlumaTenantTeamAdmin ||
    role === Roles.IlumaTenantActiveMember
  useEffect(() => {
    if (currentOrgUnit) {
      const hubspotLink =
        currentOrgUnit.organization.home_location?.hubspot_onboarding_link

      setHubspotLink(hubspotLink)
    }
  }, [currentOrgUnit])
  return (
    <OnboardingWrapper>
      <Box p={3}>
        <Image
          src={theme.logos.logo_long}
          width="128px"
          className="location-logo"
        />
        <Box mt={6}>
          <Box my={3}>
            <FontAwesomeIcon icon={faCheckCircle} size="2x" color="#00A86B" />
          </Box>
          <Heading className="teams-complete" fontSize="xl">
            {currentOrgUnit &&
              `Welcome to ${currentOrgUnit?.organization.parent_platform_tenant_name}, ${me?.user.name}!`}
          </Heading>
          <br />
          {allendaleTenant || isIlumaUser || isExternalMember ? null : (
            <>
              {' '}
              <br />
              <Text>
                You will start your membership on{' '}
                <strong>
                  {userDetails.startDate &&
                    format(userDetails.startDate, 'do MMMM yyyy')}
                </strong>
              </Text>
              <Text>
                {acuityEnabled
                  ? 'You can now book an onboarding session, and access your account.'
                  : 'You can now access your account.'}
              </Text>
            </>
          )}
          {isExternalMember && (
            <>
              {' '}
              <br />
              <Text>
                You will be able to book meeting rooms, offices, and workspaces
                at different locations across &nbsp;
                <strong>the Spacecubed Network.</strong>
              </Text>
              <br />
              <Text>You can now access your account.</Text>
              <br />
            </>
          )}
        </Box>

        {allendaleTenant || isIlumaUser || isExternalMember ? null : (
          <>
            <br />
            {acuityEnabled && hubspotLink && (
              <OnBoardingHubspotIframe hubspot_link={hubspotLink} />
            )}

            <br />
            <br />
          </>
        )}
        <Button
          data-testid="complete-next"
          onClick={() => {
            const token = window.localStorage.getItem('Token')
            if (userDetails!.company && currentOrganization) {
              updateOrganizationName(
                currentOrganization.id,
                userDetails!.company!
              )
            }
            return updateUserDetails(
              {
                about_you: userDetails!.aboutYou,
                job_title: userDetails!.jobTitle,
                personal_email: userDetails!.personalEmail,
                interests: userDetails!.interests,
                skills: userDetails!.skills
              },
              { token }
            ).then(() => {
              getAndSetMe!(token).then(() => {
                if (allendaleTenant || isIlumaUser) {
                  navigate(routeStrings.memberDashboardHome)
                } else if (isExternalMember) {
                  navigate(routeStrings.memberDashboardMeetingRooms)
                } else {
                  navigate(routeStrings.memberDashboardBillingUpdate)
                }
              })
            })
          }}
        >
          {allendaleTenant
            ? 'Start booking meetings'
            : isIlumaUser
            ? 'Start booking workspaces'
            : 'Finish'}
        </Button>
      </Box>
    </OnboardingWrapper>
  )
}
