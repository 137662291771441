import {
  Text,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
  Stack,
  Checkbox,
  Divider,
  Flex,
  useToast,
  HStack,
  Heading,
  Alert
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { BaseModal } from '../../../components/modals/BaseModal'
import { IOfficeMembershipChangeRequest } from '../../../types'
import { format } from 'date-fns'
import { declineChangeRequest, approveChangeRequest } from '../../../api'
import { FeatureComponent } from '../../../components/FeatureComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDesktop,
  faMoneyBillWave,
  faUserAlt,
  faUsersRectangle
} from '@fortawesome/free-solid-svg-icons'
import { CalendarCustomIcon } from '../../../components/icons/icons'

interface IModalInput {
  request?: IOfficeMembershipChangeRequest
  closeModalCallback: () => void
}

export const ReviewOfficeMembershipChangeRequestModal: React.FC<IModalInput> = (
  input
) => {
  const { request, closeModalCallback } = input
  const toast = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [overrideValue, setOverrideValue] = useState(
    request?.override_value ? request?.override_value : undefined
  )
  const [overrideReason, setOverrideReason] = useState<string | undefined>(
    request?.override_reason
  )
  const [
    isFirstSubscriptionNonChargeable,
    setIsFirstSubscriptionNonChargeable
  ] = useState(false)

  function closeModal() {
    setIsSubmitting(false)
    closeModalCallback()
  }

  useEffect(() => {
    setIsFirstSubscriptionNonChargeable(false)
    if (request) {
      if (request.override_value !== undefined) {
        setOverrideValue(request.override_value)
        setOverrideReason(request.override_reason ?? '')
      } else {
        setOverrideValue(undefined)
        setOverrideReason(undefined)
      }
    }
  }, [request])

  const validate = () => {
    let isValid = true

    return isValid
  }

  function approveRequest() {
    if (request && validate()) {
      if (isSubmitting) return
      setIsSubmitting(true)
      approveChangeRequest({
        requestId: request.id
      })
        .then(() => {
          toast({
            status: 'success',
            description: 'Request Approved'
          })
          closeModal()
        })
        .catch(() => {
          toast({
            status: 'error',
            description: 'Failed to Approve Request'
          })
          closeModal()
        })
    }
  }

  function declineRequest() {
    if (request) {
      declineChangeRequest(request.id)
        .then(() => {
          toast({
            status: 'success',
            description: 'Request Declined'
          })
          closeModal()
        })
        .catch(() => {
          toast({
            status: 'error',
            description: 'Failed to Decline Request'
          })
          closeModal()
        })
    }
  }

  if (request === undefined) {
    return null
  }

  const isDeletion = request.is_deletion
  const isChangeRate =
    request.override_value !== undefined && request.override_value !== null
  return (
    <BaseModal
      title={
        isDeletion
          ? 'Membership cancellation'
          : 'Office Membership Change Request'
      }
      isOpen={request !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText="Approve Request"
      primaryAction={approveRequest}
      secondaryButtonText="Decline Request"
      secondaryAction={declineRequest}
      isSubmitting={isSubmitting}
      isLoading={isSubmitting}
    >
      <Stack spacing={4}>
        <HStack spacing={5}>
          <FeatureComponent
            icon={<FontAwesomeIcon icon={faUserAlt} />}
            text="Requested By"
            subtitle={request.requested_by?.name ?? ''}
          />
          <FeatureComponent
            icon={<CalendarCustomIcon />}
            text={`Requested on`}
            subtitle={`${format(Date.parse(request.created_at), 'dd/MM/yyyy')}`}
          />
        </HStack>
        <HStack spacing={5}>
          <FeatureComponent
            text="Office"
            subtitle={`${request.office.name} @ ${request.office.location.name}`}
            icon={<FontAwesomeIcon icon={faDesktop} />}
            iconBg={'gray.100'}
          />
        </HStack>
        <FeatureComponent
          text="Team"
          subtitle={`${request.organization.name}`}
          icon={<FontAwesomeIcon icon={faUsersRectangle} />}
          iconBg={'gray.100'}
        />
      </Stack>

      <>
        <Divider my={3} />

        {!isDeletion && (
          <Stack>
            <Heading size={'sm'} mb={2} color={'red.500'}>
              Requested Changes
            </Heading>
            <HStack spacing={5}>
              {request.change_start_date && (
                <FeatureComponent
                  text={`Start date`}
                  subtitle={`${format(
                    Date.parse(request.change_start_date),
                    'dd/MM/yyyy'
                  )}`}
                  icon={<CalendarCustomIcon />}
                  iconBg={'gray.100'}
                />
              )}
              {request.change_end_date && (
                <FeatureComponent
                  text={`End date`}
                  subtitle={`${format(
                    Date.parse(request.change_end_date),
                    'dd/MM/yyyy'
                  )}`}
                  icon={<CalendarCustomIcon />}
                />
              )}
              {request.no_end_date && (
                <FeatureComponent
                  text={`No end date`}
                  subtitle="Membership will renew indefinitely"
                />
              )}
              {isChangeRate && (
                <>
                  <FeatureComponent
                    text={`New Rate`}
                    subtitle={`$${request.override_value}`}
                  />
                  <FeatureComponent
                    text={`Reason`}
                    subtitle={request.override_reason ?? ''}
                  />
                </>
              )}
            </HStack>
            <Alert status="warning" mt={4}>
              <Text fontSize="sm">
                By approving this request, the existing membership for{' '}
                <strong>{request.organization.name}</strong> with the specified
                office
                {request.office.name} will be updated as outlined above.
                <br />
              </Text>
            </Alert>
          </Stack>
        )}
        {isChangeRate && (
          <Stack>
            <Flex mt={2} alignItems={'center'} justifyContent={'space-between'}>
              <FormLabel fontSize={'sm'}>Override Value:</FormLabel>
              <InputGroup width={'50%'} size="sm">
                <InputLeftElement children="$" />
                <Input
                  value={overrideValue}
                  onChange={(e) => setOverrideValue(parseFloat(e.target.value))}
                  id="overrideValue"
                  placeholder="Override Value"
                  variant="flushed"
                  type="number"
                />
              </InputGroup>
            </Flex>

            {overrideValue && !isNaN(overrideValue) && (
              <Flex
                mt={2}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <FormLabel fontSize={'sm'}>Override Reason:</FormLabel>
                <InputGroup width={'50%'} size="sm">
                  <Input
                    value={overrideReason}
                    onChange={(e) => setOverrideReason(e.target.value)}
                    id="overrideReason"
                    placeholder="Override Reason"
                    variant="flushed"
                    maxLength={100}
                  />
                </InputGroup>
              </Flex>
            )}
          </Stack>
        )}

        {isDeletion && (
          <Stack>
            <Alert status="warning" mt={4} display={'block'}>
              <Text fontSize="md" fontWeight="bold" color={'red.500'} mb={2}>
                Membership Cancellation Request
              </Text>
              <Text fontSize="sm">
                {request.requested_by?.name} has requested to cancel the office
                membership before it starts. By approving this request, the
                membership regarding{' '}
                <strong>{request.organization.name}</strong> with the office{' '}
                {request.office.name} will be cancelled.
                <br />
                <br />
                <strong>
                  Please, make sure to approve this request before the start
                  date of the membership to avoid any charges.
                </strong>
              </Text>
            </Alert>
          </Stack>
        )}
      </>
    </BaseModal>
  )
}
