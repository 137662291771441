import { CheckIcon, MinusIcon } from '@chakra-ui/icons'
import { Box, Flex, Tag, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { AccountCodeCell } from '../../../components/billing/AccountCodeCell'
import { CopyURLComponent } from '../../../components/CopyURLComponent'
import { IAction } from '../../../components/table/ActionsDropdown'
import { Table, TableDataProp } from '../../../components/table/Table'
import { UserDisplay } from '../../../components/UserDisplay'
import { useAuth } from '../../../context/auth-context'
import { useFeatureFlags } from '../../../context/feature-flag-context'
import { routeStrings } from '../../../routeStrings'
import { IMeetingRoomSuperAdmin } from '../../../types'
import { useAccountCodes, useLocations } from '../../../utils/apiHooks'

interface IInputParams {
  meetingRooms: IMeetingRoomSuperAdmin[] | undefined
  loading: boolean
  editMeetingRoomAction: IAction | IAction[] | undefined
}

export const MeetingRoomsTable: React.FC<IInputParams> = ({
  meetingRooms,
  loading,
  editMeetingRoomAction
}) => {
  const locations = useLocations()
  const auth = useAuth()
  const { publicBookingsEnabled } = useFeatureFlags()

  const { data: accountCodes } = useAccountCodes(
    auth?.currentOrganization?.parent_platform_tenant
  )

  function nameComponent(meetingRoom: IMeetingRoomSuperAdmin, locationName) {
    return (
      <Flex>
        <UserDisplay
          size="md"
          name={meetingRoom.name}
          src={meetingRoom.display_url}
        />
        <Box ml={3}>
          <Text fontSize={'md'} fontWeight="bold" color={'gray.700'}>
            {meetingRoom.name}
          </Text>
          <Text fontSize={'sm'} fontWeight="bold" color={'gray.400'}>
            {locationName}
          </Text>
          {meetingRoom.private_room && (
            <Text
              fontSize={'sm'}
              fontWeight="bold"
              color={'gray.400'}
              textDecoration={'underline'}
            >
              Private room
            </Text>
          )}
        </Box>
      </Flex>
    )
  }

  function costsComponent(meetingRoom: IMeetingRoomSuperAdmin) {
    return (
      <VStack justify="center" align="start" spacing={1}>
        <Text fontSize={'md'} fontWeight="bold">
          {meetingRoom.credits_per_hour}
          <Text fontSize={'sm'} fontWeight="normal" pt={3} as={'span'}>
            {` Credit${meetingRoom.credits_per_hour === 1 ? '' : 's'}`}
          </Text>
        </Text>
        <Text fontSize={'md'} fontWeight="bold">
          ${meetingRoom.member_price_per_hour}
          <Text fontSize={'sm'} fontWeight="normal" pt={3} as={'span'}>
            {' '}
            Members
          </Text>
        </Text>
        <Text fontSize={'md'} fontWeight="bold">
          ${meetingRoom.non_member_price_per_hour}
          <Text fontSize={'sm'} fontWeight="normal" pt={3} as={'span'}>
            {' '}
            Non-members
          </Text>
        </Text>
      </VStack>
    )
  }
  function checkComponent(value: boolean) {
    return (
      <Box justifyContent="center" marginLeft={3}>
        <Tag
          color={value ? 'green.600' : undefined}
          background={value ? 'green.100' : undefined}
        >
          {value ? <CheckIcon /> : <MinusIcon />}
        </Tag>
      </Box>
    )
  }

  function urlSlugComponent(url?: string) {
    if (!publicBookingsEnabled || url === undefined)
      return <Text>Not available</Text>
    return (
      <CopyURLComponent
        fontSize={'x-small'}
        borderRadius="full"
        label="Copy URL"
        url={url}
      />
    )
  }

  const tableData =
    (meetingRooms &&
      meetingRooms.map((meetingRoom) => {
        let actions: IAction[] = []

        /**
         * Match the `xero_account_code` number to the list of codes from useAccountCodes
         * This keeps our BE query for meetings lightweight, and will cache the repsonse in SWR
         */
        let matchedXeroAcc = accountCodes?.find(
          (ac) => ac.id === meetingRoom.xero_account_code
        )
        const location = locations.find(
          (location) => location.id === meetingRoom.location
        )
        const cleanedData = {
          id: TableDataProp(meetingRoom.id),
          test_prefix: TableDataProp(`meetingRoom-row-${meetingRoom.id}`),

          room: TableDataProp(
            nameComponent(meetingRoom, location?.name),
            meetingRoom.name + (location?.name ?? '')
          ),
          cost: TableDataProp(costsComponent(meetingRoom)),

          public_booking: TableDataProp(
            meetingRoom.private_room ? (
              <Text>Private room</Text>
            ) : (
              checkComponent(meetingRoom.public_booking)
            )
          ),
          xero_account_code: TableDataProp(
            <AccountCodeCell
              accountCode={matchedXeroAcc?.code}
              accountLabel={matchedXeroAcc?.title}
            />
          ),
          url_slug: TableDataProp(
            urlSlugComponent(
              meetingRoom.private_room || !meetingRoom.public_booking
                ? undefined
                : routeStrings.fullExternalMeetingRoomURL(meetingRoom.room_slug)
            )
          ),
          actions: TableDataProp([])
        }

        // Define actions for the row
        editMeetingRoomAction !== undefined &&
          (Array.isArray(editMeetingRoomAction)
            ? (actions = editMeetingRoomAction)
            : actions.push(editMeetingRoomAction))

        cleanedData.actions = TableDataProp(actions)

        return cleanedData
      })) ||
    []

  return (
    <Box my="3" w="100%">
      <Table
        headers={[
          { header: 'id', accessor: 'id', show: false },
          { header: 'test_prefix', accessor: 'test_prefix', show: false },

          {
            header: 'Room',
            accessor: 'room',
            show: true,
            width: 3 / 12,
            enableFilter: true
          },

          {
            header: 'Cost per hour',
            accessor: 'cost',
            show: true,
            width: 2 / 12
          },
          {
            header: 'Public Booking',
            accessor: 'public_booking',
            show: true,
            width: 2 / 12
          },
          {
            header: 'Xero Account Code',
            accessor: 'xero_account_code',
            show: true,
            width: 2 / 12
          },
          {
            header: 'URL',
            accessor: 'url_slug',
            show: true,
            width: 1 / 12
          },
          { header: '', accessor: 'actions', show: true, width: 1 / 12 }
        ]}
        data={tableData}
        loading={loading}
      />
    </Box>
  )
}
