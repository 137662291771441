import {
  Box,
  Center,
  Heading,
  Image,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { useUserDetails } from '../../context/user-onboard-context'
import { useSelfServeSignupLocations } from '../../utils/apiHooks'
import { OnboardingHeading } from './OnboardingComponents'
import { Location } from '../../types'
import { routeStrings } from '../../routeStrings'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

const LocationCard: React.FC<{ location: Location; onClick?: () => void }> = ({
  location,
  onClick
}) => {
  return (
    <Center py={3} onClick={onClick} data-testid={location.name.toLowerCase()}>
      <Box
        w={'290px'}
        bg={'white'}
        boxShadow={'2xl'}
        rounded={'xl'}
        overflow={'hidden'}
        cursor="pointer"
        _hover={{
          transform: 'translateY(-1px)',
          boxShadow: 'xl'
        }}
      >
        <Box bg={'white'} boxShadow={'lg'} rounded={'md'} overflow={'hidden'}>
          <Image
            w={'full'}
            src={location.thumbnail_url}
            objectFit="cover"
            alt={location.name}
            maxH="180px"
          />
        </Box>

        <Box p={6} pt={4}>
          <Stack spacing={0} align={'start'} mb={5}>
            <Heading fontSize={'2xl'} fontWeight={'bold'} fontFamily={'body'}>
              {location.name}
            </Heading>
          </Stack>
          <Stack align="start" justify={'flex-start'}>
            <Text color={'gray.500'} fontSize={['sm', 'md']}>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{
                  color: '#565656',
                  marginRight: '4px'
                }}
                size={'sm'}
              />
              {location.address}
            </Text>
            <Text as="span" fontWeight="bold" color={'gray.500'}>
              {location.city.name}
            </Text>
          </Stack>
        </Box>
      </Box>
    </Center>
  )
}

export const OnboardingChooseLocation: React.FC = () => {
  const navigate = useNavigate()
  const [tenantId, setTenantId] = useState<number>()
  const { data: locations, isValidating } =
    useSelfServeSignupLocations(tenantId)
  const { setThemeTenantLocation, platformTheme } = usePlatformTenant()
  const { setUserDetails } = useUserDetails()
  const { space } = queryString.parse(window.location.search) // Takes the ?loc=MakePlace out of the url. Useful when you directly link the location.

  useEffect(() => {
    let tenant_id = 1
    if (!space) {
      navigate(routeStrings.location)
    } else {
      tenant_id = parseInt(space?.toString() || '1')
    }
    setThemeTenantLocation(tenant_id, undefined)
    setTenantId(tenant_id)
  }, [space])

  // Location handling
  const handleSelectLocation = ({
    id,
    locationName
  }: {
    id: number
    locationName: string
  }) => {
    setUserDetails((currentValues: any) => ({
      ...currentValues,
      location_id: id,
      locationName
    }))

    navigate(routeStrings.locationPlans(id))
  }

  return (
    <Stack spacing={8} maxW="container.xl" shouldWrapChildren height={'100vh'}>
      <VStack spacing={2} textAlign="center">
        <OnboardingHeading>Choose your space</OnboardingHeading>

        <Text fontSize="lg" color={'gray.500'}>
          Choose the location that perfectly matches your unique needs, fires up
          your interests, and propels you toward your goals.
        </Text>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
        wrap="wrap"
      >
        {locations?.map((location, index) => (
          <LocationCard
            location={location}
            key={index}
            onClick={() =>
              handleSelectLocation({
                id: location.id,
                locationName: location.name
              })
            }
          />
        ))}
      </Stack>
      {tenantId != undefined && locations?.length === 0 && (
        <Heading color="#bbb">There are no available spaces</Heading>
      )}
    </Stack>
  )
}
