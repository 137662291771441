import { IInterval, ISimpleBooking } from '../../../types'
import { areIntervalsOverlapping, fromUnixTime, isEqual } from 'date-fns'
import { IMeetingRoomSuperAdmin, IBooking } from '../../../types'
import { utcToZonedTime } from 'date-fns-tz/esm'

interface IsMeetingRoomAvailableArguments {
  workspace: IMeetingRoomSuperAdmin
  bookings: ISimpleBooking[]
  chosenStartDateTime: Date
  chosenEndDateTime: Date
}

export function isMeetingRoomAvailable({
  bookings,
  chosenEndDateTime,
  chosenStartDateTime,
  workspace: meetingRoom
}: IsMeetingRoomAvailableArguments) {
  let available = true

  if (chosenEndDateTime < chosenStartDateTime) {
    return available
  }

  // filter the bookings array by bookings which match the meeting room ID
  const bookingsForMeetingRoom = bookings.filter(
    (booking) => meetingRoom.id === booking.meeting_room
  )

  // use that result from above rather than meetingRoom.bookings (which doesn't exist)
  bookingsForMeetingRoom.forEach((booking) => {
    if (
      areIntervalsOverlapping(
        {
          start: fromUnixTime(Date.parse(booking.start_time) / 1000),
          end: fromUnixTime(Date.parse(booking.end_time) / 1000)
        },
        { start: chosenStartDateTime, end: chosenEndDateTime }
      )
    ) {
      available = false
    }
  })

  return available
}

export const secondsToTimeString = (seconds: number) => {
  var hours = Math.floor(seconds / 3600)
  var minutes = Math.floor((seconds - hours * 3600) / 60)

  return `${hours > 0 ? hours : ''} ${
    hours === 1 ? 'hour' : hours > 0 ? 'hours' : ''
  } ${minutes > 0 ? `${minutes} minutes` : ''}`
}
export const isSameInterval = (bookingTimes: IInterval, booking: IBooking) => {
  return (
    isEqual(bookingTimes.start, new Date(Date.parse(booking.start_time))) &&
    isEqual(bookingTimes.end, new Date(Date.parse(booking.end_time)))
  )
}

export const getFormatedBookingDates = (booking: IBooking) => {
  let formattedStartDate = booking.timezone
    ? utcToZonedTime(
        new Date(Date.parse(booking.start_time.toString())),
        booking.timezone
      )
    : new Date(Date.parse(booking.start_time.toString()))
  let formattedEndDate = booking.timezone
    ? utcToZonedTime(
        new Date(Date.parse(booking.end_time.toString())),
        booking.timezone
      )
    : new Date(Date.parse(booking.end_time.toString()))
  return {
    startTime: formattedStartDate,
    endTime: formattedEndDate
  }
}

export const castHoursToDateWithTimezone = (
  hours: string | undefined,
  timezone: string,
  date?: Date
) => {
  if (!hours) return null
  date = date
    ? utcToZonedTime(date, timezone)
    : utcToZonedTime(new Date(), timezone)
  try {
    let hoursArray = hours.split(':')
    date?.setHours(parseInt(hoursArray[0]))
    date?.setMinutes(parseInt(hoursArray[1]))
    date?.setSeconds(0)
    date?.setMilliseconds(0)
  } catch (error) {
    console.error(error)
  }
  return date
}
