import { ChevronDownIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
  useTheme,
  VStack
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { faMapSigns } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useAppTourContextProvider } from '../context/app-tour-context'
import { useAuth } from '../context/auth-context'
import { useFeatureFlags } from '../context/feature-flag-context'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { routeStrings } from '../routeStrings'
import { tourTargetStrings } from '../tourStepsArrays'
import { Actions, Roles, useRBAC, useRole } from '../utils/rbac'
import { LogoutIcon } from './icons/icons'
import { UserDisplay } from './UserDisplay'
import { NavLink, useNavigate } from 'react-router-dom'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export const AdminHeader: React.FC = (props) => {
  const {
    dailyFiveEnabled,
    coworkingSettingsEnabled,
    workspaceBookingEnabled
  } = useFeatureFlags()
  const { isInAdminRole, assumeRole, allowedRoles } = useRole()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const navigate = useNavigate()
  const auth = useAuth()
  const theme: any = useTheme()
  const { platformTenant } = usePlatformTenant()
  const { setRun, setStepIndex, setAdminTourActive } =
    useAppTourContextProvider()
  const canManageMeetingRooms = useRBAC(Actions.AdministerMeetingRooms)
  const canManageWorkspaces = useRBAC(Actions.AdministerWorkspaces)
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const headerContainer = css`
    align-items: center;
    // position: fixed;
    // top: 0;
    // width: 100%;
  ` as any

  const linkContainer = css`
    align-items: center;
    flex-direction: row;
    margin-left: 2rem;
  ` as any

  const Link = styled(NavLink)<any>`
    font-size: 18px;
    line-height: 36px;
    color: #b4bec8;
    transition: all 0.18s ease;
    min-width: max-content;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &.active {
      font-weight: bold;
      color: white;

      :after {
        position: absolute;
        height: 3px;
        content: '';
        width: 24px;
        background: ${theme.colors.brandPrimary};
      }
    }

    &:hover {
      color: white;
      cursor: pointer;
    }
  `
  const Beta = css`
    background: ${theme.colors.brandPrimary};
    line-height: 11px;
    text-align: right;
    right: -79px;
    width: 30px;
    color: white;
    padding: 2px;
    font-size: 11px;
    position: relative;
    border-radius: 3px;
  ` as any

  const handleStartTour = () => {
    setStepIndex(undefined)
    setAdminTourActive(true)
    setRun(true)
  }

  if (!isInAdminRole) return null

  if (platformTenant && !platformTenant.is_onboard_complete) {
    return (
      <Flex height={62} bg={theme.colors.eastBay10} css={headerContainer}>
        <Button
          color={'#fff'}
          bg={theme.colors.eastBay10}
          onClick={() => navigate(routeStrings.logout)}
        >
          Logout
        </Button>
      </Flex>
    )
  }

  const adminLinks = [
    {
      className: tourTargetStrings.adminheaderRequests,
      to: routeStrings.adminDashboardPendingRequests,

      text: 'Requests',
      display: true
    },

    {
      className: tourTargetStrings.adminheaderMembers,
      to: routeStrings.adminDashboardMembers,

      text: 'Members',
      display: true
    },

    {
      className: tourTargetStrings.adminheaderTeams,
      to: routeStrings.adminDashboardTeams,

      text: 'Teams',
      display: true
    },

    {
      className: tourTargetStrings.adminheaderLocations,
      to: routeStrings.adminDashboardLocations,

      text: 'Locations',
      display: true
    },

    {
      className: tourTargetStrings.adminheaderPlans,
      to: routeStrings.AdminDashboardPlans,

      text: 'Plans',
      display: true
    },

    {
      className: tourTargetStrings.adminheaderReports,
      to: routeStrings.adminDashboardReports,

      text: 'Reports',
      display: true
    },

    {
      className: tourTargetStrings.adminheaderMeetingrooms,
      to: routeStrings.adminDashboardMeetingRooms,

      text: 'Meeting Rooms',
      display: canManageMeetingRooms
    },

    {
      className: tourTargetStrings.adminheaderWorkspaces,
      to: routeStrings.adminDashboardWorkspaces,

      text: 'Workspaces',
      display: workspaceBookingEnabled && canManageWorkspaces
    },

    {
      className: tourTargetStrings.adminheaderCommunity,
      to: routeStrings.adminDashboardCommunity,

      text: 'Community',
      display: dailyFiveEnabled
    },

    {
      className: tourTargetStrings.adminheaderSettings,
      to: routeStrings.adminDashboardPlatformTenantSettings,

      text: 'Settings',
      display:
        coworkingSettingsEnabled && allowedRoles.includes(Roles.SpaceSuperAdmin)
    }
  ]

  return (
    <Box
      position={'fixed'}
      top={0}
      zIndex={1000}
      w={{
        base: windowDimensions.width,
        md: '100%'
      }}
    >
      <Flex
        height={62}
        bg={theme.colors.eastBay10}
        css={headerContainer}
        data-testid="admin-header"
        justifyContent={['space-between']}
      >
        <Flex maxW={'90%'}>
          <NavLink
            id={tourTargetStrings.adminheaderInit}
            style={{ display: 'flex', minWidth: '117px' }}
            to={routeStrings.adminDashboard}
          >
            <Image
              src={theme.logos.logo_long_white}
              width={117}
              pl={3}
              alignSelf="center"
            />
          </NavLink>
          <Flex
            ml={{
              base: 'auto',
              md: 10
            }}
            h={16}
            alignItems={'center'}
            justifyContent={'start'}
            overflowX={'auto'}
          >
            <IconButton
              size={'md'}
              icon={
                isOpen ? (
                  <CloseIcon color={'white'} />
                ) : (
                  <HamburgerIcon color={'white'} />
                )
              }
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
              bg={'headingPrimary'}
              _active={{
                bg: 'headingPrimary'
              }}
              _focus={{
                bg: 'headingPrimary'
              }}
            />
            <Flex display={{ base: 'none', md: 'flex' }} css={linkContainer}>
              {adminLinks.map(
                (link, i) =>
                  link.display && (
                    <Link key={i} id={link.className} to={link.to}>
                      <Text>{link.text}</Text>
                    </Link>
                  )
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex color="grey" p="3" align="center">
          <Flex justify="end" alignContent="center" mr={5}>
            <Menu>
              <MenuButton
                id={tourTargetStrings.adminheaderAdminUserMenu}
                as={Button}
                background={'transparent'}
                _hover={{
                  bg: 'gray.700'
                }}
                _expanded={{ bg: 'gray.700' }}
                _focus={{ boxShadow: 'outline' }}
              >
                <UserDisplay
                  name={auth.me?.user.name}
                  src={auth.me?.user.user_profile.profile_picture?.image}
                />
                <ChevronDownIcon color="white" boxSize="20px" />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => navigate(routeStrings.logout)}>
                  <LogoutIcon mr="2" h="16px" w="16px" /> Logout
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    assumeRole(Roles.ActiveMember)
                    navigate(routeStrings.memberDashboardHome)
                    const org = auth.me?.organizational_units[0].organization
                    if (org && auth.adminChangeOrganization) {
                      auth.adminChangeOrganization(org)
                    }
                  }}
                >
                  Swap to member view
                </MenuItem>
                <MenuDivider />
                <MenuGroup title="Switch Teams">
                  <Box id="scrollable-list" maxH="500px" overflowY="scroll">
                    {auth.me?.organizational_units.map((ou, i) => (
                      <MenuItem
                        background={
                          auth.currentOrgUnit?.id === ou.id
                            ? 'gray.100'
                            : undefined
                        }
                        key={`adminHeaderMenu:${i}`}
                        onClick={() =>
                          auth.me && auth.setOrganizationalUnit(auth.me, ou)
                        }
                      >
                        <HStack py={1} px={3} justify={'left'}>
                          <UserDisplay
                            size={'xs'}
                            name={ou.organization.name}
                          />
                          <VStack justify="left" align="left" spacing="0px">
                            <Box maxW="100px">
                              <Text fontSize="sm" isTruncated>
                                {ou.organization.name}
                              </Text>
                            </Box>
                            <Text fontWeight="bold" fontSize="xs">
                              {ou.organization.parent_platform_tenant_name}
                            </Text>
                          </VStack>
                        </HStack>
                      </MenuItem>
                    ))}
                  </Box>
                </MenuGroup>
                <MenuDivider />
                <MenuItem onClick={handleStartTour}>
                  <FontAwesomeIcon
                    icon={faMapSigns}
                    style={{ marginRight: '8px' }}
                  />
                  Start Platform Tour
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }} bg={'headingPrimary'}>
          <Stack as={'nav'} spacing={4}>
            {adminLinks.map(
              (link, i) =>
                link.display && (
                  <Link
                    key={i}
                    to={link.to}
                    style={{ paddingLeft: '15px' }}
                    onClick={onClose}
                  >
                    {link.text}
                  </Link>
                )
            )}
          </Stack>
        </Box>
      ) : null}
    </Box>
  )
}

interface Props {
  children: React.ReactNode
}

const NavLinkStyled = (props: Props) => {
  const { children } = props

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none'
      }}
      href={'#'}
    >
      {children}
    </Box>
  )
}
