import { AxiosError } from 'axios'
import { InvoiceStatus, RefundStatus } from './api'
import { SignupStatus } from './interfaces'
import { Dispatch, SetStateAction } from 'react'

type DjangoErrorResponseData = {
  non_field_errors?: string[]
}

export type DjangoErrorResponse = AxiosError<DjangoErrorResponseData>

export type IMessageResponse<T> = {
  message: string
  success: boolean
  data: T
}

export interface IDictionary<T> {
  [key: string]: T
}

export interface IStatusBoxDict {
  [id: string]: IStatusBoxEntry
}

export interface ICity {
  id: number
  name: string
  timezone: string
}

export interface IInvoicePrefix {
  id: number
  prefix: string
  location?: number
}

export interface IBasicLocation {
  id: number
  name: string
  address: string
  thumbnail_url: string
  order_of_preference: number
  city: ICity
  platform_tenant_id: number
  platform_tenant_name: string
}

export interface Location extends IBasicLocation {
  invoice_prefix?: IInvoicePrefix
  opening_hours?: ILocationOpeningHours
  business_hours?: ILocationBusinessHours[]
  hidden_to_signup?: boolean
  hubspot_onboarding_link?: string
  has_bookable_meeting_rooms?: boolean
  booking_confirmation_document_url?: string
}

export interface ILocationBooking {
  id: number
  name?: string
  opening_hours?: ILocationOpeningHours
  business_hours?: ILocationBusinessHours[]
  external_booking_message?: string
  booking_about?: string
  booking_rules?: string
  address?: string
  thumbnail_url?: string
}

export interface ILocationUpdateCreate {
  id?: number
  name: string
  address: string
  city: number
  thumbnail?: File
  order_of_preference: number
  hidden_to_signup: boolean
  accounting_department_code?: string
}

export interface ILocationBusinessHours {
  day: number
  open?: string
  close?: string
}
export interface ILocationOpeningHours {
  location: number
  full_day_from?: string
  full_day_to?: string
  first_half_from?: string
  first_half_to?: string
  second_half_from?: string
  second_half_to?: string
  hours_half_day: number
}

export interface ILocationAdmin {
  id: number
  location: IBasicLocation
  organizational_unit: IOrganizationalUnitBasic
  notify_new_external_booking: boolean
  notify_pending_change_requests: boolean
}

export interface ILocationAdminDTO {
  id?: number
  location: number
  organizational_unit: number
  notify_new_external_booking?: boolean
  notify_pending_change_requests?: boolean
}

export interface IPlanCategory {
  id: string
  name: string
}
export interface PlanRate {
  id: number
  frequency: 'YEAR' | 'MONTHLY'
  value: number
}

export type IPlanCategoryInput = Omit<PlanCategory, 'id'>

export interface PlanCategory {
  id: number
  name: string
  xero_account_code?: string
}
export interface PlanCategoryDetailed {
  id: number
  name: string
  xero_account_code?: IAccountCode
}
export interface INewPlan {
  name: string
  description: string
  tenant: number
  location?: number
  order_of_preference: number
  hidden: boolean
  hidden_to_admin: boolean
  meeting_room_credits_included: number
  category: number
  inclusions_document_file?: File
  frequency: string
  value: number
  addon_plan?: boolean
  approve_on_sign_up?: boolean
}
export interface Plan {
  id: number
  name: string
  location?: Location
  description: string
  rate: PlanRate
  rate_string: string
  order_of_preference: number
  category: PlanCategory
  inclusions_document_url: string
  hidden: boolean
  hidden_to_admin: boolean
  meeting_room_credits_included: number
  addon_plan?: boolean
  approve_on_sign_up: boolean
  uuid: string
}

export interface IPlanBasic {
  id: number
  name: string
  location?: Location
  uuid: string
}

export interface Skill {
  id: number
  name: string
}

export interface Interest {
  id: number
  name: string
}

export interface UserProfilePictures {
  image: string
}

export interface TenantUserProfile {
  signup_status: SignupStatus
  platform_tenant: number
}
interface BasicUserProfile {
  id: number
  mobile_number: string
  job_title: string
  company: string
  start_date: string
  is_migrated_from_nexudus: boolean
  profile_complete: boolean
  profile_picture?: UserProfilePictures
  receives_space_admin_emails: boolean
}

interface MemberUserProfile {
  id: number
  job_title?: string
  profile_picture_url?: string
  skills?: Skill[]
  about_you?: string
  linkedin_url?: string
}

export interface UserProfile extends BasicUserProfile {
  profile_picture_url?: any
  personal_email: string
  postcode: number
  interests: Interest[]
  skills: Skill[]
  about_you: string
  wifi_password?: string
  agreed_tc: number
}

export interface User {
  id: number
  email: string
  name: string
  user_profile: BasicUserProfile
  tenant_profiles: TenantUserProfile[]
  is_staff: boolean
  is_space_admin: boolean
  is_beta_tester: boolean
}

export interface UserDetail extends User {
  first_name: string
  last_name: string
  username: string
  user_profile: UserProfile
}

export interface ILineItem {
  charged_by: string
  created_at?: string
  description: string
  id?: number
  invoice?: number
  long_description: string
  organizational_unit?: number
  quantity: number
  updated_at?: string
  value: string
}

export interface _InvoiceSearchFilterProps {
  page: number
  date?: string
  teamName?: string
  invoiceId?: string
  tenant?: number
  locId?: number
  status?: string
}

export interface IInvoicePreview {
  invoice: IInvoice
  line_items: ILineItem[]
}

export interface IInvoice {
  id: number
  organization: number
  organization_name: string
  subtotal_charged: string
  tax_charged: string
  amount_charged: string
  amount_paid: number
  status: InvoiceStatus
  paid_on: string | null
  issued_on: string
  subtotal: number
  refund?: IRefund | null
  platform_tenant: number
  platform_tenant_name: string
}

export interface IRefund {
  id: string
  value: number
  created_at: string
  refunded_on?: string
  status: RefundStatus
  message: string
}

export interface IPaginatedQuery<T> {
  count?: number
  next?: string
  previous?: string
  results?: T[]
  detail?: string
}

export interface IPaginatedInvoices {
  count: number
  next: string
  previous: string
  results: IInvoice[]
}

export interface IPaginatedResults<T> {
  count: number
  next: string
  previous: string
  results: T[]
}

export interface IMeetingCreditQuota {
  credits_remaining: string
}

export interface INote {
  id: number
  spacecubed_user: number
  note: string
  date_taken: string
}

export interface IOrganizationalUnitBasic {
  id: number
  user: User
  is_team_admin: boolean
  is_space_super_admin: boolean
  job_title: string
  organization_name: string
  created_at?: string
}

export interface IOrganizationalUnitMeResponse
  extends IOrganizationalUnitBasic {
  organization: IOrganization
}
export interface IOrganizationalUnit extends IOrganizationalUnitBasic {
  organization: IOrganization
  seats?: IOrganizationSeat[]
}

export interface IOrganizationalUnitFilterableSelect
  extends IOrganizationalUnitBasic {
  organization: IOrganizationFilterableSelect
}

export interface IOrganizationalUnitDetail extends IOrganizationalUnit {
  ongoing_plan?: Plan
  user: UserDetail
}

export interface IOrganizationSeatBasic {
  id: number
  organizational_unit: IOrganizationalUnitBasic | null
  active_invitation: IInvitation | null
}

export interface IOrganizationSeat extends IOrganizationSeatBasic {
  organization: IOrganizationBasic
  ongoing_plan: Plan
  active_plan: Plan
  office_desk: number | undefined
  office_information?: { id: number; name: string; level: string; room: string }
  ongoing_plan_start_date: string | undefined
}

export interface IOrganizationSeatDetail extends IOrganizationSeat {
  invitations: IInvitation[]
}

export interface IChangeRequest {
  id: number
  request_type: string
  real_type: string

  requested_by?: User
  status: string
  message?: string
  reviewed_at?: string
  completed_at?: string
  created_at: string
  reviewed_by: number
}

export interface IAddonPlanChangeRequest extends IChangeRequest {
  plan_request_type: string
  change_date: string
  old_plan?: Plan
  new_plan?: Plan
  organization: IOrganizationBasic
  override_value?: string
}

export interface IPlanChangeRequest extends IChangeRequest {
  plan_request_type: string
  change_date: string
  old_plan?: Plan
  new_plan?: Plan
  organization_seat: IOrganizationSeat
}

export interface IOrganizationalUnitRequest extends IChangeRequest {
  organization: IOrganization
  plan: Plan
  is_first_subscription_non_chargeable: boolean
  start_date: string
  is_non_taxable: boolean
  is_non_chargeable: boolean
  override_value?: any
  override_reason?: string
  requested_for?: User
  location?: Location
}

export interface IAccountDeletionRequest extends IChangeRequest {
  requested_for?: User
  requested_for_email?: string
  teams?: {
    id: number
    name: string
  }[]
  notes_list: string[]
}
export interface IOfficeAssignationRequest extends IChangeRequest {
  manager: IOfficeSubscriptionManager
  organization: IOrganizationBasic
  office: IOfficeDetail
  start_date: string
  end_date?: string
  is_first_subscription_non_chargeable?: boolean
  is_non_taxable?: boolean
  is_non_chargeable?: boolean
  override_value?: number
  override_reason?: string
  issue_invoice?: boolean
}

export interface IOfficeMembershipChangeRequest extends IChangeRequest {
  organization: IOrganizationBasic
  office: IOfficeDetail
  change_start_date?: string
  change_end_date?: string
  no_end_date?: boolean
  is_deletion?: boolean
  override_value?: number
  override_reason?: string
}

export interface IAccountDeletionRequestData {
  notes_list: string[]
}

export type AccountDeletionResponse = {
  message: string
  success: boolean
  notes: string[]
}

export interface PaymentMethod {
  payment_type: string
  display_text: string
  brand?: string
  exp_month?: string
  exp_year?: string
  bsb_number?: string
  last4?: string
  status?: string
  mandate_status?: string
  mandate_url?: string
}

export interface DefaultPaymentMethod {
  id: number
  payment_method?: PaymentMethod
  active: boolean
}

export interface IUsersSet {
  id: number
  user: User | null
  is_team_admin: boolean
}

export interface IOrganizationBasic {
  id: number
  name: string
  is_team: boolean
  eft_permitted?: boolean
  members?: IOrganizationalUnitBasic[]
  is_space_admin: boolean
  organization_profile?: IBasicOrganizationProfile
}

export interface IOrganization extends IOrganizationBasic {
  is_active_default_payment_method: Boolean
  // seats: IOrganizationSeat[]
  // members: IOrganizationalUnitBasic[]
  // offices: IOffice[]
  is_chargeable: boolean
  primary_contact_name: string
  primary_contact_email: string
  gst_chargeable: boolean
  last_invoiced: string
  // xero_contact_id: string
  home_location: Location
  parent_platform_tenant: number
  parent_platform_tenant_name: string
  is_active_organization?: boolean
  is_deleted?: boolean
}
export interface IOrganizationFilterableSelect extends IOrganizationBasic {
  is_active_organization?: boolean
}

export interface IBaseSubscriptionInstance {
  start_date?: string
  end_date?: string
  auto_renew?: Boolean
  is_chargeable?: Boolean
  override_value?: string
  override_reason?: string
  plan?: Plan
  office?: IBasicOffice
}

export interface IOfficeSubscriptionManager {
  id: number
  organization: IOrganizationBasic
  office?: IBasicOffice
  start_date?: string
  end_date?: string
  current_subscription?: IBaseSubscriptionInstance
  next_subscription?: IBaseSubscriptionInstance
}

export interface IAddonSubscriptionRequest {
  organization: number
  plan: number
  start_date: string | undefined
}
export interface IAddonSubscription {
  id?: number
  organization?: IOrganization
  plan?: Plan
  override_value?: number
}

export interface IOrganizationDetail extends IOrganization {
  seats: IOrganizationSeat[]
  offices: IOffice[]
  primary_contact_name: string
  primary_contact_email: string
  gst_chargeable: boolean
  last_invoiced: string
  is_chargeable: boolean
  default_payment_method?: DefaultPaymentMethod
  xero_contact_id: string
  billing_account: BillingAccount
  addon_plans: IAddonSubscription[]
}
export interface IAccountCode extends IXeroAccountCode {
  id: number
  is_payment_account: boolean
}
export interface IXeroAccountCode {
  title: string
  code: string
  xero_account_id: string
}

export interface IXeroIntegrationSettings {
  invoice_sync_enabled: boolean
  payment_sync_enabled: boolean
  connected_xero: boolean
  can_sync_payments: boolean
  can_sync_invoices: boolean
  xero_payment_account_code: IXeroAccountCode
}

export interface IXeroIntegrationSettingsUpdate {
  invoice_sync_enabled: boolean
  payment_sync_enabled: boolean
  xero_payment_account_code: string | undefined
}

export interface IBillingCredit {
  id?: number
  credits_given: number
  credits_remaining: number | undefined
  created_at?: string
  expiry: Date | string | undefined
  reason: string
  xero_account_code: string
  organization: number | undefined
}

export interface ICreateOneTimeCharge {
  description: string
  long_description: string
  value: number
  quantity: number
  organization: number
  xero_account_code: string
}

export interface IOneTimeCharge {
  id: number
  description: string
  long_description: string
  value: number
  quantity: number
  charged_by: string
  organization: number
  xero_account_code: string

  invoice?: number
  has_been_paid: boolean
  created_at: string
  updated_at: string
}
export interface IBond {
  id?: number
  category?: string
  organization?: number | string
  location: Location | undefined
  date_paid?: Date | string
  date_returned?: Date | string
  amount_paid: number
  amount_returned: number
  notes_given?: string
  user?: number
}

export interface UpdateObjectBillingAccount {
  billing_name?: string
  billing_email?: string
  billing_address?: string
}

export interface BillingAccount {
  id: number
  billing_name: string
  billing_email: string
  billing_address: string
}

export interface IBillingAccountDetail extends BillingAccount {
  id: number
  platform_tenant: number
  platform_tenant_name: string
  payment_gateway: DefaultPaymentMethod
}

/** raw response from the API to get user details */
export interface MeResponse {
  user: User
  admin_of: IOrganization[]
  organizational_units: IOrganizationalUnit[]
}

export enum MembershipStates {
  PendingAdminApproval = 'PendingAdminApproval',
  NeedsToCompleteInitialProfile = 'NeedsToCompleteInitialProfile',
  PendingStartingMembership = 'PendingStartingMembership',
  FullyActive = 'FullyActive',
  ActiveWithoutMembership = 'ActiveWithoutMembership'
}

/** user details with some convenience properties */
export interface Me extends MeResponse {
  membershipState: MembershipStates
  startDate: Date
}

export interface IInvitation {
  token: string
  invitation_email: string
  organization: number
  organizational_unit?: number
  created_at: string
  used?: Date
  cancelled?: Date
  is_valid: boolean
  inviter?: User
  cancelled_by?: User
  used_by?: User
  location?: number
  location_name?: string
  is_team_admin?: Boolean
  invitation_type:
    | 'ExistingUserInvitation'
    | 'ExistingUserTeamInvitation'
    | 'NewUserInvitation'
    | 'TeamInvitation'
  tenant_name?: string
  tenant_id?: number
  id_tenant?: number
}

export interface IInvitationRecord {
  token: string
  inviter_name: string
  invitation_email: string
  created_at: string
  used: string
  cancelled: string
  updated_at: string
  cancelled_by: Date
  start_date: string
  type: string
}

export interface INewTeamInvitationRecord extends IInvitationRecord {
  organization: {
    id: number
    name: string
  }
  seats: Array<{
    active_plan: Plan
    id: number
  }>
}

export interface INewMemberInvitationRecord extends IInvitationRecord {
  plan?: Plan
  location?: Location
  is_first_subscription_non_chargeable: boolean
  start_date: string // YYYY-MM-dd
  override_value?: number
  override_reason?: string

  organization_name?: string
}

export interface IStatusBoxEntry {
  color: string
  label?: string
}
export interface IFeatureFlag {
  id: number
  name: string
  label: string
  description: string
  enabled: boolean
}

export interface IIndividualFeatureFlag {
  id: number
  name: string
  label: string
  description: string
}

// @TODO: delete the un-needed spaghetti
export interface Record {
  KisiGroupId?: any
  AccessControlGroupId?: any
  GoogleCalendarId?: any
  Timeslots?: any
  BusinessId: number
  Name: string
  ResourceTypeId: number
  ResourceTypeName: string
  Description?: any
  EmailConfirmationContent?: any
  Visible: boolean
  RequiresConfirmation: boolean
  DisplayOrder: number
  GroupName?: any
  Projector: boolean
  Internet: boolean
  ConferencePhone: boolean
  StandardPhone: boolean
  WhiteBoard: boolean
  LargeDisplay: boolean
  Catering: boolean
  TeaAndCoffee: boolean
  Drinks: boolean
  SecurityLock: boolean
  CCTV: boolean
  VoiceRecorder: boolean
  AirConditioning: boolean
  Heating: boolean
  NaturalLight: boolean
  AllowMultipleBookings: boolean
  Allocation?: any
  BookInAdvanceLimit?: any
  LateBookingLimit?: any
  LateCancellationLimit?: any
  IntervalLimit?: any
  NoReturnPolicy?: any
  NoReturnPolicyAllResources?: any
  NoReturnPolicyAllUsers?: any
  MaxBookingLength?: any
  MinBookingLength?: any
  Tariffs?: any
  AddedTariffs?: any
  RemovedTariffs?: any
  Shifts?: any
  LinkedResources?: any
  AddedLinkedResources?: any
  RemovedLinkedResources?: any
  Longitude: number
  Latitude: number
  HideInCalendar: boolean
  Archived: boolean
  NewPictureUrl?: any
  PictureFileName?: any
  ClearPictureFile: boolean
  Id: number
  UpdatedOn: Date
  CreatedOn: Date
  UniqueId: string
  UpdatedBy: string
  IsNew: boolean
  SystemId?: any
  ToStringText: string
  LocalizationDetails?: any
}

export interface BookingsRecord {
  ResourceId: number
  ResourceName: string
  ResourceHideInCalendar: boolean
  ResourceResourceTypeId: number
  ResourceResourceTypeName: string
  CoworkerId: number
  CoworkerFullName: string
  ExtraServiceId: number
  ExtraServiceName?: any
  FromTime: Date
  ToTime: Date
  Notes?: any
  InternalNotes?: any
  ChargeNow: boolean
  InvoiceNow: boolean
  DoNotUseBookingCredit: boolean
  PurchaseOrder?: any
  DiscountCode?: any
  Tentative: boolean
  Online: boolean
  TeamsAtTheTimeOfBooking?: any
  TariffAtTheTimeOfBooking?: any
  RepeatSeriesUniqueId?: any
  RepeatBooking: boolean
  Repeats: number
  WhichBookingsToUpdate: number
  RepeatEvery?: any
  RepeatUntil?: any
  RepeatOnMondays: boolean
  RepeatOnTuesdays: boolean
  RepeatOnWednesdays: boolean
  RepeatOnThursdays: boolean
  RepeatOnFridays: boolean
  RepeatOnSaturdays: boolean
  RepeatOnSundays: boolean
  OverridePrice?: any
  Invoiced: boolean
  InvoiceDate?: any
  CoworkerInvoiceId?: any
  CoworkerInvoiceNumber?: any
  CoworkerInvoicePaid: boolean
  CoworkerExtraServiceIds?: any
  CoworkerExtraServicePrice?: any
  CoworkerExtraServiceCurrencyCode?: any
  CoworkerExtraServiceChargePeriod?: any
  CoworkerExtraServiceTotalUses?: any
  CoworkerBillingName?: any
  MinutesToStart: number
  OverrideResourceLimits: boolean
  DisableConfirmation: boolean
  SkipGoogleCalendarUpdate: boolean
  EstimatedCost?: any
  EstimatedCostWithProducts?: any
  EstimagedCost?: any
  EstimatedProductCost?: any
  ChargedExtraServices?: any
  BookingProducts?: any
  BookingVisitors?: any
  EstimatedExtraService?: any
  Invoice?: any
  AvailableCredit: number
  IsEvent: boolean
  IsTour: boolean
  DiscountAmount?: any
  Id: number
  UpdatedOn: Date
  CreatedOn: Date
  UniqueId: string
  UpdatedBy: string
  IsNew: boolean
  SystemId?: any
  ToStringText: string
  LocalizationDetails?: any
}

export interface IDesk {
  id: number
  organization_seat: IOrganizationSeatBasic | null
}

export interface IBasicOffice {
  id: number
  name: string
  rate: number
  location_id: number
  location_name?: string
}

export interface IOffice extends IBasicOffice {
  organization: IOrganizationBasic | null
  desks: IDesk[]
}
export interface INewOffice {
  rate: number
  location: number
  number_of_desks: number
  room: string
  level: string
  meeting_credit_rate: number
  xero_account_code: number
}

export interface IOfficeListDetail extends IBasicOffice {
  meeting_credit_rate: number
  organization: IOrganizationBasic | null
  meeting_credit_allowance: number
  number_of_desks: number
  level: string
  room: string
  xero_account_code?: IAccountCode
}
export interface IOfficeDetail extends IOffice {
  location: Location
  room: string
  level: string
  meeting_credit_rate: number
  // created_at: Date
  meeting_credit_allowance: number
  xero_account_code?: IAccountCode
}

export interface IBlogPost {
  id: string
  link: string
  summary: string
  title: string
  image: string
  date: string
}

export interface IPlanMeetingRoomOverridePricing {
  id: number
  override_price_per_hour: number
  override_credits_per_hour: number
  plan: { id: number; uuid: string }
}
export interface IMeetingRoomSuperAdmin extends IMeetingRoom {
  allowed_offices: Array<{ id: number }> | []
  plan_override_pricing: IPlanMeetingRoomOverridePricing[]
  xero_account_code: number
}

export interface IWorkspaceSuperAdmin extends IWorkspace {
  xero_account_code: number
}
export interface IMeetingRoomBasic {
  id: number
  name: string
  room_slug: string
  display_url: string
  location: number
  timezone: string
  about?: string
  public_booking: boolean
  private_room: boolean
  display_image_file?: File
}
export interface IWorkspaceBasic {
  id: number
  name: string
  display_url: string
  location: number
  timezone: string
  is_main_space: boolean
  category: string
}

export interface IWorkspace extends IWorkspaceBasic {
  is_bookable: boolean
  public_booking?: boolean
  capacity?: number
  member_price_half_day_price: number
  member_price_full_day_price: number
  non_member_price_half_day_price: number
  non_member_price_full_day_price: number
  bookings: ISimpleBooking[]
  other_bookings?: ISimpleBooking[]
}

export interface INewWorkspace {
  id: number
  name: string
  location: number
  is_main_space: boolean
  is_bookable: boolean
  public_booking: boolean
  category: string
  capacity: number
  xero_account_code: number
  member_price_half_day_price: number
  member_price_full_day_price: number
  non_member_price_half_day_price: number
  non_member_price_full_day_price: number
}

export interface IMeetingRoom extends IMeetingRoomBasic {
  credits_per_hour: number
  member_price_per_hour: number
  non_member_price_per_hour: number
  bookings: ISimpleBooking[]
  allowed_plans: Array<{ id: number; uuid: string }>
  display_image_file?: File
  has_videoconference?: boolean
  has_screen_mirroring?: boolean
  capacity?: number
}

export interface IPublicMeetingRoom extends IMeetingRoom {
  roomLocation: Location
  platformTenant: {
    id: number
    brand_primary_color: string
    logo_long: string
    logo_square: string
    contact_form_url?: string
    business_name: string
  }
  locationSettings: ILocationBooking
}

export interface INewMeetingRoom {
  name: string
  credits_per_hour: number
  member_price_per_hour: number
  non_member_price_per_hour: number
  display_image_file?: File
  display_url: string | undefined
  remove_image: boolean
  location: number
  public_booking: boolean
  private_room: boolean
  allowed_plans?: number[] | Array<{ id: number }>
  allowed_offices?: number[] | Array<{ id: number }>
  plan_override_pricing?: Partial<IPlanMeetingRoomOverridePricing>[]
  xero_account_code: number
  has_videoconference: boolean
  has_screen_mirroring: boolean
  capacity: number
  about?: string
}
export interface IBooking {
  id: number
  organizational_unit: number
  meeting_room?: number
  meeting_room_name?: string
  meeting_room_display_url?: string
  is_space_admin?: boolean
  number_attendees?: number
  location: number
  location_name?: string
  location_address?: string
  workspace?: number
  workspace_name?: string
  session?: string
  start_time: string
  end_time: string
  created_at: string
  cancelled_at?: string
  has_videoconference?: boolean
  has_screen_mirroring?: boolean
  capacity?: number
  is_external?: boolean
  timezone: string
  platform_tenant?: number
  platform_tenant_name?: string
}

export interface IBookingSummary {
  resource: 'MeetingRoom' | 'Workspace'
  meeting_room?: IMeetingRoom
  workspace?: IWorkspace
  location: Location
  start_time: Date
  end_time: Date
  bookingCost: IPreviewBookingCost
}

export interface ISimpleBooking {
  id: number
  meeting_room: number
  start_time: string
  end_time: string
  session?: string
  workspace?: string
}

export interface IBookingAdmin extends IBooking {
  booked_by_first_name: string
  booked_by_last_name: string
  booked_by_email: string
  notes?: string
  was_billed?: boolean
  event_sheet_url?: string
  is_deleted_org?: boolean
}

export interface IBookingDisplay {
  meetingRoomImage?: string
  meetingRoomName?: string
  workspaceName?: string
  workspaceCategory?: string
  startTime: Date
  endTime: Date
  session?: string
  has_videoconference?: boolean
  has_screen_mirroring?: boolean
  capacity?: number
  location: number
  location_name?: string
  location_address?: string
  is_external?: boolean
  timezone: string
  // bookings?: Array<{
  //   start_time: string
  //   end_time: string
  // }>
}

export interface ITimeSlot {
  start_time: string
  end_time: string
}

export interface IInterval {
  id: string
  start: Date
  end: Date
  color?: string
}

export interface ICreateBookingOptions {
  adjustableEndTime: Date | null | undefined
  setEndTime: Dispatch<SetStateAction<Date | undefined | null>>
}

export interface IConnection {
  id: number
  created_at: string
  message?: string
  members: {
    id: number
    email: string
    name: string
  }[]
}

export enum ApprovalStatusChoices {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  NONE = 'NONE'
}
export interface IDailyFiveContent {
  id: number
  created_at: string
  interests: Interest[]
  approval_status: ApprovalStatusChoices
  active_to_show_in_future_daily_five?: boolean
  zoom_meeting_url: string
  zoom_meeting_password: string
  title: string
  summary?: string
  author?: string
  url?: string
  thumbnail?: string
  content_type: string
  image?: string
  date?: string
  start_time?: string
  finish_time?: string
  location?: string
  location_venue?: IBasicLocation
  platform_tenant?: number
  user?: number
  user_profile_image?: string
  humanitix_event_id?: string
  humanitix_date_id?: string
  calendar_hide_event?: boolean
}

export interface IPaginatedDailyFive {
  count: number
  next: string
  previous: string
  results: IDailyFiveContent[]
}

export interface INewContent {
  title: string
  summary?: string
  author?: string
  url?: string
  thumbnail?: File
  content_type: string
  image?: File
  date?: string
  start_time?: string
  finish_time?: string
  location?: string
  location_venue?: IBasicLocation
  add_zoom_meeting?: boolean
  platform_tenant?: number
  interests: Interest[]
}

export interface IDailyFiveNotification {
  created_at: string
  content: IDailyFiveContent[]
}
export interface ICommunityProfile {
  saved_content?: IDailyFiveContent[]
  new_comment_added_email_notifications?: boolean
  new_comment_added_push_notifications?: boolean

  new_daily_five_ready_push_notifications?: boolean
  new_daily_five_ready_email_notifications?: boolean
  is_zoom_connected?: boolean
  id?: number
}

export interface IComment {
  body: string
  user: number
  created_at: string
  firstname: string
  lastname: string
  content: number
  user_profile_image: string
}

export interface ICreateBookingRequest {
  start_time: string
  end_time: string
  organizational_unit?: number
  meeting_room?: number
  workspace?: number
  session?: string
  notes?: string
  number_attendees?: number
  event_sheet_url?: string
}
export interface IPreviewBookingCost {
  charges: string
  credits: string
  member_price_per_hour: string
  credits_per_hour: string
  tax_charged?: string
}

export interface IPlatformTenant {
  id: number
  brand_primary_color?: string
  logo_long_white?: string
  logo_long?: string
  logo_square?: string
  hubspot_help_widget_code?: string
  enable_user_chatbot?: boolean
  user_chatbot_iframe_html?: string
  enable_admin_chatbot?: boolean
  admin_chatbot_iframe_html?: string
  support_link?: string
  business_name?: string
  abn?: string
  invoice_logo_long?: string
  show_daily_five?: boolean

  contact_form_url?: string

  address?: string
  email_new_notification_banner?: string
  email_member_accepted_banner?: string
  email_meeting_room_booking_banner?: string
  email_highlight_theme_color?: string
  email_from_address?: string
  email_from_address_billing?: string
  email_signature?: string
  email_company_name?: string
  social_account_facebook?: string
  email_welcome_message?: string
  social_account_instagram?: string
  social_account_twitter?: string
  social_account_linkedin?: string
  locations: Location[]
  is_onboard_complete?: boolean
}

export interface IPlatformIntegrationSettings {
  is_stripe_connected: boolean
  xero_connect_status: boolean
  humanitix_integration: IHumanitixIntegration
}

export interface IHumanitixIntegration {
  id: number
  api_key?: string
  last_updated?: string
  last_synced?: string
  status: string
  platform_tenant: number
  error_message?: string
}

export interface IUpdateAPI {
  api_key: string
}

export interface IPlatformTenantUpdate extends Partial<IPlatformTenant> {
  logoLongWhiteImageFile?: File
  logoLongImageFile?: File
  logoSquareImageFile?: File
  invoiceLogoLongImageFile?: File
  emailNewNotificationBannerImageFile?: File
  emailMemberAcceptedBannerImageFile?: File
  emailMeetingRoomBookingBannerImageFile?: File
}
export interface TenantEftAccount {
  bsb_number: string
  account_number: string
  bank: string
  account_name: string
}

export interface IInitialPlatformTenant {
  id: number
  abn?: string
  company?: string
  address?: string
  invitation_token?: string
  user_email?: string
}

export interface IPlatformTenantTheme {
  id: string
  business_name: string
  brand_primary_color?: string
  logo_long_white?: string
  logo_long?: string
  logo_square?: string
  hubspot_help_widget_code?: string
  support_link?: string
  contact_form_url?: string
}

export interface SelectOption {
  readonly label: string
  readonly value: number
}

export interface GroupedOption {
  readonly label: string
  readonly options: readonly SelectOption[]
}

// Members Directory
export interface OrganizationIndustry {
  id: number
  name?: string
}

export interface IBasicOrganizationProfile {
  id: number
  logo_url?: string
}

export interface IOrganizationProfile extends IBasicOrganizationProfile {
  linkedin_url?: string
  mobile_number?: string
  website?: string
  industries?: OrganizationIndustry[]
  overview?: string
}

export interface IUpdateOrganizationProfile extends IOrganizationProfile {
  logo_image_file?: File
  empty_industries?: Boolean
}

export interface IOrganizationalUnitDirectoryPreferences {
  job_title?: string
  appear_in_directory?: Boolean
  new_connection_email_notifications?: Boolean
}

export interface OrganizationProfile {
  id: number
  organization: IOrganizationBasic
  logo_url?: string
  linkedin_url?: string
  mobile_number?: string
  website?: string
  industries?: OrganizationIndustry[]
  overview?: string
}

export interface DirectoryOrganizationResult {
  id: number
  name: string
  organization_profile: OrganizationProfile
  home_location_name?: string
  home_location_address?: string
}

export interface DirectoryOrganizationalUnitResult {
  id: number // ORG UNIT ID
  profile?: MemberUserProfile
  user: {
    id: string
    name: string
  }
  job_title?: string
  organization: DirectoryOrganizationResult
}

export interface DirectoryMemberResult {
  id: number
  member: DirectoryOrganizationalUnitResult
  first_contact?: boolean
  created_at?: string
  message?: string
  organization: DirectoryOrganizationResult
}

export interface IBasicDirectoryMemberResult {
  id: number
  member_name: string
  first_contact: boolean
  created_at: string
  message: string
  organization: string
}

export interface IEventsFilter {
  location?: number
  interests?: number[]
}

export interface ITabMapping {
  disabled?: boolean
  tabName: string
  buttonText: string
  action: () => void
}
