import { Box, Center, Flex, Skeleton, Tag, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { Button } from '../../../components/Button'
import { Table, TableDataProp } from '../../../components/table/Table'
import { IOfficeMembershipChangeRequest } from '../../../types'
import { TeamLink } from './TeamLink'
import { RenderStatus } from '../../../components/RenderStatus'
import { CheckCustomIcon } from '../../../components/icons/icons'
import { ReviewOfficeMembershipChangeRequestModal } from '../Modals/ReviewOfficeMembershipChangeRequestModal'

interface IInputParams {
  pendingRequests: IOfficeMembershipChangeRequest[] | undefined
  status?: string
  revalidateRequests: () => void
  pagination?: {
    fetchMoreResults: () => void
    hasMoreResults: boolean
    isLoading: boolean
    count: number
  }
}

export const OfficeMembershipChangeRequests: React.FC<IInputParams> = (
  input
) => {
  const { pendingRequests, pagination, revalidateRequests } = input
  const titleStatus = input.status ? input.status.toLowerCase() : 'All'

  const [requestToReview, setRequestToReview] = useState<
    IOfficeMembershipChangeRequest | undefined
  >()

  const loading = pendingRequests === undefined

  const reviewRequestAction = {
    text: 'Review Request',
    testIdPrefix: 'review-request',
    action: (rowId) => {
      const requestObj = pendingRequests!.find((obj) => obj.id === rowId)
      if (requestObj) {
        setRequestToReview(requestObj)
      }
    }
  }
  const tableData = pendingRequests
    ? pendingRequests.map((request) => {
        const isDateChange = !request.is_deletion
        const isDeletion = request.is_deletion
        let changes: {
          title: string
          value: string | React.ReactNode
        }[] = []
        if (isDateChange) {
          if (request.change_start_date) {
            changes.push({
              title: 'Start Date',
              value: format(Date.parse(request.change_start_date), 'dd/MM/yyyy')
            })
          }
          if (request.change_end_date) {
            changes.push({
              title: 'End Date',
              value: format(Date.parse(request.change_end_date), 'dd/MM/yyyy')
            })
          }
          if (request.no_end_date) {
            changes.push({
              title: '',
              value: 'No End Date'
            })
          }
        } else if (isDeletion) {
          changes.push({
            title: 'Deleted',
            value: (
              <Box justifyContent="center" marginLeft={3}>
                <Tag color={'green.600'} background={'green.100'}>
                  <CheckCustomIcon />
                </Tag>
              </Box>
            )
          })
        }

        const cleanedData = {
          id: TableDataProp(request.id),
          test_prefix: TableDataProp(request.requested_by?.name),
          status: TableDataProp(RenderStatus(request.status)),
          requested_by: [
            TableDataProp(request.requested_by?.name),
            TableDataProp(request.requested_by?.email)
          ],
          office: [
            TableDataProp(
              `${request.office?.name} @ ${request.office?.location.name}`
            )
          ],

          organization: TableDataProp(
            TeamLink({
              organizationId: request.organization.id,
              organizationName: request.organization.name
            })
          ),
          what_changed: TableDataProp(
            isDateChange
              ? 'Date Adjustment'
              : isDeletion
              ? 'Cancellation Request'
              : 'Unknown change'
          ),
          new_values: TableDataProp(
            changes.map((change) => {
              return (
                <Flex>
                  <Text>{change.title}</Text>
                  {change.value}
                </Flex>
              )
            })
          )
        }

        const actions: any = []
        if (request.status === 'PENDING') {
          actions.push(reviewRequestAction)
        }

        cleanedData['actions'] = TableDataProp(actions)

        return cleanedData
      })
    : []

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Box display="flex" mb={3} alignItems="baseline">
          <Text
            mb={0}
            mr={3}
            color="headingPrimary"
            fontSize="lg"
            textTransform="capitalize"
          >
            Office Membership Change Requests
          </Text>
          <Text fontSize="xs" color="grey">
            {pagination
              ? pagination.count
              : pendingRequests
              ? pendingRequests.length
              : ''}{' '}
            Total
          </Text>
        </Box>

        <Table
          pageSize={pagination ? 100 : 10}
          headers={[
            { header: 'id', accessor: 'id', show: false },
            { header: 'test_prefix', accessor: 'test_prefix', show: false },
            {
              header: 'Status',
              accessor: 'status',
              show: true,
              width: 1.25 / 12
            },
            {
              header: 'Requested By',
              accessor: 'requested_by',
              show: true,
              width: 2.2 / 12
            },
            {
              header: 'Office',
              accessor: 'office',
              show: true,
              width: 1.5 / 12
            },
            {
              header: 'Team',
              accessor: 'organization',
              show: true,
              width: 1.5 / 12
            },
            {
              header: 'Change type',
              accessor: 'what_changed',
              show: true,
              width: 1 / 12
            },
            {
              header: 'Changes',
              accessor: 'new_value',
              show: true,
              width: 1 / 12
            },
            { header: '', accessor: 'actions', show: true, width: 0.5 / 12 }
          ]}
          data={tableData}
          loading={loading}
        />
      </Box>
      {pagination && (
        <Center display={'block'}>
          {pagination.isLoading && <Skeleton my={3} w={'100%'} h="60px" />}

          {pagination.hasMoreResults &&
            pendingRequests &&
            pendingRequests?.length > 0 && (
              <Center>
                <Button
                  disabled={pagination.isLoading || !pagination.hasMoreResults}
                  onClick={pagination.fetchMoreResults}
                  mt={3}
                  p={3}
                >
                  Fetch more requests
                </Button>
              </Center>
            )}
        </Center>
      )}

      <ReviewOfficeMembershipChangeRequestModal
        request={requestToReview}
        closeModalCallback={() => {
          setRequestToReview(undefined)
          revalidateRequests()
        }}
      />
    </div>
  )
}
