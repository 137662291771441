import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { createOneTimeCharge, updateOneTimeCharge } from '../../../api'
import { Button } from '../../../components/Button'
import { StyledInput } from '../../../components/StyledInputs'
import { StyledSelect } from '../../../components/StyledSelect'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import {
  ICreateOneTimeCharge,
  IOneTimeCharge,
  IOrganization
} from '../../../types'
import { useAccountCodes } from '../../../utils/apiHooks'
import { validateTextIsOnlyAscii } from '../../../utils/formUtils'
import { FeatureComponent } from '../../../components/FeatureComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
  oneTimeChargeToEdit?: IOneTimeCharge
  organization: IOrganization
}
export const CreateOneTimeChargeModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  oneTimeChargeToEdit,
  organization
}) => {
  const toast = useToast()
  const { platformTenant } = usePlatformTenant()
  const { data: accountCodes } = useAccountCodes(platformTenant?.id)

  const [disableEdition, setDisabledEdition] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setDisabledEdition(oneTimeChargeToEdit?.invoice != null)
    }
  }, [isOpen])

  function validateRate(value: number) {
    let error = ''
    if (!value) {
      error = 'This rate is required'
    } else if (isNaN(value)) {
      error = 'This rate must be numeric'
    } else if (value < 0) {
      error = 'This rate cannot be negative'
    }
    return error
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent minW="450px" borderRadius="6px">
        <ModalHeader pb={5}>
          {oneTimeChargeToEdit ? 'Edit' : 'New'} One time charge{' '}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            description: oneTimeChargeToEdit?.description ?? '',
            long_description: oneTimeChargeToEdit?.long_description ?? '',
            value: oneTimeChargeToEdit?.value,
            quantity: oneTimeChargeToEdit?.quantity ?? 1,
            charged_by: oneTimeChargeToEdit?.charged_by ?? 'STAFF_MEMBER',
            xero_account_code: oneTimeChargeToEdit?.xero_account_code ?? ''
          }}
          onSubmit={async (values, actions) => {
            const value = values?.value ?? 0
            delete values.value
            const newOneTimeCharge: ICreateOneTimeCharge = {
              ...values,
              value,
              organization: organization.id
            }
            let message = ''

            try {
              if (oneTimeChargeToEdit?.id) {
                await updateOneTimeCharge(
                  oneTimeChargeToEdit.id,
                  newOneTimeCharge
                )
                message = 'One Time Charge modified.'
              } else {
                await createOneTimeCharge(newOneTimeCharge)
                message = 'One Time Charge created.'
              }
              toast({
                status: 'success',
                description: message
              })
              actions.setSubmitting(false)
              onClose()
            } catch (error) {
              actions.setSubmitting(false)
              toast({
                status: 'error',
                description: 'Failed to create One Time Charge'
              })
            }
          }}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody pb={5}>
                <Field
                  name="description"
                  validate={validateTextIsOnlyAscii}
                  render={({ field, form }) => (
                    <FormControl
                      isDisabled={disableEdition}
                      isRequired
                      isInvalid={
                        form.errors.description && form.touched.description
                      }
                      pb={5}
                    >
                      <FormLabel htmlFor="description">Description</FormLabel>
                      <StyledInput {...field} id="name" w="100%" />

                      <FormErrorMessage>
                        {form.errors.description}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  name="long_description"
                  validate={validateTextIsOnlyAscii}
                  render={({ field, form }) => (
                    <FormControl
                      isDisabled={disableEdition}
                      isInvalid={
                        form.errors.long_description &&
                        form.touched.long_description
                      }
                      pt={2}
                      pb={5}
                    >
                      <FormLabel htmlFor="long_description">
                        Long description
                      </FormLabel>
                      <Textarea
                        pl={4}
                        variant="flushed"
                        bg="#FAFAFA"
                        fontSize="md"
                        borderColor="eastbay07"
                        id="long_description"
                        borderBottom="1px"
                        resize="vertical"
                        size="sm"
                        {...field}
                        _invalid={{
                          border: '2px solid',
                          borderColor: '#E1202A'
                        }}
                      />

                      <FormErrorMessage>
                        {form.errors.long_description}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  name="quantity"
                  render={({ field, form }) => (
                    <FormControl
                      isDisabled={disableEdition}
                      isRequired
                      mr={4}
                      isInvalid={form.errors.quantity && form.touched.quantity}
                      pb={5}
                    >
                      <FormLabel color="#4F4F4F" mb={1}>
                        Quantity
                      </FormLabel>
                      <NumberInput
                        defaultValue="1"
                        min={1}
                        max={100}
                        clampValueOnBlur={true}
                        bg="#FAFAFA"
                        fontSize="md"
                        borderColor="eastbay07"
                        onChange={(value) => {
                          props.setFieldValue('quantity', value)
                        }}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <FormErrorMessage>
                        {form.errors.category}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  name="value"
                  validate={validateRate}
                  render={({ field, form }) => (
                    <FormControl
                      isDisabled={disableEdition}
                      isRequired
                      isInvalid={form.errors.value && form.touched.value}
                      pb={5}
                    >
                      <FormLabel htmlFor="value">Value</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          children="$"
                          pr="1.5rem"
                          color="eastBay05"
                        />
                        <StyledInput {...field} id="value" w="100%" />
                      </InputGroup>

                      <FormErrorMessage>{form.errors.value}</FormErrorMessage>
                    </FormControl>
                  )}
                />

                <FeatureComponent
                  icon={<FontAwesomeIcon icon={faMoneyBill1Wave} size={'1x'} />}
                  iconBg={'blue.50'}
                  text={'Total (Excluding tax)'}
                  subtitle={
                    '$' +
                    (
                      (props.values?.value || 0) * (props.values.quantity || 1)
                    ).toFixed(2)
                  }
                />
                <Divider my={2} />
                <Field
                  name="charged_by"
                  render={({ field, form }) => (
                    <FormControl
                      isDisabled={disableEdition}
                      isRequired
                      isInvalid={
                        form.errors.charged_by && form.touched.charged_by
                      }
                      pt={2}
                      pb={5}
                    >
                      <FormLabel color="#4F4F4F" mb={1}>
                        Charged by
                      </FormLabel>
                      <StyledSelect
                        value={form.values.charged_by}
                        name="charged_by"
                        onChange={(e) => {
                          props.setFieldValue('charged_by', e.target.value)
                        }}
                      >
                        <option key={'STAFF_MEMBER'} value={'STAFF_MEMBER'}>
                          {'Staff member'}
                        </option>
                      </StyledSelect>
                      <FormErrorMessage>
                        {form.errors.charged_by}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Field
                  name="xero_account_code"
                  render={({ field, form }) => (
                    <FormControl
                      isDisabled={disableEdition}
                      isRequired
                      isInvalid={
                        form.errors.xero_account_code &&
                        form.touched.xero_account_code
                      }
                      pt={2}
                      pb={5}
                    >
                      <FormLabel color="#4F4F4F" mb={1}>
                        XERO account code
                      </FormLabel>
                      <StyledSelect
                        placeholder="Select the XERO account code"
                        value={form.values.xero_account_code}
                        name="xero_account_code"
                        onChange={(e) => {
                          props.setFieldValue(
                            'xero_account_code',
                            e.target.value
                          )
                        }}
                      >
                        {accountCodes?.map((accountCode) => (
                          <option
                            key={accountCode.code}
                            value={accountCode.code}
                          >
                            {accountCode.title}
                          </option>
                        ))}
                      </StyledSelect>

                      <FormErrorMessage>
                        {form.errors.xero_account_code}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </ModalBody>

              <ModalFooter justifyContent="flex-start">
                <Button disabled={props.isSubmitting} type="submit" mr={3}>
                  Save
                </Button>
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          )}
        />
      </ModalContent>
    </Modal>
  )
}
