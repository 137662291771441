import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  Skeleton,
  DrawerFooter,
  Text,
  useToast
} from '@chakra-ui/react'
import React, { useEffect } from 'react'

import { useAuth } from '../context/auth-context'
import { useAuthDrawer } from '../context/auth-drawer-context'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { UserDetailsProvider } from '../context/user-onboard-context'
import { LoginComponent } from './LoginComponent'
import { OnboardingStepsExternal } from './Onboarding/OnboardingStepsExternal'

interface Props {}

export const SignInSignupDrawer: React.FC<Props> = (props) => {
  const { isAuthenticated, getAndSetMe } = useAuth()
  const { platformTheme } = usePlatformTenant()
  const { isOpen, onClose, isLogin, analyticsRegisterSuccessfulSignUp } =
    useAuthDrawer()
  const toast = useToast()
  useEffect(() => {
    if (isAuthenticated && isOpen) {
      onClose()
    }
  }, [isAuthenticated])

  if (!platformTheme) return <Skeleton h={'40px'} />

  return (
    <Drawer
      placement={'right'}
      onClose={onClose}
      isOpen={isOpen}
      size={isLogin ? 'md' : 'full'}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          {isLogin ? 'Sign in to proceed' : 'Sign up to Book a space'}
        </DrawerHeader>
        <DrawerBody>
          {isLogin ? (
            <LoginComponent
              logo_url={platformTheme?.logo_long}
              externalSignUp={{ tenantID: Number.parseInt(platformTheme.id) }}
            />
          ) : (
            <UserDetailsProvider>
              <OnboardingStepsExternal
                selectedTenant={Number.parseInt(platformTheme.id)}
                onFinishSignUp={() => {
                  let token = window.localStorage.getItem('Token')
                  if (token && getAndSetMe) {
                    analyticsRegisterSuccessfulSignUp(
                      platformTheme.business_name,
                      platformTheme.id
                    )
                    getAndSetMe(token).then((res) => {
                      onClose()
                    })
                  } else {
                    onClose()
                    toast({
                      title: 'An error occurred',
                      description: 'Try again later',
                      status: 'error'
                    })
                  }
                }}
              />
            </UserDetailsProvider>
          )}
        </DrawerBody>
        <DrawerFooter justifyContent={'center'}>
          <Text as="span">
            Powered by{' '}
            <Text as="span" color="#EB4969">
              Spacecubed
            </Text>
          </Text>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
