import React from 'react'
import { routeStrings } from '../../../routeStrings'
import { Link } from 'react-router-dom'

interface Props {
  organizationId: number
  organizationName: string
  external?: boolean
  style?: React.CSSProperties
}

export const TeamLink: React.FC<Props> = ({
  organizationId,
  organizationName,
  external,
  style
}) => {
  return (
    <Link
      style={{ cursor: 'pointer', marginBottom: 0, ...style }}
      to={routeStrings.adminTeamDetailView(organizationId)}
      className="table-link"
      target={external ? '_blank' : undefined}
    >
      {organizationName}
    </Link>
  )
}
