import { roundToNearestMinutes } from 'date-fns'
import React, { SetStateAction, useEffect, useState } from 'react'
import {
  ICreateBookingRequest,
  IMeetingRoom,
  IMeetingRoomSuperAdmin,
  IWorkspace,
  IWorkspaceSuperAdmin,
  Location
} from '../types'
import {
  useBasicMeetingRooms,
  useBasicWorkspaces,
  useListMeetingRoomsTimeline,
  useListWorkspacesTimeline,
  useLocations,
  useTenantLocations
} from '../utils/apiHooks'
import { IBooking } from '../types'
import { useOrganizationalUnitBookings } from '../utils/apiHooks'
import { useAuth } from './auth-context'
import { createCtx } from './createCtx'
import { useNavigate } from 'react-router-dom'
import { useMobileEmbed } from './mobile-embed-context'
import { useMedia } from '../hooks/useMedia'
import { bookMeetingRoom, rescheduleMeetingRoom } from '../api'
import { encodeQueryParams } from 'use-query-params'
import { viewBookingQueryParams } from '../utils/queryParams'
import stringify from 'query-string'
import { useCustomToast } from './toast-context'
import { usePlatformTenant } from './platform-tenant-context'
import { analyticsTrack } from '../utils/analytics'

interface BookingContextProps {
  isBooking: boolean
  setIsBooking: (value: boolean) => void
  orgUnitBookings: IBooking[]
  orgUnitMeetingRoomBookings: IBooking[]
  orgUnitWorkspaceBookings: IBooking[]
  loading: boolean
  chosenStartDateTime: Date
  chosenDate: Date
  setChosenDate: React.Dispatch<SetStateAction<Date>>
  handleChangeDate: (date: Date) => void
  chosenEndDateTime: Date
  setChosenLocation: React.Dispatch<SetStateAction<Location | undefined>>
  chosenLocation: Location | undefined
  locations: Location[]
  revalOrgUnitBookings: () => void
  handleSelectMeetingRoom: (meetingRoom: IMeetingRoomSuperAdmin) => void
  showMobileVersion: boolean
  selectedMeetingRoom: IMeetingRoomSuperAdmin | undefined
  makeBooking: () => Promise<void>
  makeRoomBooking: (
    newBooking: ICreateBookingRequest,
    existingBooking?: IBooking
  ) => Promise<IBooking>
  tenantRooms: IMeetingRoomSuperAdmin[]
  meetingRooms: IMeetingRoom[] | undefined
  revalidateMeetingRoomTimelines: () => void
  tenantWorkspaces: IWorkspaceSuperAdmin[]
  revalidateAdminWorkspaces: () => void
  revalidateWorkspaces: () => void
  workspaces: IWorkspace[] | undefined
}

const [useBookingContext, BookingContextProvider] =
  createCtx<BookingContextProps>()

const BookingProvider = ({ children }) => {
  const { platformTenant } = usePlatformTenant()
  const { isMobileEmbed } = useMobileEmbed()
  const { newToast: toast } = useCustomToast()
  const smallScreen = useMedia(['(min-width: 575px)'], [false], true)
  const showMobileVersion = isMobileEmbed || smallScreen
  const navigate = useNavigate()
  const { data: allRooms, mutate: revalMeetingRooms } = useBasicMeetingRooms(
    platformTenant?.id,
    {
      refreshInterval: 0,
      revalidateOnFocus: false
    }
  )
  const { data: tenantWorkspaces, mutate: revalidateAdminWorkspaces } =
    useBasicWorkspaces(platformTenant?.id, {
      refreshInterval: 0,
      revalidateOnFocus: false
    })

  const { currentOrgUnit } = useAuth()
  const locations = useLocations()
  const { data: orgUnitBookings, mutate: revalOrgUnitBookings } =
    useOrganizationalUnitBookings('upcoming', currentOrgUnit?.id)

  const orgUnitMeetingRoomBookings = orgUnitBookings
    ? orgUnitBookings.filter((b) => b.meeting_room)
    : []
  const orgUnitWorkspaceBookings = orgUnitBookings
    ? orgUnitBookings.filter((b) => b.workspace)
    : []

  //-------STATE-----------

  const [isBooking, setIsBooking] = useState<boolean>(false)
  const [selectedMeetingRoom, setSelectedMeetingRoom] =
    useState<IMeetingRoomSuperAdmin>()

  const [chosenDate, setChosenDate] = useState<Date>(
    roundToNearestMinutes(new Date(), { nearestTo: 15 })
  )
  const [chosenStartDateTime, setChosenStartDateTime] = useState<Date>(
    new Date()
  )
  const [chosenEndDateTime, setChosenEndDateTime] = useState<Date>(new Date())

  const [tenantRooms, setTenantRooms] = useState<IMeetingRoomSuperAdmin[]>([])

  const [chosenLocation, setChosenLocation] = useState<Location>()
  const { data: browsableLocations, mutate: revalidateLocations } =
    useTenantLocations(platformTenant?.id)

  //-------FUNCTIONS-----------

  function handleChangeDate(newDate: Date) {
    setChosenDate(newDate)
  }

  const handleSelectMeetingRoom = (meetingRoom: IMeetingRoomSuperAdmin) => {
    setSelectedMeetingRoom(meetingRoom)
  }

  const makeBooking = async () => {
    if (selectedMeetingRoom && currentOrgUnit) {
      setIsBooking(true)
      try {
        const response = await bookMeetingRoom({
          meeting_room: selectedMeetingRoom.id,
          organizational_unit: currentOrgUnit.id,
          start_time: chosenStartDateTime.toISOString(),
          end_time: chosenEndDateTime.toISOString()
        })
        let bookingResponse = response.data as IBooking
        const params = encodeQueryParams(viewBookingQueryParams, {
          newBooking: true
        })

        const url = `/dashboard/booking/${
          bookingResponse.id
        }?${stringify.stringify(params)}`
        revalOrgUnitBookings()

        setSelectedMeetingRoom(undefined)
        navigate(url)
      } catch (error) {
        let description = 'Something went wrong, failed to make booking'

        toast({
          description,
          status: 'error'
        })
      }
      setIsBooking(false)
    }
  }

  const analyticsNewBooking = (booking: IBooking, isReschedule: boolean) => {
    let title = isReschedule ? 'RescheduledBooking' : 'NewBooking'
    analyticsTrack(title, {
      id: booking.id,
      name: booking.meeting_room_name,
      tenant: booking.platform_tenant,
      tenantName: booking.platform_tenant_name,
      isExternal: booking.is_external
    })
  }

  const makeRoomBooking = (
    newBooking: ICreateBookingRequest,
    existingBooking?: IBooking
  ) => {
    const {
      organizational_unit: orgUnit,
      start_time,
      end_time,
      meeting_room,
      workspace,
      number_attendees,
      notes
    } = newBooking
    return new Promise<IBooking>((resolve, reject) => {
      if (isBooking || !orgUnit) {
        reject()
        return
      }

      setIsBooking(true)
      let promise =
        existingBooking != undefined
          ? rescheduleMeetingRoom(existingBooking.id, {
              meeting_room: meeting_room,
              workspace: workspace,
              organizational_unit: orgUnit,
              start_time,
              end_time
            })
          : bookMeetingRoom({
              meeting_room: meeting_room,
              workspace: workspace,
              organizational_unit: orgUnit,
              start_time,
              end_time,
              number_attendees,
              notes
            })
      promise
        .then((res) => {
          setIsBooking(false)
          let booking = res.data as IBooking
          analyticsNewBooking(booking, existingBooking != undefined)
          resolve(booking)
        })
        .catch((err) => {
          setIsBooking(false)
          reject(err)
        })
    })

    // if (!existingBooking) {
    //   const params = encodeQueryParams(viewBookingQueryParams, {
    //     newBooking: true
    //   })

    //   const url = `/dashboard/booking/${bookingResponse.id}?${stringify(
    //     params
    //   )}`
    //   navigate(url)
    // } else {
    //   bookingConfirmed(true, bookingResponse)
    //   toast({
    //     description: 'Booking rescheduled',
    //     status: 'success'
    //   })
    // }
  }
  //-------EFFECTS-----------

  //Filtering the meeting rooms on the frontend for now, at some point we may just query for only the rooms we need
  useEffect(() => {
    if (allRooms && platformTenant && locations) {
      let rooms: IMeetingRoomSuperAdmin[] = allRooms

      const roomLocationIds = rooms.map((r) => r.location)
      const browsable = locations.filter((l) => roomLocationIds.includes(l.id))
      // setChosenLocation(browsable.length > 0 ? browsable[0] : undefined)
      setTenantRooms(rooms)
    }
  }, [allRooms, platformTenant, locations])

  const { data: meetingRooms, mutate: revalidateMeetingRoomTimelines } =
    useListMeetingRoomsTimeline(
      chosenDate,
      chosenLocation ? chosenLocation.id : 0,
      currentOrgUnit?.organization.id ?? 0,
      {
        refreshInterval: 0,
        revalidateOnFocus: false
      }
    )
  useEffect(() => {
    if (!chosenLocation && browsableLocations) {
      try {
        setChosenLocation(browsableLocations[0])
      } catch (error) {}
    }
  }, [browsableLocations])
  const { data: workspaces, mutate: revalidateWorkspaces } =
    useListWorkspacesTimeline(
      chosenDate,
      chosenLocation ? chosenLocation.id : 0,
      {},
      {
        refreshInterval: 0,
        revalidateOnFocus: false
      }
    )

  return (
    <BookingContextProvider
      value={{
        isBooking,
        setIsBooking,
        orgUnitBookings: orgUnitBookings || [],
        orgUnitMeetingRoomBookings: orgUnitMeetingRoomBookings || [],
        orgUnitWorkspaceBookings: orgUnitWorkspaceBookings || [],
        loading: !orgUnitBookings || !tenantRooms || !browsableLocations,
        chosenStartDateTime,
        chosenDate,
        handleChangeDate,
        setChosenDate,
        chosenEndDateTime,
        chosenLocation,
        setChosenLocation,
        revalOrgUnitBookings,
        //Just passing this through to avoid refactor
        locations: browsableLocations,
        handleSelectMeetingRoom, // reval
        showMobileVersion,
        selectedMeetingRoom, // reval
        makeBooking, // reval
        makeRoomBooking,
        tenantRooms,
        meetingRooms: meetingRooms || [],
        revalidateMeetingRoomTimelines,
        tenantWorkspaces,
        revalidateAdminWorkspaces,
        revalidateWorkspaces,
        workspaces
      }}
    >
      {children}
    </BookingContextProvider>
  )
}

export { useBookingContext, BookingProvider }
