import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { StringParam, useQueryParam } from 'use-query-params'
import { GroupingHeaderLayout } from '../../components/GroupingHeaderLayout'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'
import { AdminDashboardOfficeRequests } from './AdminDashboardOfficeRequests'
import { AdminDashboardRequests } from './AdminDashboardRequests'

const meetingRoomPages = [
  {
    text: 'Membership Requests',
    id: 'membership-requests',
    tourClassName: ''
  },
  {
    text: 'Office Requests',
    id: 'office-requests',
    tourClassName: ''
  }
]

export const AdminDashboardRequestsAdmin: React.FC = () => {
  const [pageParam, setPageParam] = useQueryParam('type', StringParam)
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  useEffect(() => {
    if (!pageParam) {
      setPageParam(meetingRoomPages[0].id)
    }
  }, [pageParam])

  return (
    <LayoutAdminDashboard>
      <GroupingHeaderLayout
        tabs={meetingRoomPages.map((page) => {
          return {
            text: page.text,
            className: page.tourClassName,
            active: pageParam === page.id,
            selectAction: () => {
              setPageParam(page.id)
            }
          }
        })}
      />
      {pageParam === 'membership-requests' && <AdminDashboardRequests />}
      {pageParam === 'office-requests' && <AdminDashboardOfficeRequests />}
    </LayoutAdminDashboard>
  )
}
