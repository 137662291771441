import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { createPlan, getPlanCategories, updatePlan } from '../../../api'
import { Button } from '../../../components/Button'
import { StyledInput } from '../../../components/StyledInputs'
import { StyledSelect } from '../../../components/StyledSelect'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { INewPlan, IPlanCategory, Plan } from '../../../types'
import { usePlans } from '../../../utils/apiHooks'
import { FileUploadComponent } from '../Components/FileUploadComponent'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
  planToEdit?: Plan
}
export const CreatePlanModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  planToEdit
}) => {
  const toast = useToast()
  const [planCategories, setPlanCategories] = useState<IPlanCategory[]>([])

  const { platformTenant } = usePlatformTenant()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { mutate } = usePlans(platformTenant?.id)

  useEffect(() => {
    platformTenant &&
      getPlanCategories(platformTenant.id).then((planCategoriesResponse) => {
        if (planCategoriesResponse.data) {
          setPlanCategories(planCategoriesResponse.data)
        }
      })
  }, [platformTenant])

  function validateRate(value) {
    let error = ''
    if (value === '' || value === undefined) {
      error = 'This rate is required'
    } else if (isNaN(value)) {
      error = 'This rate must be numeric'
    } else if (value < 0) {
      error = 'This rate cannot be negative'
    }
    return error
  }

  const REGULAR_PLAN = 0
  const ADDON_PLAN = 1

  const PLAN_TYPE_DESCRIPTIONS = [
    'A plan that represents the right of a member to be part of the space for a determined period.',
    'A plan to add extra features to a current membership. ',
    'A plan for users and teams that are not part of a membership but can have access to book spaces.'
  ]

  const [planType, setPlanType] = useState(REGULAR_PLAN)

  useEffect(() => {
    if (planToEdit) {
      setPlanType(planToEdit.addon_plan ? ADDON_PLAN : REGULAR_PLAN)
    }
  }, [planToEdit])

  const PlanType: React.FC<{
    label: string
    currentPlanType: number
  }> = (props) => {
    const { label, currentPlanType } = props
    const isActive = planType === currentPlanType
    const textColor = isActive ? platformTenant?.brand_primary_color : '#888'

    if (planToEdit && !isActive) return null
    return (
      <Box
        background={'white'}
        color={textColor}
        onClick={() => {
          if (planToEdit === undefined) setPlanType(currentPlanType)
        }}
        cursor="pointer"
        borderWidth={isActive ? 2 : 1}
        borderColor={isActive ? platformTenant?.brand_primary_color : '#888'}
        p={1}
        borderRadius="md"
        _hover={{
          borderColor: platformTenant?.brand_primary_color
        }}
      >
        <Stack>
          <Text fontWeight="bold" textTransform="capitalize">
            {isActive && <FontAwesomeIcon icon={faCheckCircle} />}
            {isActive ? ' ' + label : label}
          </Text>
          {isActive && (
            <Text fontSize={12} color="#000">
              {PLAN_TYPE_DESCRIPTIONS[currentPlanType]}
            </Text>
          )}
        </Stack>
      </Box>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent minW={['450px', '600px']} borderRadius="6px">
        <ModalHeader pb={5}>{planToEdit ? 'Edit' : 'New'} plan </ModalHeader>
        <ModalCloseButton />
        <Formik
          validateOnChange={false}
          initialValues={{
            category: planToEdit?.category?.id ?? 0,
            description: planToEdit?.description ?? '',
            frequency: planToEdit?.rate.frequency ?? 'MONTH',
            show_to_members:
              planToEdit?.hidden === undefined ? true : !planToEdit?.hidden,
            // show_to_admin should be true by default when creating a plan (when planToEdit is undefined)
            // otherwise it should be the inverse of the plan we are editing's `hidden_to_admin` attribute
            // (eg, if "hidden_to_admin" is false on a plan, show_to_admin should be true)
            show_to_admin:
              planToEdit === undefined ? true : !planToEdit?.hidden_to_admin,
            inclusions_document_file: undefined,
            location: planToEdit?.location?.id ?? 0,
            tenant: platformTenant?.id ?? 0,
            meeting_room_credits_included:
              planToEdit?.meeting_room_credits_included ?? 0,
            name: planToEdit?.name ?? '',
            order_of_preference: planToEdit?.order_of_preference ?? -1,
            value: planToEdit?.rate.value ?? 0,
            addon_plan: planToEdit?.addon_plan ?? false,
            approve_on_sign_up: planToEdit?.approve_on_sign_up ?? false
          }}
          onSubmit={(values, actions) => {
            // newPlan is of type INewPlan, which includes a `hidden_to_admin` property
            // however for our user-friendly UI, we going to render a switch saying "show to admin"
            // instead of "hidden to admin".
            // PROBLEM: we need to map the form values to the `INewPlan` type.
            if (isSubmitting) return
            const { show_to_admin, show_to_members, ...rest } = values
            const newPlan: INewPlan = {
              ...rest,
              location:
                [ADDON_PLAN, REGULAR_PLAN].indexOf(planType) >= 0
                  ? values.location
                  : 0,
              addon_plan: planType === ADDON_PLAN,
              hidden: !show_to_members,
              hidden_to_admin: !show_to_admin
            }

            if (planToEdit) {
              // update
              updatePlan(planToEdit.id, newPlan)
                .then(() => {
                  setIsSubmitting(false)
                  toast({
                    status: 'success',
                    description: 'Plan updated'
                  })
                  mutate()
                  onClose()
                })
                .catch(() => {
                  setIsSubmitting(false)
                  toast({
                    status: 'error',
                    description: 'Failed to update Plan'
                  })
                })
                .finally(() => {
                  actions.setSubmitting(false)
                  mutate()
                })
            } else {
              // create

              createPlan(newPlan)
                .then(() => {
                  setIsSubmitting(false)
                  toast({
                    status: 'success',
                    description: 'Plan Created'
                  })
                  onClose()
                })
                .catch(() => {
                  setIsSubmitting(false)
                  toast({
                    status: 'error',
                    description: 'Failed to create plan'
                  })
                })
                .finally(() => {
                  actions.setSubmitting(false)
                  mutate()
                })
            }
          }}
        >
          {({
            values,
            setFieldValue,
            isSubmitting,
            touched,
            errors,
            handleSubmit
          }) => (
            <Form>
              <ModalBody flexWrap="wrap">
                <Flex>
                  <Stack px={2}>
                    <Text fontWeight="bold">Type of plan</Text>

                    <PlanType
                      label={'Membership'}
                      currentPlanType={REGULAR_PLAN}
                    />
                    <PlanType
                      label={'Addon Plan'}
                      currentPlanType={ADDON_PLAN}
                    />

                    <Field
                      name="name"
                      render={({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <FormLabel htmlFor="name">Name</FormLabel>
                          <StyledInput {...field} id="name" w="100%" />

                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                    <Field
                      name="description"
                      render={({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={
                            form.errors.description && form.touched.description
                          }
                          mt={3}
                        >
                          <FormLabel htmlFor="description" mt="10px">
                            Description
                          </FormLabel>
                          <Textarea
                            {...field}
                            pl={4}
                            variant="flushed"
                            bg="#FAFAFA"
                            fontSize="md"
                            borderColor="eastbay07"
                            id="description"
                            borderBottom="1px"
                          />

                          <FormErrorMessage>
                            {form.errors.description}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                    <SimpleGrid mt={3} columns={[1, 2]} spacing={6}>
                      {[REGULAR_PLAN, ADDON_PLAN].indexOf(planType) >= 0 && (
                        <Field
                          name="location"
                          render={({ field, form }) => (
                            <FormControl
                              isDisabled={!!planToEdit}
                              isRequired
                              isInvalid={
                                form.errors.location && form.touched.location
                              }
                              pb={5}
                            >
                              <FormLabel color="#4F4F4F" mb={1}>
                                Location
                              </FormLabel>
                              <StyledSelect
                                placeholder="Select a location"
                                value={form.values.location}
                                name="location"
                                onChange={(e) => {
                                  setFieldValue(
                                    'location',
                                    parseInt(e.target.value)
                                  )
                                }}
                              >
                                {platformTenant?.locations.map((location) => (
                                  <option key={location.id} value={location.id}>
                                    {location.name}
                                  </option>
                                ))}
                              </StyledSelect>

                              <FormErrorMessage>
                                {form.errors.category}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        />
                      )}
                      <Field
                        name="category"
                        render={({ field, form }) => (
                          <FormControl
                            isRequired
                            isInvalid={
                              form.errors.category && form.touched.category
                            }
                            pb={5}
                          >
                            <FormLabel color="#4F4F4F" mb={1}>
                              Category
                            </FormLabel>
                            <StyledSelect
                              placeholder="Select a plan category"
                              value={form.values.category}
                              name="category"
                              onChange={(e) => {
                                setFieldValue(
                                  'category',
                                  parseInt(e.target.value)
                                )
                              }}
                            >
                              {planCategories.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </StyledSelect>

                            <FormErrorMessage>
                              {form.errors.category}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />

                      <Field
                        name="value"
                        validate={validateRate}
                        render={({ field, form }) => (
                          <FormControl
                            isDisabled={!!planToEdit}
                            isRequired
                            isInvalid={form.errors.value && form.touched.value}
                          >
                            <FormLabel htmlFor="value">Rate</FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                children="$"
                                pr="1.5rem"
                                color="eastBay05"
                              />
                              <StyledInput
                                {...field}
                                id="value"
                                placeholder="Plan value"
                                w="100%"
                              />
                            </InputGroup>

                            <FormErrorMessage>
                              {form.errors.value}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                      <Field
                        name="frequency"
                        render={({ field, form }) => (
                          <FormControl
                            isDisabled={!!planToEdit}
                            isRequired
                            isInvalid={
                              form.errors.frequency && form.touched.frequency
                            }
                            pb={5}
                          >
                            <FormLabel color="#4F4F4F">Frequency</FormLabel>
                            <StyledSelect
                              placeholder="Select a plan frequency"
                              value={form.values.frequency}
                              name="frequency"
                              onChange={(e) => {
                                setFieldValue('frequency', e.target.value)
                              }}
                            >
                              <option key={'MONTHLY'} value={'MONTH'}>
                                {'Monthly'}
                              </option>
                              <option key={'YEARLY'} value={'YEAR'}>
                                {'Yearly'}
                              </option>
                            </StyledSelect>

                            <FormErrorMessage>
                              {form.errors.category}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                      <Field
                        name="meeting_room_credits_included"
                        validate={validateRate}
                        render={({ field, form }) => (
                          <FormControl
                            isDisabled={!!planToEdit}
                            isRequired
                            isInvalid={
                              form.errors.meeting_room_credits_included &&
                              form.touched.meeting_room_credits_included
                            }
                          >
                            <FormLabel htmlFor="meeting_room_credits_included">
                              Meeting Credits included:
                            </FormLabel>
                            <StyledInput
                              {...field}
                              id="meeting_room_credits_included"
                              placeholder="meeting room credits"
                              w="100%"
                            />
                            <FormErrorMessage>
                              {form.errors.meeting_room_credits_included}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                      {planType === REGULAR_PLAN && (
                        <Field
                          name="order_of_preference"
                          validate={(value) => {
                            if (isNaN(value)) {
                              return 'This value must be numeric'
                            }
                          }}
                          render={({ field, form }) => (
                            <FormControl
                              isRequired
                              isInvalid={
                                form.errors.order_of_preference &&
                                form.touched.order_of_preference
                              }
                              pb={5}
                            >
                              <FormLabel htmlFor="order_of_preference">
                                Order of preference
                              </FormLabel>
                              <StyledInput
                                {...field}
                                id="order_of_preference"
                                placeholder="meeting room credits"
                                w="100%"
                              />
                              <FormErrorMessage>
                                {form.errors.order_of_preference}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        />
                      )}
                    </SimpleGrid>
                  </Stack>

                  <Stack ml={2} px={2}>
                    {/* Document field */}
                    <Field
                      name="inclusions_document_file"
                      render={({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.inclusions_document_file &&
                            form.touched.inclusions_document_file
                          }
                          pb={8}
                          borderBottom="1px solid #E0E0E0"
                        >
                          <FormLabel htmlFor="inclusions_document_file">
                            Inclusions document (pdf)
                          </FormLabel>
                          <Text mb="16px" fontSize="xs" color="eastBay07">
                            Only .doc, .docx and .pdf files. 10 MB max file
                            size.
                          </Text>
                          <FileUploadComponent
                            accept="application/pdf"
                            documentType="Plan File"
                            fileUrl={planToEdit?.inclusions_document_url}
                            initialValue={planToEdit?.inclusions_document_url}
                            uploadedFile={values.inclusions_document_file}
                            setUploadedFile={(file) => {
                              setFieldValue('inclusions_document_file', file)
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.inclusions_document_file}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                    <Heading size="sm">Checks</Heading>

                    <Divider />
                    <Field
                      name="show_to_members"
                      render={({ field, form }) => (
                        <FormControl
                          display="flex"
                          alignItems="center"
                          isInvalid={
                            form.errors.show_to_members &&
                            form.touched.show_to_members
                          }
                          justifyContent={'space-between'}
                        >
                          <Box>
                            <FormLabel htmlFor="show_to_members" mb="0">
                              Show to members
                            </FormLabel>
                            <FormErrorMessage>
                              {form.errors.show_to_members}
                            </FormErrorMessage>
                          </Box>
                          <Switch
                            size="sm"
                            colorScheme="red"
                            id="hidden"
                            isChecked={form.values.show_to_members}
                            {...field}
                            pr={5}
                          ></Switch>
                        </FormControl>
                      )}
                    />
                    <Divider />
                    <Field
                      name="show_to_admin"
                      render={({ field, form }) => (
                        <FormControl
                          display="flex"
                          alignItems="center"
                          isInvalid={
                            form.errors.show_to_admin &&
                            form.touched.show_to_admin
                          }
                          justifyContent={'space-between'}
                        >
                          <Box>
                            <FormLabel htmlFor="show_to_admin" mb="0">
                              Show to admin
                            </FormLabel>
                            <FormErrorMessage>
                              {form.errors.show_to_admin}
                            </FormErrorMessage>
                          </Box>
                          <Switch
                            size="sm"
                            colorScheme="red"
                            id="show_to_admin"
                            isChecked={form.values.show_to_admin}
                            {...field}
                            pr={5}
                          ></Switch>
                        </FormControl>
                      )}
                    />
                    <Divider />
                    <Field
                      name="approve_on_sign_up"
                      render={({ field, form }) => (
                        <FormControl
                          display="flex"
                          alignItems="center"
                          justifyContent={'space-between'}
                        >
                          <Box>
                            <FormLabel htmlFor="approve_on_sign_up" mb="0">
                              Automatically approve on sign up
                            </FormLabel>
                            <Popover>
                              <PopoverTrigger>
                                <Flex>
                                  <Text color="gray.400" mr={2}>
                                    More Info
                                  </Text>
                                  <IconButton
                                    aria-label={'info-'}
                                    icon={
                                      <FontAwesomeIcon icon={faInfoCircle} />
                                    }
                                    size={'xs'}
                                  />
                                </Flex>
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>
                                  Approval on Sign up
                                </PopoverHeader>
                                <PopoverBody>
                                  <Flex>
                                    <Text>
                                      By switching this option on, any member
                                      that signs up under this plan will
                                      automatically be created as a Team, and
                                      will have access once their selected start
                                      dates commences. They will have to be
                                      onboarded by an admin.
                                    </Text>
                                  </Flex>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Box>
                          <Switch
                            size="sm"
                            colorScheme="red"
                            id="approve_on_sign_up"
                            isChecked={form.values.approve_on_sign_up}
                            {...field}
                            pr={5}
                          ></Switch>
                        </FormControl>
                      )}
                    />
                    <Divider />
                    <ModalFooter justifyContent="right">
                      <Button variant="secondary" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button disabled={isSubmitting} type="submit" ml={3}>
                        Save
                      </Button>
                    </ModalFooter>
                  </Stack>
                </Flex>
              </ModalBody>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
